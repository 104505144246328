import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { get, upperFirst, isEmpty, map } from 'lodash'
import cx from 'classnames'
import { withTranslation } from 'react-i18next'
import * as IBAN from 'iban'
import { Tooltip } from 'react-tippy'
import { NumericFormat } from 'react-number-format'

// utils
import { formatAddress } from '../../utils/address'
import { formatDate } from '../../utils/date'
import { OBCHODNY_PARTNER_TYP, SPOSOB_PLATBY_DOSLA_PLATBA, SPOSOB_PLATBY_ODOSLANA_PLATBA } from '../../utils/enums'
import { getSepaMandatStavColor } from '../../utils/zmluvnyUcet'
import { setRouteParams, POHLADAVKY_ZOZNAM, ZMLUVNE_UCTY_UPRAVIT, ZMLUVNE_UCTY_DETAIL } from '../../utils/routes'
import Permissions, { PERMISSIONS } from '../../utils/permissionsHoc'
import { openDataUriWindow } from '../../utils/files'

// actions
import * as ZostatokZmluvneUctyActions from '../../actions/ZostatokZmluvneUctyActions'

class ZmluvnyUcetListItem extends React.Component {
	static propTypes = {
		zmluvnyUcet: PropTypes.shape().isRequired,
		zmluvnyUcetCislo: PropTypes.string.isRequired,
		interakcia: PropTypes.shape({
			id: PropTypes.number.isRequired,
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		zostatokZmluvneUctyActions: PropTypes.shape(),
		zostatok: PropTypes.shape(),
		obchodnyPartnerDetail: PropTypes.shape(),
		t: PropTypes.func.isRequired
	}

	componentDidMount() {
		const { zostatok, zmluvnyUcetCislo, zostatokZmluvneUctyActions } = this.props

		this._mounted = true

		if ((zmluvnyUcetCislo && isEmpty(zostatok)) || get(zostatok, 'isFailure')) {
			zostatokZmluvneUctyActions.loadZostatokZmluvnyUcet(zmluvnyUcetCislo)
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	renderZostatokStavuUctu = (backUrl) => {
		const { zmluvnyUcet, zostatok, interakcia, t } = this.props

		const isLoading = get(zostatok, 'isLoading') || false
		const isFailure = get(zostatok, 'isFailure') || isEmpty(get(zostatok, 'data')) || false

		if (isLoading) {
			return <div className='custom-empty-state'>{t('translation:ZmluvneUcty.Načítavam zostatok stavu účtu')}</div>
		}
		if (isFailure) {
			return <div className='custom-empty-state'>{t('translation:ZmluvneUcty.Nepodarilo sa načítať stav účtu')}</div>
		}
		return (
			<div className='clearfix' style={{ padding: '10px 0px' }}>
				<div className='row right-button-space'>
					<div className='col-3'>
						<span>{t('translation:StavUctu.Preplatky')}</span>
						<br />
						<strong>
							<NumericFormat
								thousandSeparator={' '}
								decimalSeparator={','}
								decimalScale={2}
								fixedDecimalScale={true}
								displayType='text'
								suffix=' €'
								value={get(zostatok, 'data.sumaPreplatky')}
							/>
						</strong>
					</div>
					<div className='col-3'>
						<Tooltip
							html={
								<span>
									{t(
										'translation:Common.Súhrn všetkých platieb v splatnosti, po splatnosti a očakávaných platieb do konca splátkového kalendára'
									)}
								</span>
							}
							position='top'
							trigger='mouseenter'
							theme='light'
						>
							<span>{t('translation:StavUctu.Očakávané platby')}</span>
							<br />
							<strong data-text-color='red'>
								<NumericFormat
									thousandSeparator={' '}
									decimalSeparator={','}
									decimalScale={2}
									fixedDecimalScale={true}
									displayType='text'
									suffix=' €'
									value={get(zostatok, 'data.sumaNedoplatky')}
								/>
							</strong>
						</Tooltip>
					</div>
					<div className='col-6'>
						<span>{t('translation:StavUctu.Nedoplatky (po splatnosti)')}</span>
						<br />
						<strong data-text-color='red'>
							<NumericFormat
								thousandSeparator={' '}
								decimalSeparator={','}
								decimalScale={2}
								fixedDecimalScale={true}
								displayType='text'
								suffix=' €'
								value={get(zostatok, 'data.sumaNedoplatkyPoSplatnosti')}
							/>
						</strong>
					</div>
				</div>
				<div className='right-button'>
					<Permissions
						allowed={[PERMISSIONS.VIEW_POHLADAVKY]}
						render={(hasPerm, actions) => {
							return (
								<Tooltip html={t('translation:Common.Preklik na záložku Stav účtu')} position='bottom' trigger='mouseenter' theme='light'>
									<Link
										to={`${setRouteParams(POHLADAVKY_ZOZNAM, get(interakcia, 'opCislo'))}?zmluvnyUcetCislo=${get(
											zmluvnyUcet,
											'cislo'
										)}&backUrl=${backUrl}`}
										className='button'
										data-type='icon-button'
										data-icon='pig'
										onClick={(e) => {
											if (!hasPerm) {
												e.preventDefault()
												actions.openForbiddenModal()
											}
										}}
									/>
								</Tooltip>
							)
						}}
					/>
				</div>
			</div>
		)
	}

	render() {
		const { zmluvnyUcet, zmluvnyUcetCislo, interakcia, t, obchodnyPartnerDetail } = this.props

		if (!zmluvnyUcetCislo) {
			return (
				<div className='zmluvny-ucet'>
					<div className='custom-empty-state' style={{ textAlign: 'left', paddingLeft: '12px' }}>
						{t('translation:ZmluvneUcty.Neviazané na zmluvný účet')}
					</div>
				</div>
			)
		}

		const backUrl = btoa(`${window.location.pathname}${window.location.search}`)

		const zostatokStavuUctu = this.renderZostatokStavuUctu(backUrl)

		return (
			<div className='row zmluvny-ucet'>
				<div className='col-6'>
					<table className='content-table'>
						<tbody>
							<tr>
								<td style={{ width: '150px' }}>{t('translation:ZmluvneUcty.Zmluvný účet')}</td>
								<td colSpan={2} style={{ lineHeight: '32px' }}>
									<strong>{get(zmluvnyUcet, 'cislo', '-')}</strong>
								</td>
							</tr>
							<tr>
								<td>{t('translation:ZmluvneUcty.Prijaté platby')}</td>
								<td>
									<strong
										className={cx(
											'zmluvny-ucet-header',
											get(zmluvnyUcet, 'doslePlatbySposob.id') == SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA ? 'red' : 'green'
										)}
									>
										{upperFirst(get(zmluvnyUcet, 'doslePlatbySposob.nazov', '-'))}
									</strong>
									{get(zmluvnyUcet, 'sepaMandat') && (
										<Tooltip
											html={
												<div style={{ textAlign: 'left' }}>
													{t('translation:ZmluvneUcty.Číslo SEPA mandátu')}&nbsp;
													<strong>{get(zmluvnyUcet, 'sepaMandat.cislo', '-')}</strong>
													<br />
													{t('translation:ZmluvneUcty.Stav SEPA mandátu')}&nbsp;
													<strong>{get(zmluvnyUcet, 'sepaMandat.sepaMandatStav.nazov', '-')}</strong>
													<br />
													{t('translation:ZmluvneUcty.Dátum podpisu')}&nbsp;
													{formatDate(get(zmluvnyUcet, 'sepaMandat.podpisDatum'), '-', 'DD.MM.YYYY')}
												</div>
											}
											position='right'
											trigger='mouseenter'
											theme='light'
										>
											<div
												className='label circle'
												data-color={getSepaMandatStavColor(get(zmluvnyUcet, 'sepaMandat.sepaMandatStav.id'))}
												style={{ lineHeight: '21px' }}
											/>
										</Tooltip>
									)}
									{get(zmluvnyUcet, 'sepaMandat.dokumentId') && (
										<a
											className='file'
											onClick={() =>
												openDataUriWindow(get(zmluvnyUcet, 'sepaMandat.dokumentId'), t('translation:ZmluvneUcty.SEPA Mandát'))
											}
											data-type='general'
											style={{
												cursor: 'pointer',
												lineHeight: '21px',
												verticalAlign: 'middle',
												marginLeft: '10px',
												backgroundSize: '17px auto'
											}}
										/>
									)}
								</td>
								<td>
									<div style={{ textAlign: 'right' }}>
										{get(zmluvnyUcet, 'doslePlatbySposob.id') == SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA
											? formatAddress(get(zmluvnyUcet, 'adresaDoslePlatby')) || '-'
											: IBAN.printFormat(get(zmluvnyUcet, 'doslePlatbyBankoveSpojenie.IBAN') || '') || '-'}
									</div>
								</td>
							</tr>
							<tr>
								<td>{t('translation:ZmluvneUcty.Odoslané platby')}</td>
								<td>
									<strong
										className={cx(
											'zmluvny-ucet-header',
											get(zmluvnyUcet, 'odoslanePlatbySposob.id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA ? 'red' : 'green'
										)}
									>
										{upperFirst(get(zmluvnyUcet, 'odoslanePlatbySposob.nazov', '-'))}
									</strong>
								</td>
								<td>
									<div style={{ textAlign: 'right' }}>
										{get(zmluvnyUcet, 'odoslanePlatbySposob.id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA
											? formatAddress(get(zmluvnyUcet, 'adresaOdoslanePlatby')) || '-'
											: IBAN.printFormat(get(zmluvnyUcet, 'odoslanePlatbyBankoveSpojenie.IBAN') || '') || '-'}
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className='col-6'>
					<div style={{ textAlign: 'right', verticalAlign: 'top', padding: '5px 10px' }}>
						<Tooltip html={t('translation:ZmluvneUcty.Detail zmluvného účtu')} position='bottom' trigger='mouseenter' theme='light'>
							<Link
								to={`${setRouteParams(ZMLUVNE_UCTY_DETAIL, get(interakcia, 'opCislo'), get(zmluvnyUcet, 'cislo'))}?backUrl=${backUrl}`}
								className='button'
								data-type='icon-button'
								data-icon='view'
							/>
						</Tooltip>
						<Permissions
							allowed={[PERMISSIONS.UKON_UDAJE_ZU]}
							render={(hasPerm, actions) => {
								if (hasPerm) {
									return (
										<Tooltip html={t('translation:ZmluvneUcty.Upraviť nastavenie ZÚ')} position='bottom' trigger='mouseenter' theme='light'>
											<Link
												to={`${setRouteParams(
													ZMLUVNE_UCTY_UPRAVIT,
													get(interakcia, 'opCislo'),
													get(zmluvnyUcet, 'cislo')
												)}?backUrl=${backUrl}`}
												style={{ marginLeft: '10px' }}
												className='button'
												data-type='icon-button'
												data-icon='edit'
											/>
										</Tooltip>
									)
								}

								return (
									<Tooltip
										html={<span>{t('translation:Common.Na vykonanie akcie nemáte potrebné oprávnenia')}</span>}
										position='bottom'
										trigger='mouseenter'
										theme='light'
									>
										<Link
											to={`${setRouteParams(
												ZMLUVNE_UCTY_UPRAVIT,
												get(interakcia, 'opCislo'),
												get(zmluvnyUcet, 'cislo')
											)}?backUrl=${backUrl}`}
											style={{ marginLeft: '10px' }}
											className={cx('button', { disabled: true })}
											data-type='icon-button'
											data-icon='edit'
											onClick={(e) => {
												e.preventDefault()
												actions.openForbiddenModal()
											}}
										/>
									</Tooltip>
								)
							}}
						/>
					</div>
					<table className='content-table'>
						<tbody>
							<tr>
								<td style={{ width: '160px' }}>{t('translation:ZmluvneUcty.e-Faktúra')}</td>
								<td>
									{!isEmpty(get(zmluvnyUcet, 'eFakturaEmaily', [])) ? (
										map(get(zmluvnyUcet, 'eFakturaEmaily', []), (item, index) => (
											<div className={'email-badge-wrapper'}>
												<div className='green-text' key={`email-${index}`}>
													<strong>{get(item, 'email')}</strong>
												</div>
												<div className={'email-badges'}>
													{item.pdf && get(obchodnyPartnerDetail, 'data.typ') === OBCHODNY_PARTNER_TYP.MOP && (
														<span className={'label'}>PDF</span>
													)}
													{item.xml && get(obchodnyPartnerDetail, 'data.typ') === OBCHODNY_PARTNER_TYP.MOP && (
														<span className={'label'}>XML</span>
													)}
												</div>
											</div>
										))
									) : (
										<span className='red-text'>
											<strong>{t('translation:Common.Neaktívna')}</strong>
										</span>
									)}
								</td>
							</tr>
							<tr>
								<td style={{ width: '160px' }}>{t('translation:ZmluvneUcty.Pripomienka platby')}</td>
								<td>
									{get(zmluvnyUcet, 'neposielatPripomienkuPlatby') ? (
										<span>
											<strong>{t('translation:Common.Neaktívna')}</strong>
										</span>
									) : (
										<span className='green-text'>
											<strong>{t('translation:Common.Aktívna')}</strong>
										</span>
									)}
								</td>
							</tr>
							<tr>
								<td colSpan={2}>{zostatokStavuUctu}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const zmluvnyUcetCislo = get(ownProps, 'zmluvnyUcet.cislo')
	const zostatok = get(state, `zostatokZmluvneUcty.zmluvneUcty[${zmluvnyUcetCislo}]`)
	return {
		interakcia: get(state, 'interakcie.detail.data'),
		obchodnyPartnerDetail: get(state, 'obchodnyPartner.detail'),
		zostatok,
		zmluvnyUcetCislo
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	zostatokZmluvneUctyActions: bindActionCreators(ZostatokZmluvneUctyActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(ZmluvnyUcetListItem)
