import dayjs from 'dayjs'
import { get, isEmpty, some, isBoolean } from 'lodash'

import { BUSINESS_CHANNELS } from '../../../../utils/enums'

const validate = (values, props) => {
	const { procesnyKonfigurator } = props

	const errors = {}

	if (!values.ukonVstup) {
		errors.ukonVstup = props.t('translation:Common.validate.Vstup do úkonu je povinný')
	}

	if (values.splnomocnenec && values.splnomocnenec.length > 120) {
		errors.splnomocnenec = props.t('translation:Common.validate.Splnomocnenec môže mať najviac 120 znakov')
	}

	if (values.podpisMiesto) {
		if (values.podpisMiesto.length > 100) {
			errors.podpisMiesto = props.t('translation:Common.validate.Miesto podpisu úkonu môže mať najviac 100 znakov')
		}
	}

	if (get(props, 'auth.businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE && isEmpty(values.dokumenty)) {
		errors.dokumenty = props.t('translation:Common.validate.Dokumenty pre BO kanál sú povinné')
	}

	if (get(props, 'auth.businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE && !values.datumPrijatiaZiadosti) {
		errors.datumPrijatiaZiadosti = props.t('translation:Common.validate.Dátum prijatia žiadosti je povinný')
	}

	if (values.datumPrijatiaZiadosti) {
		if (
			get(props, 'auth.businessChannel.actual.id') == BUSINESS_CHANNELS.ZSE_CENTRUM ||
			get(props, 'auth.businessChannel.actual.id') == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA
		) {
			if (values.datumPrijatiaZiadosti < dayjs().startOf('day') || values.datumPrijatiaZiadosti > dayjs().endOf('day')) {
				errors.datumPrijatiaZiadosti = props.t('translation:Common.validate.Dátum prijatia žiadosti nie je platný - zvoľte prosím aktuálny deň')
			}
		} else if (get(props, 'auth.businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE) {
			if (values.datumPrijatiaZiadosti > dayjs().endOf('day')) {
				errors.datumPrijatiaZiadosti = props.t('translation:Common.validate.Dátum prijatia žiadosti nie je platný - nemôžete zvoliť dátum v budúcnosti')
			}
		}
	}

	const splatkyPocetMin = +get(procesnyKonfigurator, 'data.ukony.planSplatok.standardneAtributy.splatkyPocetMin', 0)
	const splatkyPocetMax = +get(procesnyKonfigurator, 'data.ukony.planSplatok.standardneAtributy.splatkyPocetMax', 0)

	if (!values.splatkyPocet) {
		errors.splatkyPocet = props.t('translation:PlanySplatok.validate.Počet splátok je povinný')
	} else if (values.splatkyPocet < splatkyPocetMin || values.splatkyPocet > splatkyPocetMax) {
		errors.splatkyPocet = props.t('translation:PlanySplatok.validate.Hodnota musí byť celočíselná medzi {splatkyPocetMin} a {splatkyPocetMax}', {
			splatkyPocetMin,
			splatkyPocetMax
		})
	}

	if (!isBoolean(values.skontrolovaneZalohy)) {
		errors.skontrolovaneZalohy = props.t('translation:Common.validate.Hodnota je povinná')
	}

	if (!values.datumSplatnosti) {
		errors.datumSplatnosti = props.t('translation:PlanySplatok.validate.Dátum splatnosti je povinný')
	}

	if (!isEmpty(values.dokumenty)) {
		some(values.dokumenty, (dokument) => {
			if (!dokument.dataAsBase64) {
				errors.dokumenty = props.t('translation:Common.validate.Neplatný dokument', { nazovDokumentu: dokument.name })
				return true
			}
		})
	}

	if (values.poznamka && values.poznamka.length > 2000) {
		errors.poznamka = props.t('translation:Common.validate.Maximálna dĺžka poznámky je 2000 znakov')
	}

	return errors
}

export default validate
