import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { reduxForm, propTypes, getFormValues } from 'redux-form'
import PropTypes from 'prop-types'
import { get, find, upperFirst, map } from 'lodash'
import { Tooltip } from 'react-tippy'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { NumericFormat } from 'react-number-format'

// components
import FormInfo from '../../FormInfo'
import WrapperPodpisovanieDokumentov from '../../PodpisovanieDokumentov/WrapperPodpisovanieDokumentov'
import PlanSplatokAktualneNastavenie from '../PlanSplatokAktualneNastavenie'

// utils
import { setRouteParams, PLANY_SPLATOK_ZOZNAM } from '../../../utils/routes'
import { FORMS, TYP_PLATCU_PLANU_SPLATOK } from '../../../utils/enums'
import { getPlatcaTypString, getPlatcaTypLabel } from '../../../utils/planySplatok'
import { celeMeno } from '../../../utils/obchodnyPartner'
import { formatDate } from '../../../utils/date'

class PlanSplatokConfirm extends React.Component {
	static propTypes = {
		...propTypes,
		formValues: PropTypes.shape()
	}

	_mounted = false

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	render() {
		const {
			interakcia,
			ciselniky,
			formTitle,
			handleSubmit,
			scenarioOption,
			isDisabledNotification,
			isDisabledSignedPdf,
			isDisabledScenarNotSelected,
			templatePdf,
			onBackClick,
			schvalovacieKriteria,
			disallowScenarios,
			formValues,
			planSplatokAktualneNastavenie,
			t
		} = this.props

		const ukonVstup = find(get(ciselniky, 'ukonVstup', []), (item) => {
			return get(item, 'id') == get(formValues, 'ukonVstup')
		})

		const disabledBtn = (
			<button className='button' type='submit' disabled data-color='blue' style={{ marginLeft: '20px' }}>
				{t('translation:Common.Dokončiť')}
			</button>
		)

		let submitBtn = null
		if (get(templatePdf, 'isLoading')) {
			submitBtn = (
				<Tooltip
					html={<span>{t('translation:Common.Prebieha načítavanie dokumentu pre podpisovanie')}</span>}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					{disabledBtn}
				</Tooltip>
			)
		} else if (isDisabledScenarNotSelected) {
			submitBtn = (
				<Tooltip html={<span>{t('translation:Common.Pre pokračovanie zvoľte scenár')}</span>} position='left' trigger='mouseenter' theme='light'>
					{disabledBtn}
				</Tooltip>
			)
		} else if (isDisabledSignedPdf) {
			submitBtn = (
				<Tooltip html={<span>{t('translation:Common.Dokument musí byť podpísaný')}</span>} position='left' trigger='mouseenter' theme='light'>
					{disabledBtn}
				</Tooltip>
			)
		} else if (!isDisabledSignedPdf && isDisabledNotification) {
			submitBtn = (
				<Tooltip
					html={<span>{t('translation:Common.Na dokončenie úkonu je potrebné nastaviť notifikáciu')}</span>}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					{disabledBtn}
				</Tooltip>
			)
		} else if (!isDisabledNotification && !isDisabledSignedPdf) {
			submitBtn = (
				<button className='button pull-right' type='submit' data-color='blue' style={{ marginLeft: '20px' }}>
					{t('translation:Common.Dokončiť')}
				</button>
			)
		}

		let platcaComponent
		switch (get(formValues, 'platcaTyp')) {
			case TYP_PLATCU_PLANU_SPLATOK.SPLNOMOCNENEC: {
				platcaComponent = (
					<span>{`${get(formValues, 'splnomocnenie.splnomocnenec.cislo') || '0'} - ${
						celeMeno(get(formValues, 'splnomocnenie.splnomocnenec')) || '-'
					}`}</span>
				)
				break
			}
			case TYP_PLATCU_PLANU_SPLATOK.DEDIC:
				platcaComponent = <span>{get(formValues, 'dedic', '-') || '-'}</span>
				break
		}

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					<div className='pull-right'>{submitBtn}</div>
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<Link
						to={setRouteParams(PLANY_SPLATOK_ZOZNAM, get(interakcia, 'opCislo'))}
						className='button pull-right'
						data-type='outline'
						data-color='red'
					>
						{t('translation:Common.Zrušiť')}
					</Link>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<FormInfo schvalovacieKriteria={schvalovacieKriteria} />
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<thead>
									<tr>
										<th />
										<th>{t('translation:Common.Nové hodnoty')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Vstup do úkonu')}</strong>
										</td>
										<td>{upperFirst(get(ukonVstup, 'nazov', ''))}</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Miesto podpisu úkonu')}</strong>
										</td>
										<td>{get(formValues, 'podpisMiesto')}</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Dátum prijatia žiadosti')}</strong>
										</td>
										<td>{formatDate(get(formValues, 'datumPrijatiaZiadosti'), null, 'DD.MM.YYYY')}</td>
									</tr>
								</tbody>
							</table>
							<div className='inner-box'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<tbody>
										<tr>
											<td>
												<strong>{t('translation:PlanySplatok.Typ platcu')}</strong>
											</td>
											<td>{getPlatcaTypString(get(formValues, 'platcaTyp'), t) || '-'}</td>
										</tr>
										{platcaComponent && (
											<tr>
												<td>
													<strong>{getPlatcaTypLabel(get(formValues, 'platcaTyp'), t) || '-'}</strong>
												</td>
												<td>{platcaComponent}</td>
											</tr>
										)}
										<tr>
											<td>
												<strong>{t('translation:Common.Zmluvný účet')}</strong>
											</td>
											<td>{get(formValues, 'zuCislo')}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:PlanySplatok.Zahrnuté faktúry')}</strong>
											</td>
											<td style={{ padding: 0 }}>
												<table className='search-results-table no-pointer' cellSpacing='0' style={{ border: 'none' }}>
													<thead>
														<tr>
															<th>{t('translation:Common.Číslo faktúry')}</th>
															<th>{t('translation:Common.Otvorená suma')}</th>
														</tr>
													</thead>
													<tbody data-empty={t('translation:PlanySplatok.Neboli zvolené žiadne faktúry')}>
														{map(get(formValues, 'zdrojoveFaktury', []), (faktura, index) => (
															<tr key={`faktura_${index}`}>
																<td>{get(faktura, 'cislo', '-')}</td>
																<td style={{ whiteSpace: 'nowrap' }}>
																	<NumericFormat
																		thousandSeparator={' '}
																		decimalSeparator={','}
																		decimalScale={2}
																		fixedDecimalScale={true}
																		displayType='text'
																		suffix=' €'
																		value={get(faktura, 'sumaOtvorena')}
																		defaultValue='-'
																	/>
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:PlanySplatok.Počet splátok')}</strong>
											</td>
											<td>{get(formValues, 'splatkyPocet')}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:PlanySplatok.Dátum splatnosti 1 splátky (informatívny)')}</strong>
											</td>
											<td>{formatDate(get(formValues, 'datumSplatnosti'), null, 'DD.MM.YYYY')}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:PlanySplatok.Úrok')}</strong>
											</td>
											<td>{get(formValues, 'urok') ? t('translation:Common.Áno') : t('translation:Common.Nie')}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:PlanySplatok.Skontrolované zálohy')}</strong>
											</td>
											<td>{get(formValues, 'skontrolovaneZalohy') ? t('translation:Common.Áno') : t('translation:Common.Nie')}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Common.Poznámka (interná)')}</strong>
											</td>
											<td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{get(formValues, 'poznamkaInterna')}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Poznámka')}</strong>
										</td>
										<td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
											<div className='input-wrapper'>
												<textarea
													disabled='disabled'
													rows='5'
													className='form-control static'
													style={{ backgroundColor: 'white' }}
													value={get(formValues, 'poznamka')}
												/>
											</div>
										</td>
									</tr>
									<PlanSplatokAktualneNastavenie aktualneNastavenie={planSplatokAktualneNastavenie} />
								</tbody>
							</table>

							<WrapperPodpisovanieDokumentov
								scenarioOption={scenarioOption}
								disallowScenarios={disallowScenarios}
								schvalovacieKriteria={schvalovacieKriteria}
							/>
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.PLANY_SPLATOK_VYTVORENIE,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true
})(PlanSplatokConfirm)

const mapStateToProps = (state) => ({
	formValues: getFormValues(FORMS.PLANY_SPLATOK_VYTVORENIE)(state)
})

export default compose(withTranslation('components'), connect(mapStateToProps))(form)
