import { find, get, isInteger, parseInt } from 'lodash'
import jwtDecode from 'jwt-decode'

import { history } from '../utils/history'
import { setAccessToken, clearAccessToken } from '../utils/auth'
import {
	USER_LOGIN_SUCCESS,
	USER_LOGOUT,
	USER_BUSINESS_CHANNEL_CHANGED,
	USER_SET_DEFAULT_BUSINESS_CHANNEL,
	USER_UPDATE_CONFIG,
	USER_UPDATE_HEARTBEAT,
	USER_MIESTO_PODPISU_CHANGED
} from '../types/auth'
import { postReq, getReq, putReq } from '../utils/request'
import { resetStorage } from './ResetStorageActions'
import { loadCESCiselniky, loadCiselniky } from './CiselnikyActions'

import { setRouteParams, PREHLAD, INDEX } from '../utils/routes'
import { zaciatokInterakcie } from './Interakcie/ZaciatokInterakcie'

export function userLoginSuccess(user, defaultBusinessChannel, assignBusinessChannels) {
	return {
		type: USER_LOGIN_SUCCESS,
		payload: {
			user,
			defaultBusinessChannel,
			assignBusinessChannels
		}
	}
}

export function userBusinessChannelChanged(actualBusinessChannel) {
	return {
		type: USER_BUSINESS_CHANNEL_CHANGED,
		payload: {
			actualBusinessChannel
		}
	}
}
export function userMiestoPodpisChanged(podpisMiesto) {
	return {
		type: USER_MIESTO_PODPISU_CHANGED,
		payload: {
			podpisMiesto
		}
	}
}

export function userSetDefaultBusinessChannel(defaultBusinessChannel, assignBusinessChannels) {
	return {
		type: USER_SET_DEFAULT_BUSINESS_CHANNEL,
		payload: {
			defaultBusinessChannel,
			assignBusinessChannels
		}
	}
}

export function logout() {
	clearAccessToken()
	return {
		type: USER_LOGOUT
	}
}

export function logoutUser() {
	return (dispatch) => {
		dispatch(resetStorage())
		dispatch(logout())
		history.push('/prihlasenie')
	}
}

export function logInUser(token) {
	return async (dispatch, getStore) => {
		try {
			const { ciselniky, cesCiselniky } = getStore()
			const initUrl = localStorage.getItem('initUrl')
			const user = jwtDecode(token)
			setAccessToken(token)
			if (!ciselniky.data) {
				await loadCiselniky()(dispatch, getStore)
			}
			if (!cesCiselniky.data) {
				await loadCESCiselniky()(dispatch, getStore)
			}

			const res = await getReq('/api/v0/biznisKanaly')

			const defaultBusinessChannel = find(res.biznisKanaly, { default: true })
			const assignBusinessChannels = res.biznisKanaly
			let defaultChannel = null
			if (defaultBusinessChannel) {
				defaultChannel = {
					id: defaultBusinessChannel.id,
					nazov: defaultBusinessChannel.nazov,
					popis: defaultBusinessChannel.popis
				}
			}
			dispatch(userLoginSuccess(user, defaultChannel, assignBusinessChannels))

			if (initUrl) {
				const split = initUrl.split('/')
				if (split.length > 2) {
					const opCislo = parseInt(split[2])
					if (isInteger(opCislo)) {
						dispatch(zaciatokInterakcie(opCislo))
						history.push(initUrl)
					}
				}
				localStorage.removeItem('initUrl')
			} else {
				history.replace('/')
			}
		} catch (error) {
			history.replace('/prihlasenie')
		}
	}
}

export const setDefaultBusinessChannel = (body) => {
	return async (dispatch) => {
		const res = await putReq('/api/v0/biznisKanaly', null, body)
		const defaultBusinessChannel = find(res.biznisKanaly, { default: true })
		const assignBusinessChannels = res.biznisKanaly
		let defaultChannel = null
		if (defaultBusinessChannel) {
			defaultChannel = {
				id: defaultBusinessChannel.id,
				nazov: defaultBusinessChannel.nazov,
				popis: defaultBusinessChannel.popis
			}
		}
		dispatch(userSetDefaultBusinessChannel(defaultChannel, assignBusinessChannels))
	}
}

export const changeBusinessChannel = (actualChannel) => {
	return async (dispatch, getStore) => {
		const op = get(getStore(), 'obchodnyPartner.detail.data.cislo')

		dispatch(userBusinessChannelChanged(actualChannel))
		if (op) {
			history.push(setRouteParams(PREHLAD, op))
		} else {
			history.push(setRouteParams(INDEX))
		}
	}
}

export function heartbeat() {
	return async (dispatch) => {
		try {
			const res = await postReq('/api/v0/autorizacia/ping')
			if (get(res, 'accessToken')) {
				setAccessToken(res.accessToken)
				const user = jwtDecode(res.accessToken)

				dispatch({
					type: USER_UPDATE_CONFIG,
					payload: {
						frontendCacheTime: get(res, 'config.frontendCacheTime', null),
						stavUctuUpdateOffset: get(res, 'config.stavUctuUpdateOffset', null)
					}
				})
				dispatch({
					type: USER_UPDATE_HEARTBEAT,
					payload: {
						user
					}
				})
			} else {
				dispatch(logout())
				history.replace('/prihlasenie')
			}
		} catch (err) {
			// eslint-disable-next-line
			console.log(err)
		}
	}
}
