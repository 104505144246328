import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'

// utils
import { formatAddress } from '../../utils/address'
import { formatDate } from '../../utils/date'
import { formatDegrees } from '../../utils/degrees'

function UkonZalozenieOP(props) {
	const { ukonData, dokumenty, t, formatDocuments } = props

	const datumNarodenia = formatDate(get(ukonData, 'obchodnyPartner.narodenieDatum', null, 'DD.MM.YYYY'))

	const titulyPredMenom = formatDegrees(get(ukonData, 'obchodnyPartner.tituly', []), true)
	const titulyZaMenom = formatDegrees(get(ukonData, 'obchodnyPartner.tituly', []))

	const adresaZakaznika = formatAddress(get(ukonData, 'obchodnyPartner.adresaZakaznika', null))
	const adresaKorespondencna = formatAddress(get(ukonData, 'obchodnyPartner.adresaKorespondencna', null))

	return (
		<tbody>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Typ')}</strong>
				</td>
				<td>{get(ukonData, 'obchodnyPartner.typ')}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Druh')}</strong>
				</td>
				<td>{get(ukonData, 'obchodnyPartner.druh.nazov')}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Oslovenie')}</strong>
				</td>
				<td>{get(ukonData, 'obchodnyPartner.oslovenie.nazov')}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Meno')}</strong>
				</td>
				<td>{get(ukonData, 'obchodnyPartner.meno')}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Priezvisko')}</strong>
				</td>
				<td>{get(ukonData, 'obchodnyPartner.priezvisko')}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Titul pred menom')}</strong>
				</td>
				<td>{titulyPredMenom}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Titul za menom')}</strong>
				</td>
				<td>{titulyZaMenom}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Dátum narodenia')}</strong>
				</td>
				<td>{datumNarodenia}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Adresa trvalého pobytu')}</strong>
					<br />
					<strong>{t('containers:UkonDetailPage.Do rúk')}</strong>
				</td>
				<td>
					{adresaZakaznika}
					<br />
					{get(ukonData, 'obchodnyPartner.adresaZakaznika.doRukMeno')}
				</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Korešpondenčná adresa')}</strong>
					<br />
					<strong>{t('containers:UkonDetailPage.Do rúk')}</strong>
				</td>
				<td>
					{adresaKorespondencna}
					<br />
					{get(ukonData, 'obchodnyPartner.adresaKorespondencna.doRukMeno')}
				</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Kontaktne telefónne číslo')}</strong>
				</td>
				<td>{get(ukonData, 'obchodnyPartner.kontaktnyTelefon')}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('containers:UkonDetailPage.Kontaktný email')}</strong>
				</td>
				<td>{get(ukonData, 'obchodnyPartner.kontaktnyEmail')}</td>
			</tr>
			<tr>
				<td colSpan={2}>{formatDocuments(dokumenty)}</td>
			</tr>
		</tbody>
	)
}

UkonZalozenieOP.propTypes = {
	ukonData: PropTypes.shape(),
	ukonDataPred: PropTypes.shape(),
	dokumenty: PropTypes.array,
	t: PropTypes.func.isRequired,
	formatDocuments: PropTypes.func.isRequired
}

export default UkonZalozenieOP
