import { get } from 'lodash'
import dayjs from 'dayjs'
import { isEmail } from '../../../../utils/email'

const validate = (values, props) => {
	const errors = {}

	// validate form only if OP is not selected
	if (values.mode == 'NEW_OP') {
		if (!values.oslovenie) {
			errors.oslovenie = props.t('translation:Common.validate.Oslovenie je povinné')
		}
		if (!values.meno) {
			errors.meno = props.t('translation:Common.validate.Meno je povinné')
		} else if (values.meno.length > 40) {
			errors.meno = props.t('translation:Common.validate.Meno môže mať najviac 40 znakov')
		}
		if (!values.priezvisko) {
			errors.priezvisko = props.t('translation:Common.validate.Priezvisko je povinné')
		} else if (values.priezvisko.length > 40) {
			errors.priezvisko = props.t('translation:Common.validate.Priezvisko môže mať najviac 40 znakov')
		}

		if (!values.narodenieDatum) {
			errors.narodenieDatum = props.t('translation:Common.validate.Dátum narodenia je povinný')
		} else if (values.narodenieDatum < dayjs().add(-120, 'years').add(-1, 'day') || values.narodenieDatum > dayjs().add(-18, 'years')) {
			errors.narodenieDatum = props.t('translation:Common.validate.Dátum narodenia nie je v povolenom rozsahu')
		}

		if (values.telefonneCislo) {
			// check if phone number has slovak international format
			const isSK = new RegExp(/^\+421/, 'g')
			if (isSK.test(values.telefonneCislo)) {
				// check slovak international format phone
				const regex = new RegExp(/^\+421 ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/, 'g')
				const result = regex.test(values.telefonneCislo)
				if (!result) {
					errors.telefonneCislo = props.t('translation:Common.validate.Nesprávny formát telefónneho čisla, použite formát v tvare +421 XXX XXX XXX')
				}
			} else {
				// check if string has allow char 0-9 + [space]
				const regexFormat = new RegExp(/^\+[1-9]{1}[0-9\s]{1,14}$/, 'g')
				const regexWhitespaces = new RegExp(/\s{2,}/, 'g')
				const result = regexFormat.test(values.telefonneCislo) && !regexWhitespaces.test(values.telefonneCislo)
				if (!result) {
					errors.telefonneCislo = props.t('translation:Common.validate.Nesprávny formát telefónneho čisla, použite formát v tvare +421 XXX XXX XXX')
				}
			}
		}

		if (values.kontaktnyEmail) {
			if (!isEmail(values.kontaktnyEmail)) {
				errors.kontaktnyEmail = props.t('translation:Common.validate.Emailová adresa je neplatná')
			} else if (values.kontaktnyEmail.length > 2048) {
				errors.kontaktnyEmail = props.t('translation:Common.validate.Email môže mať najviac 2048 znakov')
			}
		}

		if (!get(values, 'adresaZakaznika.id')) {
			errors.adresaZakaznika = { id: props.t('translation:Common.validate.Adresa je povinná') }
		}
	} else if (!values.selectedOP) {
		errors.selectedOP = props.t('translation:Common.validate.Musíte zvoliť obchodného partnera')
	}

	return errors
}

export default validate
