import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import cx from 'classnames'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'
import { NumericFormat } from 'react-number-format'

// utils
import { formatAddress } from '../../utils/address'
import { getContexForRozpisyZaloh, getIconByKomoditaTyp, getRozpisZalohEditableStatus } from '../../utils/rozpisyZaloh'
import Permissions, { PERMISSIONS } from '../../utils/permissionsHoc'
import { formatDate } from '../../utils/date'
import { setRouteParams, ROZPISY_ZALOH_DETAIL, ROZPISY_ZALOH_UPRAVIT } from '../../utils/routes'

class RozpisyZalohTableRow extends React.Component {
	static propTypes = {
		interakcia: PropTypes.shape({
			data: PropTypes.shape({
				id: PropTypes.number.isRequired,
				opCislo: PropTypes.string.isRequired
			}).isRequired,
			isLoading: PropTypes.bool.isRequired
		}).isRequired,
		rozpisZaloh: PropTypes.shape().isRequired,
		odberneMiesta: PropTypes.shape().isRequired,
		procesnyKonfigurator: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)
	}

	render() {
		const { interakcia, rozpisZaloh, odberneMiesta, procesnyKonfigurator, t } = this.props

		const { zmluvnyUcet, odberneMiesto, miestoSpotreby } = getContexForRozpisyZaloh(rozpisZaloh, odberneMiesta)

		const produktTyp = get(odberneMiesto, 'komoditaTyp', { nazov: '-' })

		const productIcon = getIconByKomoditaTyp(produktTyp)

		const zalohyNeznizovatSumu = get(zmluvnyUcet, 'zalohyNeznizovatSumu') ? t('components:Áno') : t('components:Nie')

		const { isStavRozpisuZalohOtvoreny, isStavRozpisuZalohOtvorenyPreZmeny, isOutDated } = getRozpisZalohEditableStatus(rozpisZaloh, procesnyKonfigurator)

		let stavRozpisuZalohText
		let stavRozpisuZalohColor
		if (isStavRozpisuZalohOtvoreny && isOutDated) {
			stavRozpisuZalohText = t('components:RozpisyZalohTableRow.Po dátume platnosti')
			stavRozpisuZalohColor = 'grey'
		} else if (isStavRozpisuZalohOtvoreny) {
			stavRozpisuZalohText = t('components:RozpisyZalohTableRow.Otvorený')
			stavRozpisuZalohColor = 'red'
		} else {
			stavRozpisuZalohText = t('components:RozpisyZalohTableRow.Vyrovnaný')
			stavRozpisuZalohColor = 'green'
		}

		return (
			<div className='box'>
				<div className='box-content'>
					<div className='row bordered'>
						<div className='col-6'>
							<table className='content-table'>
								<tbody>
									<tr>
										<td style={{ width: '33%' }}>{t('components:RozpisyZalohTableRow.Zmluvný účet')}</td>
										<td style={{ width: '67%' }}>
											<strong>{get(rozpisZaloh, 'zmluvnyUcetCislo', '-')}</strong>
										</td>
									</tr>
									<tr>
										<td style={{ width: '33%' }}>{t('components:RozpisyZalohTableRow.Číslo zmluvy')}</td>
										<td style={{ width: '67%' }}>
											<strong>{get(rozpisZaloh, 'zmluvnyVztahCislo', '-')}</strong>
										</td>
									</tr>
									<tr>
										<td>{t('components:RozpisyZalohTableRow.Miesto spotreby')}</td>
										<td>
											<strong>{get(miestoSpotreby, 'adresa', null) ? formatAddress(get(miestoSpotreby, 'adresa')) : '-'}</strong>
										</td>
									</tr>
									{produktTyp.id == 1 && (
										<tr>
											<td>{t('components:RozpisyZalohTableRow.EIC')}</td>
											<td>
												<strong>{get(odberneMiesto, 'identifikator', '-')}</strong>
											</td>
										</tr>
									)}
									{produktTyp.id == 2 && (
										<tr>
											<td>{t('components:RozpisyZalohTableRow.POD')}</td>
											<td>
												<strong>{get(odberneMiesto, 'identifikator', '-')}</strong>
											</td>
										</tr>
									)}
								</tbody>
							</table>
							<div className='inner-box' style={{ marginRight: '10px' }}>
								<table>
									<tbody>
										<tr>
											<td style={{ width: '33%' }}>{t('components:RozpisyZalohTableRow.Produkt')}</td>
											<td style={{ width: '67%' }}>
												<strong className='icon'>
													{productIcon && <img src={productIcon} alt={produktTyp.nazov} />}
													<span>{produktTyp.nazov}</span>
												</strong>
											</td>
										</tr>
										<tr>
											<td>{t('components:RozpisyZalohTableRow.Stav rozpisu záloh')}</td>
											<td>
												<div className='label' data-color={stavRozpisuZalohColor}>
													{stavRozpisuZalohText}
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div className='col-6'>
							<table className='content-table'>
								<tbody>
									<tr>
										<td>{t('components:RozpisyZalohTableRow.Cyklus záloh')}</td>
										<td>
											<strong>{get(rozpisZaloh, 'zalohyCyklus.nazov', '-')}</strong>
										</td>
									</tr>
									<tr>
										<td>{t('components:RozpisyZalohTableRow.Výška záloh')}</td>
										<td>
											<strong>
												<NumericFormat
													thousandSeparator={' '}
													decimalSeparator={','}
													decimalScale={2}
													displayType='text'
													suffix=' €'
													value={rozpisZaloh.zalohySuma}
													defaultValue='-'
												/>
											</strong>
										</td>
									</tr>
									<tr>
										<td>{t('components:RozpisyZalohTableRow.Obdobie od - do')}</td>
										<td>
											<strong>
												{formatDate(get(rozpisZaloh, 'obdobieOd'), '', 'DD.MM.YYYY')} -{' '}
												{formatDate(get(rozpisZaloh, 'obdobieDo'), '', 'DD.MM.YYYY')}
											</strong>
										</td>
									</tr>
									<tr>
										<td>{t('components:RozpisyZalohTableRow.Fakturačná oblasť')}</td>
										<td>
											<Tooltip
												title={`${t('translation:Common.Kód')} = ${get(rozpisZaloh, 'fakturacnaOblast.nazov.id', '-')}`}
												position='top-start'
												trigger='mouseenter'
												theme='light'
											>
												<strong>{`${get(rozpisZaloh, 'fakturacnaOblast.nazov.nazov', '-')} (${
													get(rozpisZaloh, 'fakturacnaOblast.smart')
														? t('translation:Common.SMART')
														: t('translation:Common.NON SMART')
												})`}</strong>
											</Tooltip>
										</td>
									</tr>
									<tr>
										<td>{t('components:RozpisyZalohTableRow.Deň splatnosti záloh')}</td>
										<td>
											<strong>{get(zmluvnyUcet, 'zalohyDenSplatnosti.nazov', '-')}</strong>
										</td>
									</tr>
									<tr>
										<td>{t('components:RozpisyZalohTableRow.Neznižovať výšku záloh')}</td>
										<td>
											<strong>{zalohyNeznizovatSumu}</strong>
										</td>
									</tr>
								</tbody>
							</table>
							<div className='row'>
								<div className='col-6'>
									<Link
										to={setRouteParams(ROZPISY_ZALOH_DETAIL, get(interakcia, 'data.opCislo'), get(rozpisZaloh, 'cislo'))}
										className='button full-width'
										data-color='blue'
										data-type='outline'
										style={{ marginLeft: '10px', width: 'calc(100% - 10px)' }}
									>
										{t('components:RozpisyZalohTableRow.Detail rozpisu záloh')}
									</Link>
								</div>
								<div className='col-6'>
									<Permissions
										allowed={[PERMISSIONS.UKON_PREDDAVKOVE_PLATBY]}
										render={(hasPerm, actions) => {
											const btn = (
												<Link
													to={setRouteParams(ROZPISY_ZALOH_UPRAVIT, get(interakcia, 'data.opCislo'), get(rozpisZaloh, 'cislo'))}
													className={cx('button', 'full-width', {
														disabled: !hasPerm || !isStavRozpisuZalohOtvoreny || !isStavRozpisuZalohOtvorenyPreZmeny || isOutDated
													})}
													data-color='blue'
													onClick={(e) => {
														if (!hasPerm) {
															e.preventDefault()
															actions.openForbiddenModal()
														}
														if (!isStavRozpisuZalohOtvoreny || !isStavRozpisuZalohOtvorenyPreZmeny || isOutDated) {
															e.preventDefault()
														}
													}}
												>
													{t('components:RozpisyZalohTableRow.Upraviť rozpis záloh')}
												</Link>
											)

											let dokoncitBtn = btn
											if (!isStavRozpisuZalohOtvoreny || !isStavRozpisuZalohOtvorenyPreZmeny || isOutDated) {
												let text
												if (!isStavRozpisuZalohOtvoreny) {
													text = t('containers:RozpisZalohDetailPage.Rozpis záloh nie je otvorený')
												} else if (!isStavRozpisuZalohOtvorenyPreZmeny) {
													text = t('containers:RozpisZalohDetailPage.Rozpis záloh nie je otvorený pre zmenu')
												} else {
													text = t('containers:RozpisZalohDetailPage.Rozpis záloh je po dátume platnosti')
												}
												dokoncitBtn = (
													<Tooltip title={text} position='top-start' trigger='mouseenter' theme='light'>
														{btn}
													</Tooltip>
												)
											}
											return dokoncitBtn
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation('components')(RozpisyZalohTableRow)
