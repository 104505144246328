import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Link } from 'react-router-dom'
import { map, get, isEqual, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import cx from 'classnames'
import { withTranslation } from 'react-i18next'
import { isValid, getFormValues } from 'redux-form'
import { Tooltip } from 'react-tippy'

// atoms
import TextareaField from '../atoms/TextareaField'
import AcwSelector from '../atoms/AcwSelector'

// components
import ElementLoading from '../components/ElementLoading'
import ElementFailure from '../components/ElementFailure'
import DefaultModal from '../components/Modals/DefaultModal'
import GenericUkonForm, { GENERIC_FIELDS_KEY } from '../components/GenericUkon/VseobecnyPasivnyUkon/GenericUkonForm'
import { UKON_CATEGORY_ONE_KEY, PRIMARNY_UKON_KEY, UKON_TIME_RANGE_KEY } from '../components/GenericUkon/VseobecnyPasivnyUkon/GenericFieldArray'
import { ConditionalWrapper } from '../components/ConditionalWrapper'

// actions
import InterakcieActions from '../actions/Interakcie'
import * as UkonyActions from '../actions/UkonyActions'
import * as PonukaActions from '../actions/PonukaActions'
import * as TrackingActions from '../actions/TrackingActions'
import * as DigitalizaciaActions from '../actions/ObchodniPartneri/Digitalizacia'
import * as ExternalIntegrationsActions from '../actions/ExternalIntegrationsActions'

// utils
import { postReq } from '../utils/request'
import { DOKUMENT_TYP, FORMS as FORM, UKONY_CISELNIK, STAV_INDIKATORA, EXTERNAL_INTEGRATIONS } from '../utils/enums'
import { setRouteParams, PREHLAD, INDEX } from '../utils/routes'
import { getIsMop } from '../utils/obchodnyPartner'
import InteractionActionTableRow from '../components/TableRows/InteractionActionTableRow'
import { history } from '../utils/history'

dayjs.extend(duration)

const MAX_POZNAMKA_LENGTH = 2000

class UkoncenieInterakcie extends React.Component {
	static propTypes = {
		interakcieActions: PropTypes.shape({
			endInteraction: PropTypes.func.isRequired
		}).isRequired,
		interakcia: PropTypes.shape({
			detail: PropTypes.shape({
				data: PropTypes.shape({
					id: PropTypes.number.isRequired,
					opCislo: PropTypes.string.isRequired
				}).isRequired,
				isLoading: PropTypes.bool.isRequired
			}).isRequired,
			isLoading: PropTypes.bool.isRequired
		}).isRequired,
		obchodnyPartner: PropTypes.shape({
			data: PropTypes.shape()
		}),
		digitalizacia: PropTypes.shape(),
		ukonyByInterakciaId: PropTypes.shape(),
		ukonyActions: PropTypes.shape(),
		trackingActions: PropTypes.shape(),
		digitalizaciaActions: PropTypes.shape(),
		tracking: PropTypes.shape(),
		auth: PropTypes.shape(),
		ciselniky: PropTypes.shape(),
		cesCiselniky: PropTypes.shape(),
		isFormValid: PropTypes.bool.isRequired,
		t: PropTypes.func.isRequired,
		liveAgentSession: PropTypes.string,
		finesseSession: PropTypes.string,
		call250Session: PropTypes.string
	}

	constructor(props) {
		super(props)

		this.state = {
			ukonOptions: [],
			index: 0,
			poznamka: null,
			currentTime: dayjs(),
			currentTimeInterval: null,
			manualneUkony: {},
			isSubmitting: false,
			acwForSubmit: null,
			showLiveAgentModal: false,
			showFinesseModal: false,
			showCall250Modal: false
		}
	}

	sendUkon = async (manualnyUkon) => {
		const { interakcia, auth, obchodnyPartner } = this.props

		const dokumentyVstupne = map(get(manualnyUkon, 'dokumentyVstupne', []), (file) => ({
			contentType: file.type,
			nazov: file.name,
			data: file.dataAsBase64,
			typ: {
				id: DOKUMENT_TYP.VSTUPNY
			}
		}))

		const body = {
			typ: {
				id: UKONY_CISELNIK.VSEOBECNY_UKON_PASIVNY,
				nazov: 'všeobecný pasívny úkon'
			},
			opCislo: get(obchodnyPartner, 'data.cislo'),
			interakciaId: get(interakcia, 'detail.data.id'),
			riesitel: get(auth, 'user.id'),
			kanal: get(auth, 'businessChannel.actual'),
			trvanie: dayjs(get(manualnyUkon, `${UKON_TIME_RANGE_KEY}.endTime`)).diff(
				dayjs(get(manualnyUkon, `${UKON_TIME_RANGE_KEY}.startTime`)),
				'millisecond'
			),
			vstup: get(manualnyUkon, 'vstup'),
			zacatyOd: dayjs(get(manualnyUkon, `${UKON_TIME_RANGE_KEY}.startTime`)).toISOString(),
			poznamka: get(manualnyUkon, 'poznamka'),
			data: get(manualnyUkon, 'data'),
			dokumenty: [...dokumentyVstupne]
		}

		const result = await postReq(`/api/v2/ukony/vytvorit-a-spustit`, null, body)

		return result
	}

	clearAndRedirect = () => {
		history.push(`${INDEX}?clear=true`)
	}

	updateExterneIDs = (externeIDsForRequest = [], localSessionId, source) => {
		const { interakcia } = this.props

		const externeIDsInInterakcia = get(interakcia, 'detail.data.externeIDs')

		const sessionInInterakcia = externeIDsInInterakcia?.find((externeID) => externeID.source === source)
		if (sessionInInterakcia && sessionInInterakcia?.externalId !== localSessionId) {
			// Ak existuje session v interakcii a nezhoduje sa s lokálnou session, použijeme lokálnu
			externeIDsForRequest.push({
				source,
				externalId: localSessionId
			})
		} else if (sessionInInterakcia && sessionInInterakcia?.externalId === localSessionId) {
			// Ak existuje session v interakcii a zhoduje sa s lokálnou session, použijeme existujúcu z interakcie
			externeIDsForRequest.push(sessionInInterakcia)
		} else if (localSessionId) {
			// Ak neexistuje session v interakcii, ale existuje lokálna session, použijeme lokálnu
			externeIDsForRequest.push({
				externalId: localSessionId,
				source
			})
		}

		return externeIDsForRequest
	}

	formatEndInteractionBody = (primarnyUkonId) => {
		const { poznamka, acwForSubmit } = this.state
		const { liveAgentSession, finesseSession, call250Session } = this.props

		// CP-3008
		const formattedAcw = acwForSubmit ? [{ odpoved: acwForSubmit, kod: 'digi' }] : []

		const externeIDsForRequest = []
		if (liveAgentSession) {
			this.updateExterneIDs(externeIDsForRequest, liveAgentSession, EXTERNAL_INTEGRATIONS.LIVE_AGENT)
		}
		if (finesseSession) {
			this.updateExterneIDs(externeIDsForRequest, finesseSession, EXTERNAL_INTEGRATIONS.FINESSE)
		}
		if (call250Session) {
			this.updateExterneIDs(externeIDsForRequest, call250Session, EXTERNAL_INTEGRATIONS.CALL250)
		}

		return {
			poznamka,
			acw: formattedAcw,
			externeIDs: externeIDsForRequest,
			primarnyUkonId
		}
	}

	submitInteraction = async () => {
		try {
			const { interakcia, interakcieActions, isFormValid, liveAgentSession, finesseSession, call250Session } = this.props
			const { manualneUkony, isSubmitting, poznamka } = this.state

			if (isSubmitting || !isFormValid || get(interakcia, 'isLoading') || (poznamka && poznamka.length > MAX_POZNAMKA_LENGTH)) {
				return
			}

			this.setState({
				isSubmitting: true
			})

			const sendUkonyPromises = map(manualneUkony, this.sendUkon)
			const results = await Promise.all(sendUkonyPromises)

			const primarnyIndex = Object.values(manualneUkony)?.findIndex((manualnyUkon) => get(manualnyUkon, 'primarny') === true)
			const primarnyUkonId = get(results[primarnyIndex], 'response.content.id')
			const endInteractionBody = this.formatEndInteractionBody(primarnyUkonId)

			interakcieActions.endInteraction(endInteractionBody)
			// If LA session is active, check if user want to end LiveAgent session
			if (liveAgentSession) {
				this.setState({ showLiveAgentModal: true })
			} else if (finesseSession) {
				this.setState({ showFinesseModal: true })
			} else if (call250Session) {
				this.setState({ showCall250Modal: true })
			} else {
				this.clearAndRedirect()
			}
		} catch (e) {
			// eslint-disable-next-line
			console.log(e)
		} finally {
			this.setState({
				isSubmitting: false
			})
		}
	}

	onChangeHandler = (e) => {
		this.setState({
			poznamka: e.currentTarget.value
		})
	}

	async componentDidMount() {
		const { auth, ukonyActions, ponukaActions, digitalizaciaActions } = this.props

		// load all ukon assigned to current interakcion
		ukonyActions.loadUkonyByInteractionId()
		const currentTimeInterval = setInterval(() => {
			this.setState({
				currentTime: dayjs()
			})
		}, 1000 * 10) // every 10 seconds
		this.setState({ currentTimeInterval })

		const activeBusinessChannel = get(auth, 'businessChannel.actual.id')
		const ponuka = await ponukaActions.loadPonukaObchodnyPartner({
			kanalId: activeBusinessChannel,
			kontextId: 'ukoncenie-interakcie'
		})

		digitalizaciaActions.loadDigitalizacia()

		const options =
			ponuka?.map((option) => {
				return {
					label: option?.nazov,
					value: option?.nazov
				}
			}) || []

		this.setState({
			...this.state,
			ukonOptions: [...options]
		})
	}

	// NOTE: CP-1370
	componentDidUpdate(prevProps) {
		const { isSubmitting } = this.state
		const { interakcia } = this.props

		if (get(prevProps, 'interakcia.isLoading') && !get(interakcia, 'isLoading') && isSubmitting) {
			this.setState({
				isSubmitting: false
			})
		}
	}

	componentWillUnmount() {
		const { currentTimeInterval } = this.state

		if (currentTimeInterval) {
			clearInterval(currentTimeInterval)
		}
	}

	enableDokoncitButton() {
		const { formValues, isFormValid, interakcia, t } = this.props
		const { poznamka, isSubmitting } = this.state

		let isUkonValid = true
		let isPrimaryUkonValid = true
		let tooltipText = ''

		if (!isEmpty(formValues?.ukony)) {
			isPrimaryUkonValid = formValues.ukony.some((ukon) => ukon[PRIMARNY_UKON_KEY] === true)

			tooltipText = !isPrimaryUkonValid ? t('containers:UkoncenieInterakcie.Vyberte primárny úkon') : ''

			isUkonValid = formValues.ukony.every((manualnyUkon) => {
				if (manualnyUkon && manualnyUkon[UKON_CATEGORY_ONE_KEY]) return true
				return false
			})
		}

		let isPoznamkaValid = true
		if (poznamka) {
			isPoznamkaValid = poznamka.length <= MAX_POZNAMKA_LENGTH
		}

		const isButtonEnabled = isFormValid && !get(interakcia, 'isLoading') && !isSubmitting && isPoznamkaValid && isUkonValid && isPrimaryUkonValid

		return { isButtonEnabled, tooltipText }
	}

	commonContentContainer = (content) => {
		const { interakcia, obchodnyPartner, t } = this.props
		const { currentTime, showRemoveConfirmModal } = this.state

		const interakciaStartTime = dayjs(get(interakcia, 'detail.data.vytvorenaOd')).startOf('minute')
		const currentTimeMinute = currentTime.startOf('minute')
		const interactiaDlzkaTrvania = parseInt(dayjs.duration(currentTimeMinute.diff(interakciaStartTime)).asMinutes()) // ignore float values
		const opMeno = [obchodnyPartner.data.meno || '', obchodnyPartner.data.priezvisko || ''].join(' ').trim() || null
		const interakciaLabel = opMeno ? `Interakcia ${opMeno}` : ''

		const { isButtonEnabled, tooltipText } = this.enableDokoncitButton()

		let modal
		if (get(showRemoveConfirmModal, 'visible')) {
			modal = (
				<DefaultModal
					modalTitle={t('translation:Common.Upozornenie!')}
					modalContent={t('translation:Common.Naozaj chcete úkon odstrániť?')}
					rightButton={{
						onClick: () =>
							this.setState({
								showRemoveConfirmModal: {
									visible: false,
									index: null
								}
							}),
						text: t('translation:Common.Späť'),
						color: 'blue'
					}}
					leftButton={{
						onClick: () => {
							// TODO - add remove of field in array
							// this.removeUkon(get(showRemoveConfirmModal, 'index'))
							this.setState({
								showRemoveConfirmModal: {
									visible: false,
									index: null
								}
							})
						},
						text: t('translation:Common.Odstrániť'),
						color: 'red',
						outline: true
					}}
					visible
				/>
			)
		}

		return (
			<>
				{modal}
				<div className='content-header'>
					<div style={{ display: 'flex' }}>
						<div style={{ flexGrow: 1 }}>
							<Link
								to={setRouteParams(PREHLAD, get(interakcia, 'detail.data.opCislo'))}
								className='button pull-left'
								data-type='back-button'
								data-color='blue'
							>
								{t('containers:Späť')}
							</Link>
							<div className='header-title'>{t('containers:UkoncenieInterakcie.Ukončenie interakcie')}</div>
						</div>
						<div>
							<ConditionalWrapper
								condition={tooltipText}
								wrapper={(children) => (
									<Tooltip html={<span>{tooltipText}</span>} position='left' trigger='mouseenter' theme='light'>
										{children}
									</Tooltip>
								)}
							>
								<button
									onClick={this.submitInteraction}
									className={cx('button', { disabled: !isButtonEnabled })}
									disabled={!isButtonEnabled}
									data-color='blue'
									style={{ marginLeft: '20px' }}
								>
									{t('containers:Dokončiť')}
								</button>
							</ConditionalWrapper>
						</div>
					</div>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<div className='box-header clearfix'>
							<strong className='pull-left' data-text-size='medium'>
								{interakciaLabel}
							</strong>
							<div className='interaction-duration pull-right' style={{ marginTop: '3px' }}>
								<strong>{t('containers:UkoncenieInterakcie.Dĺžka interakcie')} </strong>
								<span style={{ fontWeight: 400 }}>
									{interakciaStartTime.format('HH:mm')} - {currentTimeMinute.format('HH:mm')} ({interactiaDlzkaTrvania} min)
								</span>
							</div>
						</div>
						{content}
					</div>
				</div>
			</>
		)
	}

	// NOTE: CP-3209
	showAcwSelector = (umrtieDatum, digitalizaciaDefinicie) => {
		if (umrtieDatum !== null) {
			return false
		}
		return digitalizaciaDefinicie.some((definicia) => {
			return definicia?.stav === STAV_INDIKATORA.AMBER || definicia?.stav === STAV_INDIKATORA.RED
		})
	}

	render() {
		const {
			interakcia,
			t,
			ukonyByInterakciaId,
			obchodnyPartnerDetail,
			cesCiselniky,
			externalIntegrationsActions,
			liveAgentSession,
			finesseSession,
			call250Session
		} = this.props
		const { isSubmitting, manualneUkony, ukonOptions, poznamka, currentTime } = this.state

		const interakciaStartTime = dayjs(get(interakcia, 'detail.data.vytvorenaOd')).startOf('minute')

		if (get(ukonyByInterakciaId, 'isLoading') || isSubmitting) {
			return this.commonContentContainer(<ElementLoading />)
		}

		if (get(ukonyByInterakciaId, 'isFailure')) {
			return this.commonContentContainer(<ElementFailure />)
		}

		if (liveAgentSession && this.state.showLiveAgentModal) {
			return (
				<DefaultModal
					modalTitle={t('containers:UkoncenieInterakcie.Prepojenie interakcie na LiveAgent ticket')}
					modalContent={t('containers:UkoncenieInterakcie.Chcete ukončením interakcie ukončiť aj prácu s aktuálnym LiveAgent ticketom?', {
						ticket: liveAgentSession
					})}
					leftButton={{
						onClick: () => {
							this.setState({ showLiveAgentModal: false })
							this.clearAndRedirect()
						},
						text: t('containers:UkoncenieInterakcie.Pokračovať'),
						color: 'blue',
						outline: true
					}}
					rightButton={{
						onClick: () => {
							externalIntegrationsActions.clearLiveAgentSession()
							this.setState({ showLiveAgentModal: false })
							this.clearAndRedirect()
						},
						text: t('containers:UkoncenieInterakcie.Ukončiť'),
						color: 'blue'
					}}
					visible
				/>
			)
		}

		if (finesseSession && this.state.showFinesseModal) {
			return (
				<DefaultModal
					modalTitle={t('containers:UkoncenieInterakcie.Prepojenie interakcie na Finesse hovor')}
					modalContent={t('containers:UkoncenieInterakcie.Chcete ukončením interakcie ukončiť aj prácu s aktuálnym Finesse hovorom?', {
						ticket: finesseSession
					})}
					leftButton={{
						onClick: () => {
							this.setState({ showFinesseModal: false })
							this.clearAndRedirect()
						},
						text: t('containers:UkoncenieInterakcie.Pokračovať'),
						color: 'blue',
						outline: true
					}}
					rightButton={{
						onClick: () => {
							externalIntegrationsActions.clearFinesseSession()
							this.setState({ showFinesseModal: false })
							this.clearAndRedirect()
						},
						text: t('containers:UkoncenieInterakcie.Ukončiť'),
						color: 'blue'
					}}
					visible
				/>
			)
		}

		if (call250Session && this.state.showCall250Modal) {
			return (
				<DefaultModal
					modalTitle={t('containers:UkoncenieInterakcie.Prepojenie interakcie na Call 250')}
					modalContent={t('containers:UkoncenieInterakcie.Chcete ukončením interakcie ukončiť aj prácu s aktuálnym ID vyvolania?', {
						ticket: call250Session
					})}
					leftButton={{
						onClick: () => {
							this.setState({ showCall250Modal: false })
							this.clearAndRedirect()
						},
						text: t('containers:UkoncenieInterakcie.Pokračovať'),
						color: 'blue',
						outline: true
					}}
					rightButton={{
						onClick: () => {
							externalIntegrationsActions.clearCall250Session()
							this.setState({ showCall250Modal: false })
							this.clearAndRedirect()
						},
						text: t('containers:UkoncenieInterakcie.Ukončiť'),
						color: 'blue'
					}}
					visible
				/>
			)
		}

		const acwOptions = get(cesCiselniky, 'dovodNedigitalizacia', [])
		const digitalizaciaDefinicie = get(this.props, 'digitalizacia.data.definicie', [])
		const umrtieDatum = get(obchodnyPartnerDetail, 'data.umrtieDatum', null)

		const isMOP = getIsMop(get(obchodnyPartnerDetail, 'data.skupinaOpravneni'))

		const ukony = map(get(ukonyByInterakciaId, 'data', []), (ukon) => <InteractionActionTableRow key={`ukon-${ukon.id}`} ukon={ukon} />)

		return this.commonContentContainer(
			<div className='box-content'>
				<GenericUkonForm
					onSubmit={(formValues) => {
						if (!isEqual(manualneUkony, formValues?.[GENERIC_FIELDS_KEY])) {
							this.setState({
								...this.state,
								manualneUkony: { ...formValues?.[GENERIC_FIELDS_KEY] }
							})
						}
					}}
					interakciaStartTime={interakciaStartTime}
					currentTime={currentTime}
					ukonOptions={ukonOptions}
					isMOP={isMOP}
					ukony={ukony}
				/>
				{this.showAcwSelector(umrtieDatum, digitalizaciaDefinicie) && (
					<div style={{ margin: '32px 0' }}>
						<strong style={{ display: 'block', marginBottom: '10px' }}>
							{t('containers:UkoncenieInterakcie.Uveďte dôvod neaktualizácie údajov/nedigitalizácie zákazníka')}
						</strong>
						<AcwSelector
							acwValue={this.state.acwForSubmit}
							acwOptions={acwOptions}
							onChange={(newAcwOption) => this.setState({ acwForSubmit: newAcwOption })}
						/>
					</div>
				)}
				<TextareaField
					meta={{
						error:
							poznamka && poznamka.length > MAX_POZNAMKA_LENGTH
								? t('containers:UkoncenieInterakcie.Poznámka može mať najviac znakov', { pocet: MAX_POZNAMKA_LENGTH })
								: false,
						touched: true
					}}
					placeholder={t('containers:UkoncenieInterakcie.Tu napíšte ľubovoľnú poznámku k realizovanej interakcii')}
					showLabel
					input={{
						onChange: this.onChangeHandler
					}}
					label={t('containers:UkoncenieInterakcie.Poznámka')}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie,
	obchodnyPartner: state.obchodnyPartner.detail,
	ukonyByInterakciaId: state.ukony.ukonyByInterakciaId,
	ciselniky: state.ciselniky.data,
	cesCiselniky: state.cesCiselniky.data,
	tracking: state.tracking,
	auth: state.auth,
	isFormValid: isValid(FORM.GENERIC_UKON_UKONCIT_INTERAKCIU)(state),
	formValues: getFormValues(FORM.GENERIC_UKON_UKONCIT_INTERAKCIU)(state),
	digitalizacia: state.obchodnyPartner.digitalizacia,
	liveAgentSession: get(state, 'externalIntegrations.liveAgentId'),
	finesseSession: get(state, 'externalIntegrations.finesseId'),
	call250Session: get(state, 'externalIntegrations.call250Id')
})

const mapDispatchToProps = (dispatch) => ({
	interakcieActions: bindActionCreators(InterakcieActions, dispatch),
	ukonyActions: bindActionCreators(UkonyActions, dispatch),
	trackingActions: bindActionCreators(TrackingActions, dispatch),
	ponukaActions: bindActionCreators(PonukaActions, dispatch),
	digitalizaciaActions: bindActionCreators(DigitalizaciaActions, dispatch),
	externalIntegrationsActions: bindActionCreators(ExternalIntegrationsActions, dispatch)
})

export default compose(withTranslation('containers'), connect(mapStateToProps, mapDispatchToProps))(UkoncenieInterakcie)
