import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { isEmpty, get, forEach } from 'lodash'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { compose, bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'
import { NumericFormat } from 'react-number-format'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'

// components
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'
import ElementEmptyContent from '../../components/ElementEmptyContent'

// utils
import { getContexForRozpisyZaloh, getIconByKomoditaTyp, getItemColorByState, getRozpisZalohEditableStatus } from '../../utils/rozpisyZaloh'
import { formatAddress } from '../../utils/address'
import Permissions, { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import { formatDate } from '../../utils/date'
import { setRouteParams, ROZPISY_ZALOH_ZOZNAM, ROZPISY_ZALOH_UPRAVIT } from '../../utils/routes'

class RozpisZalohDetailPage extends React.Component {
	static propTypes = {
		interakcia: PropTypes.shape({
			data: PropTypes.shape({
				id: PropTypes.number.isRequired,
				opCislo: PropTypes.string.isRequired
			}).isRequired,
			isLoading: PropTypes.bool.isRequired
		}).isRequired,
		procesnyKonfigurator: PropTypes.shape({
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired,
			data: PropTypes.shape()
		}).isRequired,
		rozpisZaloh: PropTypes.shape(),
		odberneMiesta: PropTypes.shape(),
		computedMatch: PropTypes.shape(),
		obchodnyPartnerActions: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		const { computedMatch, obchodnyPartnerActions } = this.props
		const { cisloRozpisZaloh } = computedMatch.params
		obchodnyPartnerActions.loadRozpisZaloh(cisloRozpisZaloh)
		obchodnyPartnerActions.loadAllOdberneMiesta(0)
		// TODO: 0 nastavena ako cache time - hotfix problemu https://jira.zsee.sk/jira/browse/CP-800
		// Musime prepisat tak, aby sa dotahovali iba konkretne entity pre rozpis zaloh nakolko uz mame endpointy pre detaily entit
	}

	render() {
		const { interakcia, rozpisZaloh, odberneMiesta, procesnyKonfigurator, t } = this.props

		if (rozpisZaloh.isLoading || odberneMiesta.isLoading || procesnyKonfigurator.isLoading) {
			return this.loadingContentElement()
		}
		if (rozpisZaloh.isFailure || procesnyKonfigurator.isFailure || odberneMiesta.isFailure) {
			return this.failureContentElement()
		}
		if (!odberneMiesta.allDataPrepared) {
			return this.loadingContentElement()
		}
		if (isEmpty(rozpisZaloh.data)) {
			return this.emptyContentElement()
		}
		if (!get(procesnyKonfigurator, 'data.ukony')) {
			return this.emptyContentElement(t('containers:Ľutujeme ale nepodarilo sa načítať Procesný konfigurátor'))
		}

		const { zmluvnyUcet, odberneMiesto, miestoSpotreby } = getContexForRozpisyZaloh(rozpisZaloh.data, odberneMiesta.allData)

		const produktTyp = get(odberneMiesto, 'komoditaTyp', { nazov: '-' })
		const productIcon = getIconByKomoditaTyp(produktTyp)
		const zalohyNeznizovatSumu = get(zmluvnyUcet, 'zalohyNeznizovatSumu') ? t('containers:Áno') : t('containers:Nie')

		const { referencnaVyskaZaloh, polozkyRozpisuZaloh, isStavRozpisuZalohOtvoreny, isStavRozpisuZalohOtvorenyPreZmeny, isOutDated } =
			getRozpisZalohEditableStatus(rozpisZaloh, procesnyKonfigurator)

		// const history = []
		// times(5, (i) => {
		// 	history.push(<tr key={i}>
		// 		<td>
		// 			<a href="/" className="file" data-type="general">
		// 			historia_zaloh_Apr_2018.pdf
		// 			</a>
		// 		</td>
		// 		<td>
		// 		1.4.2018
		// 		</td>
		// 		<td>
		// 			<a className="button" data-type="icon-button" data-icon="view"></a>
		// 			<a className="button" data-type="icon-button" data-icon="print"></a>
		// 			<a className="button" data-type="icon-button" data-icon="download"></a>
		// 			<a className="button" data-type="icon-button" data-icon="send"></a>
		// 		</td>
		// 	</tr>)
		// })

		const tHead = []
		const tBodyAktualneNastavene = []
		let tBodyAktualneNastaveneTotalSuma = 0
		const tBodyPredpokladaneZuctovanie = []
		forEach(polozkyRozpisuZaloh, (polozka, index) => {
			tBodyAktualneNastaveneTotalSuma += polozka.suma
			const className = getItemColorByState(polozka.state)
			tHead.push(
				<th key={index} className={className}>
					{formatDate(get(polozka, 'datum'), '', 'DD.MM.YYYY')}
				</th>
			)
			tBodyAktualneNastavene.push(
				<td key={`aktualneNastavene-${index}`} className={className}>
					<strong>
						<NumericFormat
							thousandSeparator={' '}
							decimalSeparator={','}
							decimalScale={2}
							displayType='text'
							suffix=' €'
							value={polozka.suma}
							defaultValue='-'
						/>
					</strong>
				</td>
			)
			tBodyPredpokladaneZuctovanie.push(
				<td key={`predpokladaneZuctovanie-${index}`}>
					<strong />
				</td>
			)
		})
		return this.commonContentContainer(
			<>
				<div className='content-header clearfix'>
					<Link
						to={setRouteParams(ROZPISY_ZALOH_ZOZNAM, get(interakcia, 'data.opCislo'))}
						className='button pull-left'
						data-type='back-button'
						data-color='blue'
					>
						{t('containers:Späť')}
					</Link>
					<div className='header-title pull-left'>
						{t('containers:RozpisZalohDetailPage.Detail rozpisu záloh')} ({get(rozpisZaloh, 'data.cislo')})
					</div>
					<Permissions
						allowed={[PERMISSIONS.UKON_PREDDAVKOVE_PLATBY]}
						render={(hasPerm, actions) => {
							const btn = (
								<Link
									to={setRouteParams(ROZPISY_ZALOH_UPRAVIT, get(interakcia, 'data.opCislo'), get(rozpisZaloh, 'data.cislo'))}
									className={cx('button', 'pull-right', {
										disabled: !hasPerm || !isStavRozpisuZalohOtvoreny || !isStavRozpisuZalohOtvorenyPreZmeny || isOutDated
									})}
									data-color='blue'
									onClick={(e) => {
										if (!hasPerm) {
											e.preventDefault()
											actions.openForbiddenModal()
										}
										if (!isStavRozpisuZalohOtvoreny || !isStavRozpisuZalohOtvorenyPreZmeny || isOutDated) {
											e.preventDefault()
										}
									}}
								>
									{t('containers:RozpisZalohDetailPage.Upraviť rozpis záloh')}
								</Link>
							)

							let dokoncitBtn = btn
							if (!isStavRozpisuZalohOtvoreny || !isStavRozpisuZalohOtvorenyPreZmeny || isOutDated) {
								let text
								if (!isStavRozpisuZalohOtvoreny) {
									text = t('containers:RozpisZalohDetailPage.Rozpis záloh nie je otvorený')
								} else if (!isStavRozpisuZalohOtvorenyPreZmeny) {
									text = t('containers:RozpisZalohDetailPage.Rozpis záloh nie je otvorený pre zmenu')
								} else {
									text = t('containers:RozpisZalohDetailPage.Rozpis záloh je po dátume platnosti')
								}
								dokoncitBtn = (
									<Tooltip title={text} position='top-start' trigger='mouseenter' theme='light'>
										{btn}
									</Tooltip>
								)
							}
							return <div className='pull-right'>{dokoncitBtn}</div>
						}}
					/>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<div className='box-content'>
							<div className='inner-box'>
								<div className='row'>
									<div className='col-7'>
										<table>
											<tbody>
												<tr>
													<td>{t('containers:RozpisZalohDetailPage.Zmluvný účet')}</td>
													<td>
														<strong>{get(zmluvnyUcet, 'cislo', '-')}</strong>
													</td>
												</tr>
												<tr>
													<td>{t('containers:RozpisZalohDetailPage.Produkt')}</td>
													<td>
														<strong className='icon'>
															{productIcon && <img src={productIcon} alt={produktTyp.nazov} />}
															<span>{produktTyp.nazov}</span>
														</strong>
													</td>
												</tr>
												<tr>
													<td>{t('containers:RozpisZalohDetailPage.Miesto spotreby')}</td>
													<td>
														<strong>{formatAddress(get(miestoSpotreby, 'adresa')) || '-'}</strong>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div className='col-5'>
										<table>
											<tbody>
												{produktTyp.id == 1 && (
													<tr>
														<td>{t('containers:RozpisZalohDetailPage.EIC')}</td>
														<td>
															<strong>{get(odberneMiesto, 'identifikator', '-')}</strong>
														</td>
													</tr>
												)}
												{produktTyp.id == 2 && (
													<tr>
														<td>{t('containers:RozpisZalohDetailPage.POD')}</td>
														<td>
															<strong>{get(odberneMiesto, 'identifikator', '-')}</strong>
														</td>
													</tr>
												)}
												<tr>
													<td>{t('containers:RozpisZalohDetailPage.Fakturačná oblasť')}</td>
													<td>
														<Tooltip
															title={`${t('translation:Common.Kód')} = ${get(
																rozpisZaloh,
																'data.fakturacnaOblast.nazov.id',
																'-'
															)}`}
															position='top-start'
															trigger='mouseenter'
															theme='light'
														>
															<strong>{`${get(rozpisZaloh, 'data.fakturacnaOblast.nazov.nazov', '-')} (${
																get(rozpisZaloh, 'data.fakturacnaOblast.smart')
																	? t('translation:Common.SMART')
																	: t('translation:Common.NON SMART')
															})`}</strong>
														</Tooltip>
													</td>
												</tr>
												<tr>
													<td>{t('containers:RozpisZalohDetailPage.Obdobie od do')}</td>
													<td>
														<strong>
															{formatDate(get(rozpisZaloh, 'data.obdobieOd'), null, 'DD.MM.YYYY')} -{' '}
															{formatDate(get(rozpisZaloh, 'data.obdobieDo'), null, 'DD.MM.YYYY')}
														</strong>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col-4'>
									<table className='content-table'>
										<thead>
											<tr>
												<th>{t('containers:RozpisZalohDetailPage.Základné informácie')}</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>{t('containers:RozpisZalohDetailPage.Cyklus záloh')}</td>
												<td>
													<strong>{get(rozpisZaloh, 'data.zalohyCyklus.nazov', '-')}</strong>
												</td>
											</tr>
											<tr>
												<td>{t('containers:RozpisZalohDetailPage.Referenčná výška záloh')}</td>
												<td>
													<strong>
														<NumericFormat
															thousandSeparator={' '}
															decimalSeparator={','}
															decimalScale={2}
															displayType='text'
															suffix=' €'
															value={referencnaVyskaZaloh}
															defaultValue='-'
														/>
													</strong>
												</td>
											</tr>
											<tr>
												<td>{t('containers:RozpisZalohDetailPage.Výška záloh')}</td>
												<td>
													<strong>
														<NumericFormat
															thousandSeparator={' '}
															decimalSeparator={','}
															decimalScale={2}
															displayType='text'
															suffix=' €'
															value={get(rozpisZaloh, 'data.zalohySuma')}
															defaultValue='-'
														/>
													</strong>
												</td>
											</tr>
											<tr>
												<td>{t('containers:RozpisZalohDetailPage.Deň splatnosti záloh')}</td>
												<td>
													<strong>{get(zmluvnyUcet, 'zalohyDenSplatnosti.nazov', '-')}</strong>
												</td>
											</tr>
											<tr>
												<td>{t('containers:RozpisZalohDetailPage.Neznižovať výšku záloh')}</td>
												<td>
													<strong>{zalohyNeznizovatSumu}</strong>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div className='col-8'>
									{/* <table className="content-table bordered hoverable" cellSpacing="0">
										<thead>
											<tr>
												<th colSpan="2">{t('containers:RozpisZalohDetailPage.História záloh')}</th>
												<th className="clearfix" style={{ width: '180px' }}>
													<Link
														to={setRouteParams(ROZPISY_ZALOH_HISTORIA, get(interakcia, 'data.opCislo'), get(rozpisZaloh, 'cislo'))}
														className="show-more-button pull-right">
														Zobraziť všetky
													</Link>
												</th>
											</tr>
										</thead>
										<tbody>
											{history}
										</tbody>
									</table> */}
								</div>
							</div>
						</div>
					</div>
					<div className='box'>
						<div className='box-content no-padding'>
							<table className='payment-table' cellSpacing='0'>
								<thead>
									<tr>
										<th>{t('containers:RozpisZalohDetailPage.Rozpis záloh')}</th>
										{/* <th data-paid>1. 2018</th>
										<th>4. 2018</th>
										<th>7. 2018</th>
										<th>10. 2018</th> */}
										{tHead}
										<th>Spolu</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{t('containers:RozpisZalohDetailPage.Aktuálne nastavenie')}</td>
										{tBodyAktualneNastavene}
										<td>
											<strong>
												<NumericFormat
													thousandSeparator={' '}
													decimalSeparator={','}
													decimalScale={2}
													displayType='text'
													suffix=' €'
													value={tBodyAktualneNastaveneTotalSuma}
													defaultValue='-'
												/>
											</strong>
										</td>
									</tr>
									{/* <tr>
										<td>{t('containers:RozpisZalohDetailPage.Predpokladané zúčtovanie')}</td>
										{tBodyPredpokladaneZuctovanie}
										<td><strong data-text-color="green">420 € TODO:</strong></td>
									</tr> */}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</>
		)
	}

	commonContentContainer = (content) => {
		return <>{content}</>
	}

	loadingContentElement = () => {
		return this.commonContentContainer(
			<div className='box' style={{ height: '100%' }}>
				<ElementLoading />
			</div>
		)
	}

	failureContentElement = () => {
		const { t } = this.props
		return this.commonContentContainer(<ElementFailure text={t('containers:RozpisZalohDetailPage.Nepodarilo sa načítať údaje rozpisu záloh')} />)
	}

	emptyContentElement = (text) => {
		const { t } = this.props
		const textElement = text || t('containers:RozpisZalohDetailPage.Pre obchodného partnera neevidujeme žiadne rozpisy záloh')
		return this.commonContentContainer(<ElementEmptyContent text={textElement} />)
	}
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail,
	rozpisZaloh: state.obchodnyPartner.rozpisZalohDetail,
	odberneMiesta: state.obchodnyPartner.odberneMiesta,
	procesnyKonfigurator: state.procesnyKonfigurator.procesnyKonfigurator
})

const mapDispatchToProps = (dispatch) => ({
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_PREDDAVKOVE_PLATBY])
)(RozpisZalohDetailPage)
