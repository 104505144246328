import React from 'react'
import { get, isEmpty, set, find, upperFirst } from 'lodash'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import cx from 'classnames'
import dayjs from 'dayjs'
import axios from 'axios'

// actions
import * as TrackingActions from '../../actions/TrackingActions'
import * as UkonyActions from '../../actions/UkonyActions'
import * as DataActions from '../../actions/DataActions'

// utils
import { formatFormValue } from '../../utils/form'
import { postReq } from '../../utils/request'
import { NOTIFICATION_TYPES, UKONY, BUSINESS_CHANNELS, SCENARE, UKON_STAV, UKONY_CISELNIK, FILTER_SELECTORS, DOKUMENT_TYP } from '../../utils/enums'
import { openDataUriWindow } from '../../utils/files'
import { getAccessToken } from '../../utils/auth'
import config from '../../utils/config'

// components
import DefaultModal from '../../components/Modals/DefaultModal'
import ModalSendItemDuplicate from '../../components/Modals/ModalSendItemDuplicate'
import PodpisovanieDokumentov from '../../components/PodpisovanieDokumentov'
import ImportPodpisanychDokumentovModal from '../../components/Modals/ImportPodpisanychDokumentovModal'
import CommonContainer from '../../components/PodpisovanieDokumentov/CommonContainer'

const scenarioOption = {
	kanal: BUSINESS_CHANNELS.ZSE_CENTRUM,
	scenarios: [{ typ: SCENARE.PODPISANIE_NA_MIESTE }, { typ: SCENARE.VYTLACIT_A_NAHRAT }, { typ: SCENARE.NAHRAT }],
	documentRequired: true
}

class UkonPodpisSEPAMandatu extends React.Component {
	static propTypes = {
		ukon: PropTypes.shape(),
		ukonData: PropTypes.shape(),
		ukonDataPred: PropTypes.shape(),
		dokumenty: PropTypes.array,
		t: PropTypes.func.isRequired,
		formatDocuments: PropTypes.func.isRequired,
		trackingActions: PropTypes.shape().isRequired,
		logRows: PropTypes.array,
		header: PropTypes.shape(),
		tracking: PropTypes.shape(),
		auth: PropTypes.shape(),
		signedPdf: PropTypes.shape(),
		ukonyActions: PropTypes.shape(),
		dataActions: PropTypes.shape()
	}

	state = {
		showModalUkonUC06b: false,
		showUploadSEPADokumentov: false,
		podpisSEPA: false,
		printDokument: {
			data: null,
			isLoading: false,
			isFailure: false
		},
		isLoadingPodpisSEPAMandatu: false,
		isLoadingSpustitSEPAMandat: false,
		nahratyDokumentSEPAMandat: null
	}

	_mounted = false

	async componentDidMount() {
		this._mounted = true
		const { ukon, trackingActions } = this.props

		trackingActions.clearTracking()

		try {
			if (this._mounted) {
				this.setState({
					printDokument: {
						data: null,
						isLoading: true,
						isFailure: false
					}
				})
			}

			const accessToken = getAccessToken()
			const url = `/api/v0/ukony/${get(ukon, 'id')}/generovat-formular`
			const res = await axios({
				baseURL: config.baseUrl,
				url,
				method: 'GET',
				headers: {
					'Cache-Control': 'no-cache, no-store',
					Pragma: 'no-cache',
					Authorization: `Bearer ${accessToken}`,
					'Content-Type': 'application/json',
					'Access-Control-Allow-Credentials': true
				}
			})
			if (this._mounted) {
				this.setState({
					printDokument: {
						data: {
							url: `data:${get(res, 'data.response.contentType')};base64,${get(res, 'data.response.data')}`,
							fileName: get(res, 'data.response.nazov', '') || ''
						},
						isLoading: false,
						isFailure: false
					}
				})
			}
		} catch (e) {
			if (this._mounted) {
				this.setState({
					printDokument: {
						data: null,
						isLoading: false,
						isFailure: true
					}
				})
			}
		}
	}

	componentWillUnmount() {
		this._mounted = false

		const { trackingActions, dataActions, ukonyActions } = this.props

		dataActions.unregisterLeavePageModal()
		trackingActions.clearTracking()
		// clear ukonId from storage
		ukonyActions.clearUkonId()
	}

	spustitSEPAMandat = async (dokument) => {
		const { trackingActions, tracking, auth, ukon, ukonyActions, loadUkon, dataActions, selectedFiltersUkony } = this.props

		await postReq(`/api/v0/ukony/${get(ukon, 'id')}/prilozit-dokument`, null, dokument)

		const spustitBody = {
			...ukon,
			trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
			riesitel: get(auth, 'user.id'),
			kanal: get(auth, 'businessChannel.actual')
		}
		const spustitUkon = await postReq(`/api/v0/ukony/${ukon.id}/spustit`, null, spustitBody)

		dataActions.unregisterLeavePageModal()
		// clear tracking timer
		trackingActions.clearTracking()
		ukonyActions.clearUkonId()
		ukonyActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)
		// refetch ukon data
		loadUkon()

		return spustitUkon
	}

	podpisSEPAMandatuStart = async () => {
		const { trackingActions, tracking, ukonData, ukon, ukonyActions, signedPdf, dataActions } = this.props
		if (this._mounted) {
			this.setState({ podpisSEPA: true })
		}

		dataActions.registerLeavePageModal()

		if (!tracking.startTime || (tracking.startTime && get(tracking, 'data.ukonData.sepaMandatCislo') !== get(ukonData, 'sepaMandatCislo'))) {
			const data = {
				ukonData
			}
			trackingActions.tryToStartTracking(UKONY.PODPIS_SEPA_MANDATU, data)
			ukonyActions.setUkonId(get(ukon, 'id'))
		} else {
			const dokument = {
				contentType: get(signedPdf, 'data.type'),
				typ: {
					id: DOKUMENT_TYP.VYSTUPNY
				},
				nazov: get(signedPdf, 'data.name'),
				data: get(signedPdf, 'data.dataAsBase64')
			}
			try {
				if (this._mounted) {
					this.setState({ isLoadingPodpisSEPAMandatu: true })
				}
				await this.spustitSEPAMandat(dokument)
				if (this._mounted) {
					this.setState({ isLoadingPodpisSEPAMandatu: false })
				}
			} catch (e) {
				if (this._mounted) {
					this.setState({ isLoadingPodpisSEPAMandatu: false })
				}
				// eslint-disable-next-line no-console
				console.log(e)
			}
		}
	}

	onSubmitUkonUC06b = async (data) => {
		const { interakcia, t, auth, tracking, trackingActions, ukonyActions, ukonData, ciselniky, selectedFiltersUkony } = this.props

		try {
			let notifikacie = {}
			if (get(data, 'address')) {
				set(notifikacie, 'adresyPosta', [get(data, 'address')])
			}

			if (get(data, 'email')) {
				set(notifikacie, 'adresyUri', [
					{
						typ: NOTIFICATION_TYPES.EMAIL,
						hodnota: get(data, 'email')
					}
				])
			}

			if (get(data, 'typ') === NOTIFICATION_TYPES.PRINTER) {
				set(notifikacie, 'adresyUri', [
					{
						typ: NOTIFICATION_TYPES.PRINTER,
						poradie: 0
					}
				])
			}
			notifikacie = isEmpty(notifikacie) ? undefined : notifikacie

			const ukonTyp = find(get(ciselniky, 'data.ukonTyp', []), (ukonTyp) => get(ukonTyp, 'id') == UKONY_CISELNIK.ZASLANIE_DUPLIKATU_SEPA_MANDATU)

			const body = {
				typ: ukonTyp,
				interakciaId: interakcia.data.id,
				riesitel: auth.user.id,
				kanal: auth.businessChannel.actual,
				trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
				poznamka: get(data, 'poznamka.value'),
				zacatyOd: dayjs(tracking.startTime).toISOString(),
				vstup: {
					id: get(data, 'ukonVstup.value.value'),
					nazov: get(data, 'ukonVstup.value.label')
				},
				notifikacie,
				opCislo: get(interakcia, 'data.opCislo'),
				dokumenty: [],
				data: {
					sepaMandatCislo: get(ukonData, 'sepaMandatCislo'),
					zuCislo: get(ukonData, 'zuCislo')
				}
			}
			await postReq(`/api/v2/ukony/vytvorit-a-spustit`, null, body)

			// clear tracking timer
			trackingActions.clearTracking()

			// refetch new data for historia ukonov
			ukonyActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)

			this.setState({
				result: t('translation:ZmluvneUcty.Úkon bol úspešne odoslaný', { ukonName: upperFirst(ukonTyp.nazov) }),
				success: true,
				showModalUkonUC06b: false
			})
		} catch (e) {
			this.setState({
				result: t('translation:Common.Počas odosielania úkonu nastala chyba'),
				success: false
			})
		}
	}

	handleImportSignedDocuments = (file) => {
		const nahratyDokumentSEPAMandat = {
			contentType: get(file, 'type'),
			typ: {
				id: DOKUMENT_TYP.VYSTUPNY
			},
			nazov: get(file, 'name'),
			data: get(file, 'uploadedDocumentAsBase64')
		}
		if (this._mounted) {
			this.setState({
				showUploadSEPADokumentov: false,
				nahratyDokumentSEPAMandat
			})
		}
	}

	nahratSEPAMandatStart = () => {
		const { tracking, ukonData, ukon, trackingActions, ukonyActions, dataActions } = this.props

		dataActions.registerLeavePageModal()

		if (!tracking.startTime || (get(tracking, 'startTime') && get(tracking, 'data.ukonData.sepaMandatCislo') !== get(ukonData, 'sepaMandatCislo'))) {
			const data = {
				ukonData
			}
			trackingActions.tryToStartTracking(UKONY.PODPIS_SEPA_MANDATU, data)
			ukonyActions.setUkonId(get(ukon, 'id'))
		}
		if (this._mounted) {
			this.setState({ showUploadSEPADokumentov: true })
		}
	}

	nahratSEPAMandatEnd = () => {
		const { trackingActions, ukonyActions, dataActions } = this.props
		if (this._mounted) {
			this.setState({ showUploadSEPADokumentov: false })
		}
		trackingActions.clearTracking()
		ukonyActions.clearUkonId()
		dataActions.unregisterLeavePageModal()
	}

	onDokoncitSEPAMandat = async () => {
		try {
			const { nahratyDokumentSEPAMandat } = this.state

			if (this._mounted) {
				this.setState({ isLoadingSpustitSEPAMandat: true })
			}

			await this.spustitSEPAMandat(nahratyDokumentSEPAMandat)
			if (this._mounted) {
				this.setState({ isLoadingSpustitSEPAMandat: false })
			}
		} catch (e) {
			if (this._mounted) {
				this.setState({ isLoadingSpustitSEPAMandat: false })
			}
			// eslint-disable-next-line no-console
			console.log(e)
		}
	}

	render() {
		const { auth, ukonDataPred, ukonData, formatDocuments, dokumenty, logRows, ukon, header, tracking, signedPdf, t } = this.props
		const {
			showModalUkonUC06b,
			showUploadSEPADokumentov,
			podpisSEPA,
			printDokument,
			nahratyDokumentSEPAMandat,
			isLoadingPodpisSEPAMandatu,
			isLoadingSpustitSEPAMandat,
			result,
			success
		} = this.state

		const ukonStav = get(ukon, 'ukonStav.id', -1)

		let controlPanel = null
		let showLogRows = true
		let notification = null
		let podpisDokumentov = null

		// NOTE: mat moznost podpisat SEPA mandat alebo poslat notifikaciu je mozne iba ak je ukon v stave "odlozený" = 10
		if (ukonStav === UKON_STAV.ODLOZENY) {
			let ukonTextBtn = t('containers:UkonPodpisSEPAMandatu.Podpísať SEPA mandát')
			let ukonDisabledBtn = false
			if (tracking.startTime && get(tracking, 'data.ukonData.sepaMandatCislo') === get(ukonData, 'sepaMandatCislo') && !showModalUkonUC06b) {
				ukonTextBtn = t('containers:UkonPodpisSEPAMandatu.Dokončiť podpis SEPA mandátu')
				ukonDisabledBtn = !get(signedPdf, 'data.dataAsBase64')
				podpisDokumentov = (
					<tr>
						<td colSpan={4}>
							<PodpisovanieDokumentov title={t('containers:UkonPodpisSEPAMandatu.Podpis SEPA mandátu')} scenarioOption={scenarioOption} />
						</td>
					</tr>
				)
			} else {
				notification = (
					<button
						type='button'
						onClick={() => this.setState({ showModalUkonUC06b: true })}
						className={cx('button', 'pull-right')}
						data-type='outline'
						data-color='blue'
						style={{ marginLeft: '20px' }}
					>
						{t('containers:UkonPodpisSEPAMandatu.Poslať SEPA mandát')}
					</button>
				)
			}

			showLogRows = false
			controlPanel = (
				<div className='content-header clearfix'>
					{notification}
					{!podpisSEPA && !nahratyDokumentSEPAMandat && (
						<button
							onClick={this.nahratSEPAMandatStart}
							className={cx('button', 'pull-right')}
							type='button'
							data-color='blue'
							style={{ marginLeft: '20px' }}
						>
							{t('containers:UkonPodpisSEPAMandatu.Nahrať SEPA mandát')}
						</button>
					)}
					{nahratyDokumentSEPAMandat && (
						<button
							onClick={this.onDokoncitSEPAMandat}
							className={cx('button', 'pull-right', { disabled: isLoadingSpustitSEPAMandat })}
							type='button'
							data-color='blue'
							disabled={isLoadingSpustitSEPAMandat}
							style={{ marginLeft: '20px' }}
						>
							{t('containers:UkonPodpisSEPAMandatu.Dokončiť SEPA mandát')}
						</button>
					)}
					{!nahratyDokumentSEPAMandat && !showUploadSEPADokumentov && get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZSE_CENTRUM && (
						<button
							onClick={this.podpisSEPAMandatuStart}
							className={cx('button', 'pull-right', { disabled: isLoadingPodpisSEPAMandatu || ukonDisabledBtn })}
							disabled={isLoadingPodpisSEPAMandatu || ukonDisabledBtn}
							type='button'
							data-color='blue'
							style={{ marginLeft: '20px' }}
						>
							{ukonTextBtn}
						</button>
					)}
					<div className='header-title pull-left'>{t('containers:UkonPodpisSEPAMandatu.Podpis SEPA mandátu')}</div>
				</div>
			)
		}

		let dokumentSection
		if (nahratyDokumentSEPAMandat) {
			const documentUrl = `data:${nahratyDokumentSEPAMandat.contentType};base64,${nahratyDokumentSEPAMandat.data}`
			const documentHtml = (
				<tr>
					<td>
						{get(scenarioOption, 'documentRequired', true) && (
							<a
								className='file'
								onClick={(e) => {
									e.preventDefault()
									openDataUriWindow(documentUrl, nahratyDokumentSEPAMandat.nazov)
								}}
								data-type='general'
								style={{ cursor: 'pointer' }}
							>
								{nahratyDokumentSEPAMandat.nazov}
							</a>
						)}
					</td>
					<td style={{ textAlign: 'right' }}>
						<button
							type='button'
							className='button small'
							data-type='outline'
							data-color='blue'
							style={{ marginLeft: '10px', height: '32px' }}
							onClick={() => this.setState({ showUploadSEPADokumentov: true })}
						>
							{t('components:PodpisovanieDokumentov.Zmeniť dokument')}
						</button>
					</td>
				</tr>
			)
			dokumentSection = (
				<div className='box'>
					<div className='box-content'>
						<CommonContainer title={t('containers:UkonPodpisSEPAMandatu.SEPA mandát')} content={documentHtml} />
					</div>
				</div>
			)
		}

		let modal = null
		if (showModalUkonUC06b) {
			modal = (
				<ModalSendItemDuplicate
					notificationTypes={[NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.PRINTER]}
					ukonTyp={UKONY.DUPLIKAT_SEPA_MANDATU}
					modalTitle={t('translation:UkonPodpisSEPAMandatu.Zaslanie kópie SEPA mandátu')}
					onCloseButton={() =>
						this.setState({
							showModalUkonUC06b: false
						})
					}
					zuCislo={get(ukonData, 'zuCislo')}
					onSubmit={this.onSubmitUkonUC06b}
					documentUrl={get(printDokument, 'data.url')}
					dokumentName={get(printDokument, 'data.fileName')}
				/>
			)
		}

		if (result) {
			modal = (
				<DefaultModal
					modalTitle={success ? t('containers:Odoslané') : t('containers:Chyba')}
					modalContent={result}
					leftButton={{
						onClick: () => this.setState({ result: null, success: null }),
						text: t('containers:Zavrieť'),
						color: success ? 'green' : 'red'
					}}
					visible
				/>
			)
		}

		return (
			<>
				{controlPanel}
				{modal}
				{showUploadSEPADokumentov && (
					<ImportPodpisanychDokumentovModal
						modalTitle={t('components:PodpisovanieDokumentov.Vloženie podpísaného dokumentu')}
						onCloseButton={this.nahratSEPAMandatEnd}
						onSubmit={this.handleImportSignedDocuments}
					/>
				)}
				<div className='content-wrapper'>
					{header}
					{nahratyDokumentSEPAMandat && dokumentSection}
					<div className='box'>
						<div className='box-header'>{t('containers:UkonPodpisSEPAMandatu.Detaily úkonu')}</div>
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									<tr>
										<td colSpan='2'>
											<strong>{t('containers:UkonPodpisSEPAMandatu.Číslo zmluvného účtu')}</strong>
										</td>
										<td colSpan='2'>{formatFormValue(get(ukonData, 'zuCislo'), get(ukonDataPred, 'zuCislo'))}</td>
									</tr>
									<tr>
										<td colSpan='2'>
											<strong>{t('containers:UkonPodpisSEPAMandatu.Číslo SEPA mandátu')}</strong>
										</td>
										<td colSpan='2'>{formatFormValue(get(ukonData, 'sepaMandatCislo'), get(ukonDataPred, 'sepaMandatCislo'))}</td>
									</tr>
									{get(ukonData, 'datumPodpisu') && (
										<tr>
											<td colSpan='2'>
												<strong>{t('containers:UkonPodpisSEPAMandatu.Dátum podpisu')}</strong>
											</td>
											<td colSpan='2'>{formatFormValue(get(ukonData, 'datumPodpisu'), get(ukonDataPred, 'datumPodpisu'))}</td>
										</tr>
									)}
									{podpisSEPA && podpisDokumentov}
									{!tracking.startTime && (
										<tr>
											<td colSpan={4}>{formatDocuments(dokumenty, true)}</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
					{showLogRows && !isEmpty(logRows) && (
						<div className='box'>
							<div className='box-header'>{t('containers:UkonPodpisSEPAMandatu.Logy')}</div>
							<div className='box-content' style={{ minHeight: '200px' }}>
								<table className='content-table padded bordered' cellSpacing='0'>
									<thead>
										<tr>
											<th style={{ width: '180px' }}>{t('containers:UkonPodpisSEPAMandatu.Dátum')}</th>
											<th style={{ width: '220px' }}>{t('containers:UkonPodpisSEPAMandatu.Stav')}</th>
											<th>{t('containers:UkonPodpisSEPAMandatu.Komentár')}</th>
										</tr>
									</thead>
									<tbody>{logRows}</tbody>
								</table>
							</div>
						</div>
					)}
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	ciselniky: get(state, 'ciselniky'),
	interakcia: state.interakcie.detail,
	tracking: state.tracking,
	auth: state.auth,
	signedPdf: state.podpisovanieDokumentov.signedPdf,
	selectedFiltersUkony: get(state, `selectedFilters.${FILTER_SELECTORS.SIDEBAR_HISTORY}`, {})
})

const mapDispatchToProps = (dispatch) => ({
	trackingActions: bindActionCreators(TrackingActions, dispatch),
	ukonyActions: bindActionCreators(UkonyActions, dispatch),
	dataActions: bindActionCreators(DataActions, dispatch)
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(UkonPodpisSEPAMandatu)
