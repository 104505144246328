import React from 'react'
import { get, map } from 'lodash'
import PropTypes from 'prop-types'

// utils
import { formatFormValue } from '../../utils/form'
import { formatAddress } from '../../utils/address'
import { formatDate } from '../../utils/date'
import { formatDegrees } from '../../utils/degrees'

// generic
import ContactPhoneField from '../../components/GenericUkon/fields/ContactPhoneField'
import ContactEmailField from '../../components/GenericUkon/fields/ContactEmailField'
import { EDIT_MODE, COLUMNS_COUNT } from '../GenericUkon/attributesConfig'

function UkonZmenaUdajovOP(props) {
	const { ukonData, ukonDataPred, dokumenty, t, formatDocuments } = props

	const datumNarodenia = formatDate(get(ukonData, 'narodenieDatum', null, 'DD.MM.YYYY'))
	const datumNarodeniaPred = formatDate(get(ukonDataPred, 'narodenieDatum', null, 'DD.MM.YYYY'))

	const titulyPredMenom = formatDegrees(get(ukonData, 'tituly', []), true)
	const titulyPredMenomPred = formatDegrees(get(ukonDataPred, 'tituly', []), true)

	const titulyZaMenom = formatDegrees(get(ukonData, 'tituly', []))
	const titulyZaMenomPred = formatDegrees(get(ukonDataPred, 'tituly', []))

	const adresaZakaznika = formatAddress(get(ukonData, 'adresaZakaznika', null))
	const adresaZakaznikaPred = formatAddress(get(ukonDataPred, 'adresaZakaznika', null))

	const adresaKorespondencna = formatAddress(get(ukonData, 'adresaKorespondencna', null))
	const adresaKorespondencnaPred = formatAddress(get(ukonDataPred, 'adresaKorespondencna', null))

	const eFakturyMerged = [...get(ukonData, 'efakturaDodatocneEmaily', [])]
	if (get(ukonData, 'kontaktnyEmailEFaktura') && get(ukonData, 'kontaktnyEmail')) {
		eFakturyMerged.push(get(ukonData, 'kontaktnyEmail'))
	}

	const efakturaDodatocneEmaily = map(eFakturyMerged, (email, index) => {
		return <p key={index}>{email}</p>
	})

	const globalnaZmenaEfaktury = get(ukonData, 'kontaktnyEmailEFaktura') ? t('translation:Common.Áno') : t('translation:Common.Nie')

	return (
		<tbody>
			<tr>
				<td>
					<strong>{t('translation:Common.Meno')}</strong>
				</td>
				<td>{get(ukonDataPred, 'meno')}</td>
				<td>{formatFormValue(get(ukonData, 'meno'), get(ukonDataPred, 'meno'))}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('translation:Common.Priezvisko')}</strong>
				</td>
				<td>{get(ukonDataPred, 'priezvisko')}</td>
				<td>{formatFormValue(get(ukonData, 'priezvisko'), get(ukonDataPred, 'priezvisko'))}</td>
			</tr>

			<tr>
				<td>
					<strong>{t('translation:Common.Titul pred menom')}</strong>
				</td>
				<td>{titulyPredMenomPred}</td>
				<td>{formatFormValue(titulyPredMenom, titulyPredMenomPred)}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('translation:Common.Titul za menom')}</strong>
				</td>
				<td>{titulyZaMenomPred}</td>
				<td>{formatFormValue(titulyZaMenom, titulyZaMenomPred)}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('translation:Common.Dátum narodenia')}</strong>
				</td>
				<td>{datumNarodeniaPred}</td>
				<td>{formatFormValue(datumNarodenia, datumNarodeniaPred)}</td>
			</tr>
			<ContactPhoneField
				editMode={EDIT_MODE.DETAIL}
				columnsCount={COLUMNS_COUNT.THREE}
				originalValue={get(ukonDataPred, 'kontaktnyTelefon')}
				value={get(ukonData, 'kontaktnyTelefon')}
				originalSuhlas={get(ukonDataPred, 'suhlasKontaktnyTelefon')}
				aktualnostTelefon={get(ukonData, 'aktualnostTelefon')}
				newSuhlas={get(ukonData, 'suhlasKontaktnyTelefon')}
			/>
			<ContactEmailField
				editMode={EDIT_MODE.DETAIL}
				columnsCount={COLUMNS_COUNT.THREE}
				originalValue={get(ukonDataPred, 'kontaktnyEmail')}
				value={get(ukonData, 'kontaktnyEmail')}
				originalSuhlas={get(ukonDataPred, 'suhlasKontaktnyEmail')}
				aktualnostEmail={get(ukonData, 'aktualnostEmail')}
				newSuhlas={get(ukonData, 'suhlasKontaktnyEmail')}
			/>
			<tr>
				<td>
					<strong>{t('translation:Common.Uplatniť e-faktúru na všetkých ZÚ')}</strong>
				</td>
				<td>{t('translation:Common.Nie')}</td>
				<td>{formatFormValue(globalnaZmenaEfaktury, t('translation:Common.Nie'))}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('translation:Common.E-mailové adresy pre e-faktúry')}</strong>
				</td>
				<td />
				<td>
					<strong>{efakturaDodatocneEmaily}</strong>
				</td>
			</tr>
			<tr>
				<td>
					<strong>{t('translation:Common.Adresa trvalého pobytu')}</strong>
					<br />
					<strong>{t('translation:Common.Do rúk')}</strong>
					<br />
					<strong>{t('translation:Common.Globálne použitie adresy')}</strong>
					<br />
					&nbsp;
				</td>
				<td>
					{adresaZakaznikaPred}
					<br />
					{get(ukonDataPred, 'adresaZakaznika.doRukMeno')}&nbsp;
					<br />
					&nbsp;
					<br />
					&nbsp;
				</td>
				<td>
					{formatFormValue(adresaZakaznika, adresaZakaznikaPred)}
					<br />
					{formatFormValue(get(ukonData, 'adresaZakaznika.doRukMeno'), get(ukonDataPred, 'adresaZakaznika.doRukMeno'))}&nbsp;
					<br />
					{get(ukonData, 'nastavAdresyZU') && get(ukonData, 'adresaZakaznika.id') == get(ukonData, 'nastavAdresyZU') ? <strong>Áno</strong> : 'Nie'}
					<br />
					{get(ukonDataPred, 'aktualnostAdresaZakaznika', null) !== null && get(ukonData, 'aktualnostAdresaZakaznika', null) === null && (
						<strong>{t('translation:Common.Zrušené overenie')}</strong>
					)}
					{get(ukonData, 'aktualnostAdresaZakaznika', null) &&
					get(ukonData, 'aktualnostAdresaZakaznika', null) !== get(ukonDataPred, 'aktualnostAdresaZakaznika', null) ? (
						<strong>{t('translation:Common.Overené')}</strong>
					) : (
						<span>&nbsp;</span>
					)}
				</td>
			</tr>
			<tr>
				<td>
					<strong>{t('translation:Common.Korešpondenčná adresa')}</strong>
					<br />
					<strong>{t('translation:Common.Do rúk')}</strong>
					<br />
					<strong>{t('translation:Common.Globálne použitie adresy')}</strong>
					<br />
					&nbsp;
				</td>
				<td>
					{adresaKorespondencnaPred}
					<br />
					{get(ukonDataPred, 'adresaKorespondencna.doRukMeno')}&nbsp;
					<br />
					&nbsp;
					<br />
					&nbsp;
				</td>
				<td>
					{formatFormValue(adresaKorespondencna, adresaKorespondencnaPred)}
					<br />
					{formatFormValue(get(ukonData, 'adresaKorespondencna.doRukMeno'), get(ukonDataPred, 'adresaKorespondencna.doRukMeno'))}&nbsp;
					<br />
					{get(ukonData, 'nastavAdresyZU') &&
					get(ukonData, 'adresaKorespondencna.id') == get(ukonData, 'nastavAdresyZU') &&
					get(ukonData, 'adresaKorespondencna.id') != get(ukonData, 'adresaZakaznika.id') ? (
						<strong>Áno</strong>
					) : (
						'Nie'
					)}
					<br />
					{get(ukonDataPred, 'aktualnostAdresa', null) !== null && get(ukonData, 'aktualnostAdresa', null) === null && (
						<strong>{t('translation:Common.Zrušené overenie')}</strong>
					)}
					{get(ukonData, 'aktualnostAdresa', null) && get(ukonData, 'aktualnostAdresa', null) !== get(ukonDataPred, 'aktualnostAdresa', null) ? (
						<strong>{t('translation:Common.Overené')}</strong>
					) : (
						<span>&nbsp;</span>
					)}
				</td>
			</tr>
			<tr>
				<td>
					<strong>{t('translation:Common.Deň splatnosti preferovaný')}</strong>
				</td>
				<td>{get(ukonDataPred, 'denSplatnostiPreferovany')}</td>
				<td>{formatFormValue(get(ukonData, 'denSplatnostiPreferovany'), get(ukonDataPred, 'denSplatnostiPreferovany'))}</td>
			</tr>
			<tr>
				<td colSpan={3}>{formatDocuments(dokumenty)}</td>
			</tr>
		</tbody>
	)
}

UkonZmenaUdajovOP.propTypes = {
	ukonData: PropTypes.shape(),
	ukonDataPred: PropTypes.shape(),
	dokumenty: PropTypes.array,
	t: PropTypes.func.isRequired,
	formatDocuments: PropTypes.func.isRequired
}

export default UkonZmenaUdajovOP
