import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import { compose, bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'
import dayjs from 'dayjs'

// utils
import Permissions, { PERMISSIONS } from '../../utils/permissionsHoc'
import { getLogo } from '../../utils/environment'

// components
import UserDropdownMenu from '../../components/UserDropdownMenu'
import BusinessChannelDropdownMenu from '../../components/BusinessChannelDropdownMenu'
import { getAccessToken } from '../../utils/auth'
import { setRouteParams, INDEX, SYSTEMOVE_LISTY_UKON } from '../../utils/routes'
import { PAGES } from '../../utils/enums'
import DefaultModal from '../../components/Modals/DefaultModal'

// actions
import * as ExternalIntegrationsActions from '../../actions/ExternalIntegrationsActions'

class Header extends React.Component {
	static propTypes = {
		interakcia: PropTypes.shape({
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		auth: PropTypes.shape({
			user: PropTypes.shape({
				firstName: PropTypes.string,
				lastName: PropTypes.string,
				displayName: PropTypes.string,
				email: PropTypes.string.isRequired
			})
		}).isRequired,
		obchodnyPartner: PropTypes.shape({
			meno: PropTypes.string,
			priezvisko: PropTypes.string,
			cislo: PropTypes.string
		}).isRequired,
		settings: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired,
		page: PropTypes.string,
		finesseSession: PropTypes.string,
		liveAgentSession: PropTypes.string,
		call250Session: PropTypes.string
	}

	constructor(props) {
		super(props)

		this.state = {
			ponuka: [],
			showLiveAgentModal: false,
			showFinesseModal: false,
			showCall250Modal: false
		}
	}

	render() {
		const { t, auth, obchodnyPartner, settings, interakcia, page, liveAgentSession, finesseSession, call250Session, externalIntegrationsActions } =
			this.props
		const { firstName, lastName, displayName, email } = auth.user

		const kid = get(interakcia, 'riesitelIdentita.kid')
		const ts = dayjs().format('YYYYMMDDHHmmss')

		const meno = [
			get(obchodnyPartner, 'titulyPredMenom', ''),
			get(obchodnyPartner, 'meno', ''),
			get(obchodnyPartner, 'priezvisko', ''),
			get(obchodnyPartner, 'titulyZaMenom', '')
		]
			.join(' ')
			.trim()
		return (
			<header className='top-header'>
				<a className='logo' href='/' target='_blank'>
					<img src={getLogo(get(settings, 'env'))} alt={t('containers:Header.ZSE, Súčasť vášho domova')} />
				</a>

				<div className='header-search'>
					<a href={`${INDEX}?clear=true`} target='_blank' rel='noopener noreferrer'>
						{t('containers:Header.Hľadať OP')}
					</a>
				</div>

				<ul className='ext-integrations'>
					{liveAgentSession && (
						<li className='ext-integrations-badge liveagent-badge'>
							<div className='ext-integrations-icon' />
							<div>
								<div className='ext-integrations-title'>{t('containers:Header.LiveAgent Ticket')}</div>
								<div className='ext-integrations-id'>{liveAgentSession}</div>
							</div>
							<button className='ext-integrations-close' onClick={() => this.setState({ showLiveAgentModal: true })} />
						</li>
					)}
					{finesseSession && (
						<li className='ext-integrations-badge finesse-badge'>
							<div className='ext-integrations-icon' />
							<div>
								<div className='ext-integrations-title'>{t('containers:Header.ID hovoru')}</div>
								<div className='ext-integrations-id'>{finesseSession}</div>
							</div>
							<button className='ext-integrations-close' onClick={() => this.setState({ showFinesseModal: true })} />
						</li>
					)}
					{call250Session && (
						<li className='ext-integrations-badge call250-badge'>
							<div className='ext-integrations-icon' />
							<div>
								<div className='ext-integrations-title'>{t('containers:Header.ID vyvolania')}</div>
								<div className='ext-integrations-id'>{call250Session}</div>
							</div>
							<button className='ext-integrations-close' onClick={() => this.setState({ showCall250Modal: true })} />
						</li>
					)}
				</ul>

				<div className='top-header-divider' />

				{!isEmpty(obchodnyPartner) && (
					<a
						className='button'
						data-color='black'
						data-type='outline'
						href={`/api/v2/op/${obchodnyPartner.cislo}/sprava-uctov?kid=${kid}&ts=${ts}&accessToken=${getAccessToken()}`}
						target='_blank'
						rel='noopener noreferrer'
						style={{ marginRight: '10px' }}
					>
						{t('containers:Header.Správa Online účtu')}
					</a>
				)}

				{!isEmpty(obchodnyPartner) && (
					<a
						className='button'
						data-color='black'
						data-type='outline'
						href={`/api/v0/esign/zakaznici/${obchodnyPartner.cislo}?accessToken=${getAccessToken()}`}
						target='_blank'
						rel='noopener noreferrer'
					>
						{t('containers:Header.Otvoriť OP v eSign')}
					</a>
				)}

				{page != PAGES.SYSTEMOVE_LISTY_UKON && !isEmpty(obchodnyPartner) && (
					<Permissions
						allowed={[PERMISSIONS.UKON_ODPOVED_ZAKAZNIKOVI]}
						render={(hasPerm, actions) => {
							if (hasPerm) {
								return (
									<div>
										<Tooltip
											html={<span>{t('translation:SystemoveListyUkon.Odpoveď zákazníkovi')}</span>}
											position='bottom'
											trigger='mouseenter'
											theme='light'
										>
											<Link
												to={setRouteParams(SYSTEMOVE_LISTY_UKON, get(interakcia, 'opCislo'))}
												className='button'
												data-type='icon-button'
												data-icon='post'
												data-color='black'
												type='button'
												style={{
													width: '40px',
													height: '40px',
													marginLeft: '10px',
													borderColor: '#9B9B9B',
													borderRadius: '7px'
												}}
											/>
										</Tooltip>
									</div>
								)
							}

							return (
								<div>
									<Tooltip
										html={
											<span>
												{t('translation:SystemoveListyUkon.Odpoveď zákazníkovi - Na vykonanie akcie nemáte potrebné oprávnenia')}
											</span>
										}
										position='bottom'
										trigger='mouseenter'
										theme='light'
									>
										<Link
											to={setRouteParams(SYSTEMOVE_LISTY_UKON, get(interakcia, 'opCislo'))}
											className='button disabled'
											data-type='icon-button'
											data-icon='post'
											data-color='black'
											type='button'
											onClick={(e) => {
												e.preventDefault()
												actions.openForbiddenModal()
											}}
											style={{ width: '40px', height: '40px', marginLeft: '10px', borderColor: '#9B9B9B', borderRadius: '7px' }}
										/>
									</Tooltip>
								</div>
							)
						}}
					/>
				)}
				{page == PAGES.SYSTEMOVE_LISTY_UKON && !isEmpty(obchodnyPartner) && (
					<div>
						<Link
							to={setRouteParams(SYSTEMOVE_LISTY_UKON, get(interakcia, 'opCislo'))}
							className='button disabled'
							data-type='icon-button'
							data-icon='post'
							data-color='black'
							type='button'
							onClick={(e) => {
								e.preventDefault()
							}}
							style={{ width: '40px', height: '40px', marginLeft: '10px', borderColor: '#9B9B9B', borderRadius: '7px' }}
						/>
					</div>
				)}

				<div className='header-profile'>
					<div className='profile-content'>
						<strong>{firstName && lastName ? `${firstName} ${lastName}` : displayName || email}</strong>
						<BusinessChannelDropdownMenu />
					</div>
				</div>

				<UserDropdownMenu />

				<DefaultModal
					modalTitle={t('containers:Header.Prepojenie interakcie na LiveAgent ticket')}
					modalContent={t('containers:Header.Chcete ukončiť prácu s aktuálnym LiveAgent ticketom?', {
						liveAgentSession
					})}
					leftButton={{
						onClick: () => {
							this.setState({ showLiveAgentModal: false })
						},
						text: t('containers:Header.Nie'),
						color: 'blue'
					}}
					rightButton={{
						onClick: () => {
							externalIntegrationsActions.clearLiveAgentSession()
							this.setState({ showLiveAgentModal: false })
						},
						text: t('containers:Header.Áno'),
						color: 'blue',
						outline: true
					}}
					visible={this.state.showLiveAgentModal}
				/>
				<DefaultModal
					modalTitle={t('containers:Header.Prepojenie interakcie s Finesse')}
					modalContent={t('containers:Header.Chcete ukončiť prácu s aktuálnym Finesse hovorom?', {
						finesseSession
					})}
					leftButton={{
						onClick: () => {
							this.setState({ showFinesseModal: false })
						},
						text: t('containers:Header.Nie'),
						color: 'blue'
					}}
					rightButton={{
						onClick: () => {
							externalIntegrationsActions.clearFinesseSession()
							this.setState({ showFinesseModal: false })
						},
						text: t('containers:Header.Áno'),
						color: 'blue',
						outline: true
					}}
					visible={this.state.showFinesseModal}
				/>
				<DefaultModal
					modalTitle={t('containers:Header.Prepojenie interakcie s Call 250')}
					modalContent={t('containers:Header.Chcete ukončiť prácu s aktuálnym ID vyvolania?', {
						call250Session
					})}
					leftButton={{
						onClick: () => {
							this.setState({ showCall250Modal: false })
						},
						text: t('containers:Header.Nie'),
						color: 'blue'
					}}
					rightButton={{
						onClick: () => {
							externalIntegrationsActions.clearCall250Session()
							this.setState({ showCall250Modal: false })
						},
						text: t('containers:Header.Áno'),
						color: 'blue',
						outline: true
					}}
					visible={this.state.showCall250Modal}
				/>
			</header>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	auth: state.auth,
	obchodnyPartner: state.obchodnyPartner.detail.data,
	settings: get(state, 'settings.settings'),
	liveAgentSession: get(state, 'externalIntegrations.liveAgentId'),
	finesseSession: get(state, 'externalIntegrations.finesseId'),
	call250Session: get(state, 'externalIntegrations.call250Id')
})

const mapDispatchToProps = (dispatch) => ({
	externalIntegrationsActions: bindActionCreators(ExternalIntegrationsActions, dispatch)
})

export default compose(withTranslation('containers'), connect(mapStateToProps, mapDispatchToProps))(Header)
