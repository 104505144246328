import { map, get, find, set, parseInt, isFinite, forEach, isEmpty, orderBy, uniqBy, trim, size } from 'lodash'

// utils
import postalCodes from 'postal-codes-js'
import { getReq } from '../../utils/request'

export async function searchOptionsStat(searchValue) {
	try {
		const statNazov = trim(searchValue) || null

		const query = {
			statNazov,
			velkostStranky: 500
		}

		const res = await getReq('/api/v0/ciselniky/adresa/stat', query)

		const statyOptions = reorderCountries(get(res, 'response.obsah', []))

		this.setState({ statyOptions })

		return statyOptions
	} catch (err) {
		return this.state.statyOptions
	}
}

export async function searchOptionsObec(searchValue) {
	try {
		const { editAddress } = this.state

		const mestoNazov = trim(searchValue) || null

		// call autocomplete only if country is SK
		if (get(editAddress, 'stat') != 'SK') {
			return
		}

		const res = await getReq('/api/v0/ciselniky/adresa/mesto', { mestoNazov })

		const mestaOptions = map(get(res, 'response.obsah', []), (mesto) => ({
			...(mesto || {}),
			value: get(mesto, 'mestoNazov'),
			label: get(mesto, 'mestoNazov')
		}))

		this.setState({ mestaOptions })

		return mestaOptions
	} catch (err) {
		return this.state.mestaOptions
	}
}

export async function searchOptionsUlica(searchValue) {
	try {
		const { editAddress, mestaOptions } = this.state

		const ulicaNazov = trim(searchValue) || null

		if (get(editAddress, 'stat') != 'SK') {
			return
		}
		const selectedMesto = find(mestaOptions, {
			value: get(editAddress, 'obec')
		})
		const mestoKod = get(selectedMesto, 'mestoKod')

		const res = await getReq('/api/v0/ciselniky/adresa/ulica', {
			mestoKod,
			ulicaNazov
		})

		const uliceOptions = map(get(res, 'response.obsah', []), (ulica) => ({
			...(ulica || {}),
			value: get(ulica, 'ulicaNazov'),
			label: get(ulica, 'ulicaNazov')
		}))

		this.setState({ uliceOptions })

		return uliceOptions
	} catch (err) {
		return this.state.uliceOptions
	}
}

export async function searchOptionsPsc(searchValue) {
	try {
		const { editAddress, uliceOptions, mestaOptions } = this.state

		let pscNazov = trim(searchValue) || null

		const selectedMesto = find(mestaOptions, {
			value: get(editAddress, 'obec')
		})
		const mestoKod = get(selectedMesto, 'mestoKod')

		const selectedUlica = find(uliceOptions, { mestoKod })
		const ulicaKod = get(selectedUlica, 'ulicaKod')

		// normalize SK PSC format from XXXXX to XXX XX
		if (get(editAddress, 'stat') == 'SK' && size(pscNazov) == 5) {
			pscNazov = `${pscNazov.substr(0, 3)} ${pscNazov.substr(3)}`
		}

		if (get(editAddress, 'stat') == 'CZ' && size(pscNazov) == 5) {
			pscNazov = `${pscNazov.substr(0, 3)} ${pscNazov.substr(3)}`
		}

		const res = await getReq('/api/v0/ciselniky/adresa/psc', {
			mestoKod,
			ulicaKod,
			pscNazov
		})

		const pscOptions = map(get(res, 'response.obsah', []), (psc) => ({
			...(psc || {}),
			value: get(psc, 'psc'),
			label: get(psc, 'psc')
		}))

		this.setState({ pscOptions })

		return pscOptions
	} catch (err) {
		return this.state.pscOptions
	}
}

export async function searchOptionsOrientacneCislo() {
	try {
		const { editAddress, mestaOptions, uliceOptions, pscOptions } = this.state

		const query = {}

		const mestoNazov = get(editAddress, 'obec', null)
		if (mestoNazov) {
			const mesto = find(mestaOptions, (mesto) => get(mesto, 'mestoNazov') == mestoNazov)
			if (mesto) {
				query.mestoCastKod = get(mesto, 'mestoCastKod', null)
			} else {
				const mestoFromUlica = find(uliceOptions, (ulica) => get(ulica, 'mestoNazov') == mestoNazov)
				if (mestoFromUlica) {
					query.mestoCastKod = get(mestoFromUlica, 'mestoCastKod', null)
				} else {
					const mestoFromPsc = find(pscOptions, (psc) => get(psc, 'mestoNazov') == mestoNazov)
					if (mestoFromPsc) {
						query.mestoCastKod = get(mestoFromPsc, 'mestoCastKod', null)
					}
				}
			}
		}

		const ulicaNazov = get(editAddress, 'ulica', null)
		if (ulicaNazov) {
			const ulica = find(uliceOptions, (ulica) => get(ulica, 'ulicaNazov') == ulicaNazov)
			if (ulica) {
				query.ulicaKod = get(ulica, 'ulicaKod', null)
			} else {
				const ulicaFromPsc = find(pscOptions, (psc) => get(psc, 'ulicaNazov') == ulicaNazov)
				if (ulicaFromPsc) {
					query.ulicaKod = get(ulicaFromPsc, 'ulicaKod', null)
				}
			}
		}

		if (query.ulicaKod) {
			const res = await getReq('/api/v0/ciselniky/adresa/cisloDomu', query)

			const orientacneCisloOptions = get(res, 'response.obsah', [])

			const pscOptionsOptionsFromCisloDomu = map(uniqBy(orientacneCisloOptions, 'psc'), (orientacneCislo) => ({
				value: get(orientacneCislo, 'psc'),
				label: get(orientacneCislo, 'psc')
			}))

			this.setState({
				orientacneCisloOptions,
				pscOptionsOptionsFromCisloDomu
			})

			return orientacneCisloOptions
		}

		this.setState({
			pscOptionsOptionsFromCisloDomu: []
		})
	} catch (err) {
		return this.state.orientacneCisloOptions
	}
}

function reorderCountries(countries) {
	const SK = []
	const closeCountries = []
	const otherCountries = []

	forEach(countries, (stat) => {
		const statSelect = {
			...stat,
			value: get(stat, 'statKod'),
			label: get(stat, 'statNazov')
		}
		if (get(stat, 'statKod') == 'SK') {
			SK.push(statSelect)
		} else if (
			get(stat, 'statKod') === 'CZ' ||
			get(stat, 'statKod') === 'PL' ||
			get(stat, 'statKod') === 'AT' ||
			get(stat, 'statKod') === 'UA' ||
			get(stat, 'statKod') === 'HU'
		) {
			closeCountries.push(statSelect)
		} else {
			otherCountries.push(statSelect)
		}
	})

	return [...SK, ...orderBy(closeCountries, ['label'], ['ASC']), ...orderBy(otherCountries, ['label'], ['ASC'])]
}

export function handleChangeAddress(field, value) {
	const { handleNewAddress } = this.props
	const { editAddress } = this.state

	const newState = {
		editAddress: {
			...editAddress
		}
	}

	set(newState, `editAddress.${field}`, value)

	// reset other field if value is null or the value change on different
	if ((field == 'stat' && !value) || (field == 'stat' && value != get(editAddress, 'stat'))) {
		set(newState, 'editAddress.obec', '')
		set(newState, 'editAddress.ulica', '')
		set(newState, 'editAddress.orientacneCislo', '')
		set(newState, 'editAddress.supisneCislo', '')
		set(newState, 'editAddress.psc', '')
		set(newState, 'mestaOptions', [])
		set(newState, 'uliceOptions', [])
		set(newState, 'pscOptions', [])
	}
	// reset other field exept field "stat" if value is null or the value change on different
	if ((field == 'obec' && !value) || (field == 'obec' && value != get(editAddress, 'obec'))) {
		set(newState, 'editAddress.ulica', '')
		set(newState, 'editAddress.orientacneCislo', '')
		set(newState, 'editAddress.supisneCislo', '')
		set(newState, 'editAddress.psc', '')
		set(newState, 'uliceOptions', [])
		set(newState, 'pscOptions', [])
	}

	if (field == 'ulica') {
		set(newState, 'editAddress.orientacneCislo', '')
		set(newState, 'editAddress.supisneCislo', '')
		set(newState, 'editAddress.psc', '')
		set(newState, 'pscOptions', [])
	}

	if (field == 'psc') {
		const psc = value
			? [
					{
						value,
						label: value
					}
			  ]
			: []
		set(newState, 'pscOptions', psc)
	}

	// NOTE: comment this based on CP-3118 task
	/* if (field === 'orientacneCislo') {
		const regex = new RegExp('^([A-Z0-9])*$', 'i')
		if (!regex.test(value)) {
			return
		}
	} */

	const { errors, warnings } = revalidate.call(this, newState)

	handleNewAddress &&
		handleNewAddress({
			...newState,
			errors,
			warnings
		})

	this.setState(
		{
			...newState,
			errors,
			warnings
		},
		async () => {
			if (field == 'obec') {
				await searchOptionsUlica.call(this, value)
			}
			if (field == 'ulica' || field == 'obec') {
				await searchOptionsPsc.call(this)
				await searchOptionsOrientacneCislo.call(this)
			}
		}
	)
}

export function revalidate(newState) {
	const { t } = this.props
	const { orientacneCisloOptions } = this.state
	const { editAddress } = newState

	const errors = {}
	const warnings = {}

	const stat = get(editAddress, 'stat')
	if (!stat) {
		errors.stat = t('atoms:AddressField.Štát nesmie byť prázdny')
	}
	if (!get(editAddress, 'obec')) {
		errors.obec = t('atoms:AddressField.Obec nesmie byť prázdna')
	} else if (size(get(editAddress, 'obec', '')) > 40) {
		errors.obec = t('atoms:AddressField.Obec môže mať najviac 40 znakov')
	}

	if (!get(editAddress, 'ulica')) {
		errors.ulica = t('atoms:AddressField.Ulica nesmie byť prázdna')
	} else if (size(get(editAddress, 'ulica', '')) > 60) {
		errors.ulica = t('atoms:AddressField.Ulica môže mať najviac 60 znakov')
	}

	if (get(editAddress, 'orientacneCislo')) {
		const orientacneCislo = parseInt(get(editAddress, 'orientacneCislo'))
		if (size(get(editAddress, 'orientacneCislo', '')) > 10) {
			errors.orientacneCislo = t('atoms:AddressField.Orientačné číslo môže mať najviac 10 znakov')
		} else if (isFinite(orientacneCislo)) {
			let isValid = isEmpty(orientacneCisloOptions)

			forEach(orientacneCisloOptions, (orientacneCisloOption) => {
				if (get(editAddress, 'psc')) {
					if (get(editAddress, 'psc') == orientacneCisloOption.psc) {
						if (get(orientacneCisloOption, 'cisloRozdelenie') == 0) {
							if (get(orientacneCisloOption, 'cisloMin') <= orientacneCislo && orientacneCislo <= get(orientacneCisloOption, 'cisloMax')) {
								isValid = true
								return false
							}
						} else if (get(orientacneCisloOption, 'cisloRozdelenie') == 1 && orientacneCislo % 2 == 1) {
							if (get(orientacneCisloOption, 'cisloMin') <= orientacneCislo && orientacneCislo <= get(orientacneCisloOption, 'cisloMax')) {
								isValid = true
								return false
							}
						} else if (get(orientacneCisloOption, 'cisloRozdelenie') == 2 && orientacneCislo % 2 == 0) {
							if (get(orientacneCisloOption, 'cisloMin') <= orientacneCislo && orientacneCislo <= get(orientacneCisloOption, 'cisloMax')) {
								isValid = true
								return false
							}
						}
					}
				} else if (get(orientacneCisloOption, 'cisloRozdelenie') == 0) {
					if (get(orientacneCisloOption, 'cisloMin') <= orientacneCislo && orientacneCislo <= get(orientacneCisloOption, 'cisloMax')) {
						isValid = true
						return false
					}
				} else if (get(orientacneCisloOption, 'cisloRozdelenie') == 1 && orientacneCislo % 2 == 1) {
					if (get(orientacneCisloOption, 'cisloMin') <= orientacneCislo && orientacneCislo <= get(orientacneCisloOption, 'cisloMax')) {
						isValid = true
						return false
					}
				} else if (get(orientacneCisloOption, 'cisloRozdelenie') == 2 && orientacneCislo % 2 == 0) {
					if (get(orientacneCisloOption, 'cisloMin') <= orientacneCislo && orientacneCislo <= get(orientacneCisloOption, 'cisloMax')) {
						isValid = true
						return false
					}
				}
			})

			// if orientacne cislo is not valid or if orientacne cislo is not a integer show warning
			if (!isValid || (stat == 'SK' && get(editAddress, 'orientacneCislo') != orientacneCislo)) {
				warnings.orientacneCislo = t('atoms:AddressField.Orientačné číslo je mimo povoleného intervalu')
			}
		} else {
			warnings.orientacneCislo = t('atoms:AddressField.Orientačné číslo je mimo povoleného intervalu')
		}
	} else {
		errors.orientacneCislo = t('atoms:AddressField.Orientačné číslo nesmie byť prázdne')
	}

	if (size(get(editAddress, 'supisneCislo', '')) > 10) {
		errors.supisneCislo = t('atoms:AddressField.Súpisné číslo môže mať najviac 10 znakov')
	}

	const psc = get(editAddress, 'psc')
	if (!psc) {
		errors.psc = t('atoms:AddressField.PSČ nesmie byť prázdne')
	} else if (stat == 'SK') {
		const reWhiteSpace = new RegExp(/\s/g)
		const reNumber = new RegExp(/^[0-9\s]+$/g)
		const hasWhiteSpace = reWhiteSpace.test(psc)
		const isNumber = reNumber.test(psc)

		if (hasWhiteSpace && isNumber) {
			if (size(psc) != 6) {
				errors.psc = t('atoms:AddressField.PSČ by malo byť 6 znakov dlhé (napr 841 07)')
			}
		} else if (isNumber) {
			if (size(psc) != 5) {
				errors.psc = t('atoms:AddressField.PSČ by malo byť 5 znakov dlhé (napr 84107)')
			}
		} else {
			errors.psc = t('atoms:AddressField.PSČ musí obsahovať iba číslice (napr 84107)')
		}
	} else if (stat == 'PL') {
		if (postalCodes.validate(stat, psc) !== true) {
			errors.psc = t('atoms:AddressField.PSČ by malo byť 6 znakov dlhé (napr 00-001)')
		}
	} else if (stat == 'CZ') {
		const reWhiteSpace = new RegExp(/\s/g)
		const reNumber = new RegExp(/^[0-9\s]+$/g)
		const hasWhiteSpace = reWhiteSpace.test(psc)
		const isNumber = reNumber.test(psc)

		if (hasWhiteSpace && isNumber) {
			if (size(psc) != 6) {
				errors.psc = t('atoms:AddressField.PSČ by malo byť 6 znakov dlhé (napr 100 00)')
			}
		} else if (isNumber) {
			if (size(psc) != 5) {
				errors.psc = t('atoms:AddressField.PSČ by malo byť 5 znakov dlhé (napr 10000)')
			}
		} else {
			errors.psc = t('atoms:AddressField.PSČ musí obsahovať iba číslice (napr 10000)')
		}
	} else if (stat == 'AT') {
		if (postalCodes.validate(stat, psc) !== true) {
			errors.psc = t('atoms:AddressField.PSČ by malo byť 4 znaky dlhé (napr 1010)')
		}
	} else if (stat == 'UA') {
		if (postalCodes.validate(stat, psc) !== true) {
			errors.psc = t('atoms:AddressField.PSČ by malo byť 5 znakov dlhé (napr 01014)')
		}
	} else if (stat == 'HU') {
		if (postalCodes.validate(stat, psc) !== true) {
			errors.psc = t('atoms:AddressField.PSČ by malo byť 4 znaky dlhé (napr 1188)')
		}
	} else if (postalCodes.validate(stat, psc) !== true) {
		errors.psc = t('atoms:AddressField.PSČ nemá správny tvar')
	} else if (size(psc) > 10) {
		errors.psc = t('translation:Common.PSČ môže mať najviac 10 znakov')
	}

	const doRukMeno = get(editAddress, 'doRukMeno', '')
	if (doRukMeno && size(doRukMeno) > 40) {
		errors.doRukMeno = t('atoms:AddressField.Do rúk môže mať najviac 40 znakov')
	}

	return {
		errors,
		warnings
	}
}
