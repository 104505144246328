import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { bindActionCreators, compose } from 'redux'
import { get, map, isEmpty } from 'lodash'
import { Tooltip } from 'react-tippy'
import cx from 'classnames'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'

// components
import Modal from './Modal'
import Select from '../../atoms/BasicSelect'
import ElementLoading from '../ElementLoading'

// utils
import { TYP_PLATCU_PLANU_SPLATOK } from '../../utils/enums'
import { celeMeno } from '../../utils/obchodnyPartner'
import Permissions, { PERMISSIONS, checkPermissions } from '../../utils/permissionsHoc'

// resources
import crossIcon from '../../resources/img/icons/cross-black.svg'
import { formatAddress } from '../../utils/address'

class VyberTypuPlatcuPlanuSplatok extends React.Component {
	static propTypes = {
		auth: PropTypes.shape().isRequired,
		modalTitle: PropTypes.string,
		obchodnyPartnerActions: PropTypes.shape().isRequired,
		splnomocnenia: PropTypes.shape(),
		onCloseButton: PropTypes.func.isRequired,
		onSubmit: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			selectedPlatcaTyp: TYP_PLATCU_PLANU_SPLATOK.SAM_ZA_SEBA,
			selectedSplnomocnenec: null
		}
	}

	componentDidMount() {
		const { auth, obchodnyPartnerActions } = this.props

		if (checkPermissions(get(auth, 'roles', []), [PERMISSIONS.VIEW_SPLNOMOCNENIE])) {
			obchodnyPartnerActions.loadVztahy()
		}
	}

	handleSubmit = () => {
		const { onSubmit } = this.props
		const { selectedPlatcaTyp, selectedSplnomocnenec } = this.state

		onSubmit({
			selectedPlatcaTyp,
			selectedSplnomocnenec: get(selectedSplnomocnenec, 'value')
		})
	}

	render() {
		const { modalTitle, onCloseButton, splnomocnenia, t } = this.props
		const { selectedPlatcaTyp, selectedSplnomocnenec } = this.state

		let content = null
		if (selectedPlatcaTyp == TYP_PLATCU_PLANU_SPLATOK.SAM_ZA_SEBA) {
			content = <span>{t('translation:PlanySplatok.Obchodný partner vytvára plán splátok sám za seba')}</span>
		} else if (selectedPlatcaTyp == TYP_PLATCU_PLANU_SPLATOK.SPLNOMOCNENEC) {
			if (get(splnomocnenia, 'isLoading')) {
				content = (
					<div style={{ minHeight: '50px', marginTop: '25px' }}>
						<ElementLoading />
					</div>
				)
			} else if (isEmpty(splnomocnenia, 'data', [])) {
				content = <span>{t('translation:PlanySplatok.Obchodný partner nemá žiadne splnomocnenia')}</span>
			} else {
				const splnomocneniaOptions = map(get(splnomocnenia, 'data', []), (splnomocnenie) => ({
					label: `${get(splnomocnenie, 'splnomocnenec.cislo')} - ${celeMeno(get(splnomocnenie, 'splnomocnenec'))}, ${formatAddress(
						get(splnomocnenie, 'splnomocnenec.adresaZakaznika')
					)}`,
					value: `${get(splnomocnenie, 'splnomocnenec.cislo')}+${get(splnomocnenie, 'platnostOd')}`
				}))
				content = (
					<Select
						label={t('translation:Splnomocnenia.Zvoľte splnomocnenca')}
						onChange={(value) =>
							this.setState({
								selectedSplnomocnenec: value
							})
						}
						options={splnomocneniaOptions}
						value={selectedSplnomocnenec}
						isClearable={false}
						isSearchable={false}
						placeholder={t('translation:Splnomocnenia.Prosím vyberte splnomocnenie')}
					/>
				)
			}
		} else if (selectedPlatcaTyp == TYP_PLATCU_PLANU_SPLATOK.DEDIC) {
			content = (
				<span>
					{t(
						'translation:PlanySplatok.Platca je dedičom obchodného partnera V ďalšom kroku vyberiete existujúceho obchodného partnera alebo založíte nového'
					)}
				</span>
			)
		}

		return (
			<Modal shown size='m'>
				{modalTitle && (
					<div className='modal-header'>
						<h3>{modalTitle}</h3>
						<div className='close' onClick={onCloseButton}>
							<img src={crossIcon} width='25' />
						</div>
					</div>
				)}
				<div className='modal-content'>
					<div className='row' style={{ paddingBottom: '20px' }}>
						<div className='col-4'>
							<button
								className='button'
								type='button'
								onClick={() =>
									this.setState({
										selectedPlatcaTyp: TYP_PLATCU_PLANU_SPLATOK.SAM_ZA_SEBA
									})
								}
								data-color='blue'
								data-type={selectedPlatcaTyp != TYP_PLATCU_PLANU_SPLATOK.SAM_ZA_SEBA ? 'outline' : ''}
								style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
							>
								{t('translation:PlanySplatok.Sám za seba')}
							</button>
						</div>
						<Permissions
							allowed={[PERMISSIONS.VIEW_SPLNOMOCNENIE]}
							render={(hasPerm) => {
								if (hasPerm) {
									return (
										<div className='col-4'>
											<button
												className='button'
												type='button'
												onClick={() =>
													this.setState({
														selectedPlatcaTyp: TYP_PLATCU_PLANU_SPLATOK.SPLNOMOCNENEC
													})
												}
												data-color='blue'
												data-type={selectedPlatcaTyp != TYP_PLATCU_PLANU_SPLATOK.SPLNOMOCNENEC ? 'outline' : ''}
												style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
											>
												{t('translation:PlanySplatok.Splnomocnenec')}
											</button>
										</div>
									)
								}

								return (
									<div className='col-4'>
										<Tooltip
											html={<span>{t('translation:Common.Na vykonanie akcie nemáte potrebné oprávnenia')}</span>}
											position='top'
											trigger='mouseenter'
											theme='light'
										>
											<button
												className={cx('button', { disabled: true })}
												type='button'
												onClick={(e) => e.preventDefault()}
												data-color='blue'
												style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
											>
												{t('translation:PlanySplatok.Splnomocnenec')}
											</button>
										</Tooltip>
									</div>
								)
							}}
						/>
						<div className='col-4'>
							<button
								className='button'
								type='button'
								onClick={() =>
									this.setState({
										selectedPlatcaTyp: TYP_PLATCU_PLANU_SPLATOK.DEDIC
									})
								}
								data-color='blue'
								data-type={selectedPlatcaTyp != TYP_PLATCU_PLANU_SPLATOK.DEDIC ? 'outline' : ''}
								style={{ width: '100%', paddingLeft: '10px', paddingRight: '10px' }}
							>
								{t('translation:PlanySplatok.Dedič')}
							</button>
						</div>
					</div>
					<div className='row'>
						<div className='col-12'>{content}</div>
					</div>
				</div>
				<div className='modal-footer clearfix'>
					<button
						type='button'
						className='button'
						onClick={this.handleSubmit}
						data-color='blue'
						disabled={!selectedPlatcaTyp || (selectedPlatcaTyp == TYP_PLATCU_PLANU_SPLATOK.SPLNOMOCNENEC && !selectedSplnomocnenec)}
						style={{ width: '100%', marginLeft: 0 }}
					>
						{t('translation:Common.Pokračovať')}
					</button>
				</div>
			</Modal>
		)
	}
}
const mapStateToProps = (state) => ({
	auth: get(state, 'auth'),
	interakcia: get(state, 'interakcie.detail.data'),
	splnomocnenia: get(state, 'obchodnyPartner.splnomocnenia')
})

const mapDispatchToProps = (dispatch) => ({
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(VyberTypuPlatcuPlanuSplatok)
