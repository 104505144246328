import { get, isEmpty } from 'lodash'

import OdberneMiestoField from '../../components/GenericUkon/fields/OdberneMiestoField'
import ProduktField from '../../components/GenericUkon/fields/ProduktField'
import ProduktovaRodinaField from '../../components/GenericUkon/fields/ProduktovaRodinaField'
import ProduktVsdSsdField from '../../components/GenericUkon/fields/ProduktVsdSsdField'
import PozadovanyDatumField from '../../components/GenericUkon/fields/PozadovanyDatumField'
import PozadovanyDatumReadOnlyField from '../../components/GenericUkon/fields/PozadovanyDatumReadOnlyField'
import ZmluvnyUcetField from '../../components/GenericUkon/fields/ZmluvnyUcet/ZmluvnyUcetField'
import BooleanField from '../../components/GenericUkon/fields/BooleanField'
import OnlyYesConfirmField from '../../components/GenericUkon/fields/OnlyYesConfirmField'
import OdpocetField from '../../components/GenericUkon/fields/OdpocetField'
import ZalohyField from '../../components/GenericUkon/fields/ZalohyField'
import UcelPouzitiaGasField from '../../components/GenericUkon/fields/UcelPouzitiaGasField'
import DovodUkonceniaField from '../../components/GenericUkon/fields/DovodUkonceniaField'
import RelatedUkonField from '../../components/GenericUkon/fields/RelatedUkonField'
import PoznamkaField from '../../components/GenericUkon/fields/PoznamkaField'
import VstupneDokumentyField from '../../components/GenericUkon/fields/VstupneDokumentyField'
import VstupneDokumentySystemoveField from '../../components/GenericUkon/fields/VstupneDokumentySystemoveField'
import ZmluvnyVztahCisloIsuField from '../../components/GenericUkon/fields/ZmluvnyVztahCisloIsuField'
import ZmluvnyVztahCisloField from '../../components/GenericUkon/fields/ZmluvnyVztahCisloField'
import MiestoSpotrebyField from '../../components/GenericUkon/fields/MiestoSpotrebyField'
import VyrocieDKField from '../../components/GenericUkon/fields/VyrocieDKField'
import DistribucneCisloOPField from '../../components/GenericUkon/fields/DistribucneCisloOPField'
import PoznamkaSPPDField from '../../components/GenericUkon/fields/PoznamkaSPPDField'
import PreukazTypField from '../../components/GenericUkon/fields/PreukazTypField'
import PreukazCisloField from '../../components/GenericUkon/fields/PreukazCisloField'
import ElektromobilField from '../../components/GenericUkon/fields/ElektromobilField'
import SubjektZuctovaniaField from '../../components/GenericUkon/fields/SubjektZuctovaniaField'
import UkonKategoriaField from '../../components/GenericUkon/fields/UkonKategoriaField'
import VstupDoUkonuField from '../../components/GenericUkon/fields/VstupDoUkonuField'
import MiestoSpotrebyVyberField from '../../components/GenericUkon/fields/MiestoSpotrebyVyberField'
import UkonDokumentyField from '../../components/GenericUkon/fields/UkonDokumentyField'
import FazyPocetField from '../../components/GenericUkon/fields/FazyPocetField'
import HlavnyIsticHodnotaField from '../../components/GenericUkon/fields/HlavnyIsticHodnotaField'

import { formatAddressForResponse } from '../../utils/address'

import { KOMODITA_TYP, UKONY_CISELNIK } from '../../utils/enums'

import { getAccessToken } from '../../utils/auth'

export const EDIT_MODE = {
	LOOKUP: 'LOOKUP',
	EDIT: 'EDIT',
	CONFIRM: 'CONFIRM',
	DETAIL: 'DETAIL'
}

export const COLUMNS_COUNT = {
	TWO: 'TWO',
	THREE: 'THREE'
}

export const ATTRIBUTES_CONFIG = {
	AttrOdberneMiestoDTO: {
		label: 'Odberné miesto',
		fieldComponent: OdberneMiestoField,
		destroy: (props, attribute) => {
			const { formValues, ukonyActions, odberneMiestaActions } = props

			const omCislo = get(formValues, `${get(attribute, 'cesta')}.cislo`)
			if (omCislo) {
				ukonyActions.clearUkonyForOdberneMiesto(omCislo)
			}
			odberneMiestaActions.searchOdberneMiestaClear()
		}
	},
	AttrProduktDTO: {
		fieldComponent: ProduktField
	},
	AttrProduktVsdSsdDTO: {
		fieldComponent: ProduktVsdSsdField
	},
	AttrProduktovaRodinaDTO: {
		fieldComponent: ProduktovaRodinaField
	},
	AttrPozadovanyDatumDTO: {
		fieldComponent: PozadovanyDatumField
	},
	AttrPozadovanyDatumReadOnlyDTO: {
		fieldComponent: PozadovanyDatumReadOnlyField
	},
	AttrZmluvnyUcetDTO: {
		fieldComponent: ZmluvnyUcetField,
		format: (props, value) => {
			const { addresses } = props

			const adresaFakturacie = formatAddressForResponse('adresaFakturacie', value, addresses)

			return {
				...value,
				adresaFakturacie
			}
		}
	},
	AttrPohladavkyUhradeneDTO: {
		label: 'Uhradené pohľadávky',
		fieldComponent: BooleanField
	},
	AttrUzivaciePravoPreukazaneDTO: {
		label: 'Preukázané užívacie právo k OM',
		fieldComponent: BooleanField
	},
	AttrLimitovanaObsluhaSchvaleneDTO: {
		label: 'Požiadavka bola schválená (neplatici@zse.sk)',
		fieldComponent: BooleanField
	},
	AttrOdpocetDTO: {
		label: 'Stav meradla',
		fieldComponent: OdpocetField
	},
	AttrPredpokladanaRocnaSpotrebaDTO: {
		label: 'Predpokladaná ročná spotreba',
		showOnlyModes: [EDIT_MODE.CONFIRM, EDIT_MODE.DETAIL],
		hideBeforeValue: true,
		fieldComponent: OdpocetField
	},
	AttrZalohyDTO: {
		fieldComponent: ZalohyField
	},
	AttrUcelPouzitiaGasDTO: {
		fieldComponent: UcelPouzitiaGasField
	},
	AttrDovodUkonceniaDTO: {
		fieldComponent: DovodUkonceniaField
	},
	AttrZmluvaNovaUkonIdDTO: {
		label: 'Súvisiaci úkon novej zmluvy',
		buttonLabel: 'Nová zmluva',
		fieldComponent: RelatedUkonField,
		readonly: true
	},
	AttrZmluvaPrepisUkonIdDTO: {
		label: 'Súvisiaci úkon ukončenia zmluvy',
		buttonLabel: 'Ukončenie zmluvy',
		fieldComponent: RelatedUkonField,
		readonly: true
	},
	AttrPoznamkaDTO: {
		fieldComponent: PoznamkaField
	},
	AttrPrilohaDTO: {
		fieldComponent: VstupneDokumentyField
	},
	AttrPrilohyDTO: {
		fieldComponent: VstupneDokumentySystemoveField
	},
	AttrUkonDokumentyDTO: {
		fieldComponent: UkonDokumentyField
	},
	AttrZmluvnyVztahCisloIsuDTO: {
		fieldComponent: ZmluvnyVztahCisloIsuField
	},
	AttrZmluvnyVztahCisloDTO: {
		fieldComponent: ZmluvnyVztahCisloField
	},
	AttrZopPotvrdenaDTO: {
		label: 'Zmluva o pripojení (ZoP) potvrdená',
		fieldComponent: OnlyYesConfirmField
	},
	AttrMiestoSpotrebyDTO: {
		label: 'Miesto spotreby',
		fieldComponent: MiestoSpotrebyField,
		destroy: (props) => {
			const { odberneMiestaActions, miestaSpotrebyActions } = props

			odberneMiestaActions.searchOdberneMiestaClear()
			miestaSpotrebyActions.searchMiestaSpotrebyClear()
		}
	},
	AttrMiestoSpotrebyVyberDTO: {
		label: 'Vyberte miesto spotreby',
		fieldComponent: MiestoSpotrebyVyberField
	},
	AttrElektromobilDTO: {
		fieldComponent: ElektromobilField
	},
	AttrPriPotvrdenaDTO: {
		formatLabel: (props) => {
			const typ = get(props, 'novaZmluvaTyp', '')
			return `Zmluva o pripojení (${typ}) potvrdená`
		},
		tooltip: 'Overenie platnosti zmluvy o Pripojení na požadované parametre zodpovedajúcemu obchodnému produktu',
		fieldComponent: OnlyYesConfirmField
	},
	AttrTechnickaZmenaOMDTO: {
		label: 'Podaná Žiadosť o vyjadrenie k technickej zmene na OM',
		fieldComponent: BooleanField
	},
	AttrAdresaSppdDTO: {
		label: 'Miesto spotreby',
		fieldComponent: MiestoSpotrebyField,
		destroy: (props) => {
			const { odberneMiestaActions, miestaSpotrebyActions } = props

			odberneMiestaActions.searchOdberneMiestaClear()
			miestaSpotrebyActions.searchMiestaSpotrebyClear()
		}
	},
	AttrVyrocieDKDTO: {
		fieldComponent: VyrocieDKField
	},
	AttrDistribucneOPCisloDTO: {
		fieldComponent: DistribucneCisloOPField
	},
	AttrGasPodmienkySplneneDTO: {
		label: 'Splnené podmienky SPP-D',
		fieldComponent: OnlyYesConfirmField
	},
	AttrOmVyrobaDTO: {
		label: 'Je na OM výroba?',
		fieldComponent: BooleanField
	},
	AttrInformaciaPreSppdDTO: {
		fieldComponent: PoznamkaSPPDField
	},
	AttrPreukazTypDTO: {
		fieldComponent: PreukazTypField
	},
	AttrPreukazCisloDTO: {
		fieldComponent: PreukazCisloField
	},
	AttrOkamzitaDodavkaSuhlasDTO: {
		label: 'Súhlas s okamžitou dodávkou',
		fieldComponent: BooleanField
	},
	AttrViazanostDTO: {
		label: 'Viazanosť na dobu určitú?',
		fieldComponent: BooleanField
	},
	AttrSubjektZuctovaniaDTO: {
		label: 'Subjekt zúčtovania',
		fieldComponent: SubjektZuctovaniaField
	},
	AttrUkonKategoria1DTO: {
		fieldComponent: UkonKategoriaField,
		optionsData: 'ukonKategoria1',
		label: 'Kategória 1'
	},
	AttrUkonKategoria2DTO: {
		fieldComponent: UkonKategoriaField,
		optionsData: 'ukonKategoria2',
		label: 'Kategória 2'
	},
	AttrUkonKategoria3DTO: {
		fieldComponent: UkonKategoriaField,
		optionsData: 'ukonKategoria3',
		label: 'Kategória 3'
	},
	AttrUkonVstupDTO: {
		fieldComponent: VstupDoUkonuField
	},
	AttrFazyPocetDTO: {
		fieldComponent: FazyPocetField
	},
	AttrHlavnyIsticHodnotaDTO: {
		fieldComponent: HlavnyIsticHodnotaField
	}
}
// old FE based urls

// const METHODOLOGICAL_GUIDELINES = {
// 	PREPIS_ZMLUVY_EE: 'https://zsegroup.sharepoint.com/sites/energiaportal/SitePages/Prepis%20zmluvy%20o%20dod%C3%A1vke%20elektriny%20v%20dom%C3%A1cnosti.aspx',
// 	PREPIS_ZMLUVY_GAS: 'https://zsegroup.sharepoint.com/sites/energiaportal/SitePages/Prepis-plyn-dom%C3%A1cnos%C5%A5.aspx',
// 	NOVY_ODBER_EE: 'https://zsegroup.sharepoint.com/sites/energiaportal/SitePages/Nov%C3%BD-odber(MOO%20EE).aspx',
// 	NOVY_ODBER_EE_PREPIS_DVOJICKA: 'https://zsegroup.sharepoint.com/sites/energiaportal/SitePages/Prepis%20elektrina-dom%C3%A1cnosti.aspx',
// 	NOVY_ODBER_GAS: 'https://zsegroup.sharepoint.com/sites/energiaportal/SitePages/Nov%C3%BD%20odber%20plynu%20v%20dom%C3%A1cnosti.aspx',
// 	NOVY_ODBER_GAS_PREPIS_DVOJICKA: 'https://zsegroup.sharepoint.com/sites/energiaportal/SitePages/Prepis-plyn-dom%C3%A1cnos%C5%A5.aspx',
// 	UKONCENIE_ZMLUVY_EE: 'https://zsegroup.sharepoint.com/sites/energiaportal/SitePages/Ukon%C4%8Denie-zmluvy-EE-dom%C3%A1cnos%C5%A5.aspx',
// 	UKONCENIE_ZMLUVY_GAS: 'https://zsegroup.sharepoint.com/sites/energiaportal/SitePages/Ukon%C4%8Denie-Plyn-dom%C3%A1cnos%C5%A5.aspx',
// 	PREDAJ_VAS: 'https://zsegroup.sharepoint.com/sites/energiaportal/SitePages/VAS.aspx',
// 	DODATOK_EE: 'https://zsegroup.sharepoint.com/sites/energiaportal/SitePages/Elektrina-MOO.aspx',
// 	DODATOK_GAS: 'https://zsegroup.sharepoint.com/sites/energiaportal/SitePages/Plyn%20(MOO).aspx',
// 	ZMENA_SADZBY_EE: 'https://zsegroup.sharepoint.com/sites/energiaportal/SitePages/Zmena-sadzby-a-tarify-elektrina---dom%C3%A1cnos%C5%A5.aspx',
// 	ZMENA_SADZBY_GAS: 'https://zsegroup.sharepoint.com/sites/energiaportal/SitePages/Zmena-tarify-plynu-dom%C3%A1cnos%C5%A5.aspx'
// }
//

const METHODOLOGICAL_GUIDELINES = {
	VSEOBECNY_UKON_AKTIVNY: `/api/v0/pomocnik/vseobecnyUkon?accessToken=${getAccessToken()}`,
	VSEOBECNY_UKON_PASIVNY: `/api/v0/pomocnik/vseobecnyUkonPasivny?accessToken=${getAccessToken()}`,
	PREPIS_ZMLUVY_EE: `/api/v0/pomocnik/prepisZmluvyEE?accessToken=${getAccessToken()}`,
	PREPIS_ZMLUVY_GAS: `/api/v0/pomocnik/prepisZmluvyGAS?accessToken=${getAccessToken()}`,
	NOVY_ODBER_EE: `/api/v0/pomocnik/novyOdberEE?accessToken=${getAccessToken()}`,
	NOVY_ODBER_EE_PREPIS_DVOJICKA: `/api/v0/pomocnik/prepisZmluvyEEDvojicka?accessToken=${getAccessToken()}`,
	NOVY_ODBER_GAS: `/api/v0/pomocnik/novyOdberGAS?accessToken=${getAccessToken()}`,
	OMS_GAS: `/api/v0/pomocnik/obnovaMiestaSpotrebyGAS?accessToken=${getAccessToken()}`,
	NOVY_ODBER_GAS_PREPIS_DVOJICKA: `/api/v0/pomocnik/prepisZmluvyGASDvojicka?accessToken=${getAccessToken()}`,
	UKONCENIE_ZMLUVY_EE: `/api/v0/pomocnik/ukoncenieZmluvyEE?accessToken=${getAccessToken()}`,
	UKONCENIE_ZMLUVY_GAS: `/api/v0/pomocnik/ukoncenieZmluvyGAS?accessToken=${getAccessToken()}`,
	PREDAJ_VAS: `/api/v0/pomocnik/predajVAS?accessToken=${getAccessToken()}`,
	DODATOK_EE: `/api/v0/pomocnik/dodatokEE?accessToken=${getAccessToken()}`,
	DODATOK_GAS: `/api/v0/pomocnik/dodatokGAS?accessToken=${getAccessToken()}`,
	ZMENA_SADZBY_EE: `/api/v0/pomocnik/zmenaSadzbyEE?accessToken=${getAccessToken()}`,
	ZMENA_SADZBY_GAS: `/api/v0/pomocnik/zmenaSadzbyGAS?accessToken=${getAccessToken()}`
}

export const recognizeMethodologicalGuideline = (ponuka) => {
	const ukonTyp = get(ponuka, 'ukon.typ.id')

	if (ukonTyp == UKONY_CISELNIK.VSEOBECNY_UKON_AKTIVNY) {
		return METHODOLOGICAL_GUIDELINES.VSEOBECNY_UKON_AKTIVNY
	}

	if (ukonTyp == UKONY_CISELNIK.VSEOBECNY_UKON_PASIVNY) {
		return METHODOLOGICAL_GUIDELINES.VSEOBECNY_UKON_PASIVNY
	}

	// Prepis zmluvy - typ 38, 1020 a 2020
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_PREPIS) {
		const komoditaTyp = get(ponuka, 'ukon.data.odberneMiesto.komoditaTyp.id')
		if (komoditaTyp == KOMODITA_TYP.ELEKTRINA) {
			return METHODOLOGICAL_GUIDELINES.PREPIS_ZMLUVY_EE
		}
		if (komoditaTyp == KOMODITA_TYP.PLYN) {
			return METHODOLOGICAL_GUIDELINES.PREPIS_ZMLUVY_GAS
		}
	}
	// Prepis zmluvy - typ 1020
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_PREPIS_EE) {
		return METHODOLOGICAL_GUIDELINES.PREPIS_ZMLUVY_EE
	}
	// Prepis zmluvy - typ 2020
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_PREPIS_GAS) {
		return METHODOLOGICAL_GUIDELINES.PREPIS_ZMLUVY_GAS
	}

	// Nova zmluva - typ 35
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY) {
		const komoditaTyp = get(ponuka, 'ukon.data.odberneMiesto.komoditaTyp.id')
		if (isEmpty(get(ponuka, 'ukon.dataPred.produkt'))) {
			// Novy odber
			if (komoditaTyp == KOMODITA_TYP.ELEKTRINA) {
				return METHODOLOGICAL_GUIDELINES.NOVY_ODBER_EE
			}
			if (komoditaTyp == KOMODITA_TYP.PLYN) {
				return METHODOLOGICAL_GUIDELINES.NOVY_ODBER_GAS
			}
		} else {
			// Dvojicka prepisu
			if (komoditaTyp == KOMODITA_TYP.ELEKTRINA) {
				return METHODOLOGICAL_GUIDELINES.NOVY_ODBER_EE_PREPIS_DVOJICKA
			}
			if (komoditaTyp == KOMODITA_TYP.PLYN) {
				return METHODOLOGICAL_GUIDELINES.NOVY_ODBER_GAS_PREPIS_DVOJICKA
			}
		}
	}
	// Nova zmluva - typ 2010
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_GAS) {
		if (isEmpty(get(ponuka, 'ukon.dataPred.produkt'))) {
			// Novy odber
			return METHODOLOGICAL_GUIDELINES.NOVY_ODBER_GAS
		}

		// Dvojicka prepisu
		return METHODOLOGICAL_GUIDELINES.NOVY_ODBER_GAS_PREPIS_DVOJICKA
	}
	// obnova miesta spotreby GAS - typ 2030
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_GAS_OBNOVA_MS) {
		return METHODOLOGICAL_GUIDELINES.OMS_GAS
	}
	// Nova zmluva - typ 1010, 1030
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_EE || ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_EE_OBNOVA_MS) {
		if (isEmpty(get(ponuka, 'ukon.dataPred.produkt'))) {
			// Novy odber
			return METHODOLOGICAL_GUIDELINES.NOVY_ODBER_EE
		}

		// Dvojicka prepisu
		return METHODOLOGICAL_GUIDELINES.NOVY_ODBER_EE_PREPIS_DVOJICKA
	}

	// Ukoncenie zmluvy - typ 37
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_UKONCENIE) {
		const komoditaTyp = get(ponuka, 'ukon.data.odberneMiesto.komoditaTyp.id')
		if (komoditaTyp == KOMODITA_TYP.ELEKTRINA) {
			return METHODOLOGICAL_GUIDELINES.UKONCENIE_ZMLUVY_EE
		}
		if (komoditaTyp == KOMODITA_TYP.PLYN) {
			return METHODOLOGICAL_GUIDELINES.UKONCENIE_ZMLUVY_GAS
		}
	}
	// Ukoncenie zmluvy - typ 1410
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_UKONCENIE_EE_PREPIS) {
		return METHODOLOGICAL_GUIDELINES.PREPIS_ZMLUVY_EE
	}
	// Ukoncenie zmluvy - typ 2410
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_UKONCENIE_GAS_PREPIS) {
		return METHODOLOGICAL_GUIDELINES.UKONCENIE_ZMLUVY_GAS
	}

	// Predaj VAS - typ 3010
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_VAS) {
		const komoditaTyp = get(ponuka, 'ukon.data.odberneMiesto.komoditaTyp.id')
		if (komoditaTyp == KOMODITA_TYP.ELEKTRINA || komoditaTyp == KOMODITA_TYP.PLYN || komoditaTyp == KOMODITA_TYP.NEKOMODITA) {
			return METHODOLOGICAL_GUIDELINES.PREDAJ_VAS
		}
	}

	// Novy dodatok - typ 3020
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_DODATOK) {
		const komoditaTyp = get(ponuka, 'ukon.data.odberneMiesto.komoditaTyp.id')
		if (komoditaTyp == KOMODITA_TYP.ELEKTRINA) {
			return METHODOLOGICAL_GUIDELINES.DODATOK_EE
		}
		if (komoditaTyp == KOMODITA_TYP.PLYN) {
			return METHODOLOGICAL_GUIDELINES.DODATOK_GAS
		}
	}

	// Zmena sadzby - typ 42
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_ZMENA) {
		const komoditaTyp = get(ponuka, 'ukon.data.odberneMiesto.komoditaTyp.id')
		if (komoditaTyp == KOMODITA_TYP.ELEKTRINA) {
			return METHODOLOGICAL_GUIDELINES.ZMENA_SADZBY_EE
		}
		if (komoditaTyp == KOMODITA_TYP.PLYN) {
			return METHODOLOGICAL_GUIDELINES.ZMENA_SADZBY_GAS
		}
	}
	// Zmena sadzby - typ 1210
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_ZMENA_PRODUKTU_EE) {
		return METHODOLOGICAL_GUIDELINES.ZMENA_SADZBY_EE
	}
	// Zmena sadzby - typ 2210
	if (ukonTyp == UKONY_CISELNIK.ZMLUVNY_VZTAH_ZMENA_PRODUKTU_GAS) {
		return METHODOLOGICAL_GUIDELINES.ZMENA_SADZBY_GAS
	}
	return null
}
