import { get } from 'lodash'
import { UKONY, UKONY_CISELNIK } from './enums'

export const getUkonEnumByType = (ukonTyp) => {
	const id = get(ukonTyp, 'id')
	switch (id) {
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY:
			return UKONY.ZMLUVNY_VZTAH_NOVY
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_UKONCENIE:
			return UKONY.ZMLUVNY_VZTAH_UKONCENIE
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_VAS:
			return UKONY.ZMLUVNY_VZTAH_NOVY_VAS
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_DODATOK:
			return UKONY.ZMLUVNY_VZTAH_NOVY_DODATOK
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_ZMENA:
			return UKONY.ZMLUVNY_VZTAH_ZMENA
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_PREPIS:
			return UKONY.ZMLUVNY_VZTAH_PREPIS
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_EE:
			return UKONY.ZMLUVNY_VZTAH_NOVY_EE
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_EE_OBNOVA_MS:
			return UKONY.ZMLUVNY_VZTAH_NOVY_EE_OBNOVA_MS
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_GAS:
			return UKONY.ZMLUVNY_VZTAH_NOVY_GAS
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_GAS_OBNOVA_MS:
			return UKONY.ZMLUVNY_VZTAH_NOVY_GAS_OBNOVA_MS
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_ZMENA_PRODUKTU_EE:
			return UKONY.ZMLUVNY_VZTAH_ZMENA_PRODUKTU_EE
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_ZMENA_PRODUKTU_GAS:
			return UKONY.ZMLUVNY_VZTAH_ZMENA_PRODUKTU_GAS
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_UKONCENIE_EE_PREPIS:
			return UKONY.ZMLUVNY_VZTAH_UKONCENIE_EE_PREPIS
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_UKONCENIE_GAS_PREPIS:
			return UKONY.ZMLUVNY_VZTAH_UKONCENIE_GAS_PREPIS
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_PREPIS_EE:
			return UKONY.ZMLUVNY_VZTAH_PREPIS_EE
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_PREPIS_GAS:
			return UKONY.ZMLUVNY_VZTAH_PREPIS_GAS
		case UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE:
			return UKONY.PODPIS_ZMLUVNEJ_DOKUMENTACIE
		case UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_NOVY_ODBER:
			return UKONY.PODPIS_ZMLUVNEJ_DOKUMENTACIE_NOVY_ODBER
		case UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_PREPIS:
			return UKONY.PODPIS_ZMLUVNEJ_DOKUMENTACIE_PREPIS
		case UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_OBNOVA_MS:
			return UKONY.PODPIS_ZMLUVNEJ_DOKUMENTACIE_OBNOVA_MS
		case UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_VAS:
			return UKONY.PODPIS_ZMLUVNEJ_DOKUMENTACIE_VAS
		case UKONY_CISELNIK.PODPIS_ZMLUVNEJ_DOKUMENTACIE_DODATOK:
			return UKONY.PODPIS_ZMLUVNEJ_DOKUMENTACIE_DODATOK
		default:
			return null
	}
}

// CP-2377
export const getProcesnyKonfigType = (ukonTyp, ukony) => {
	let procesnyKonfigType = null
	if (ukony == undefined) return null

	Object.entries(ukony).forEach(([key, value]) => {
		if (value.standardneAtributy.typyUkonov) {
			let typyUkonovArray = value.standardneAtributy.typyUkonov.split(',')
			typyUkonovArray = typyUkonovArray.map((stringValue) => Number(stringValue))

			if (typyUkonovArray.some((ukonTypFromArray) => ukonTypFromArray === ukonTyp)) {
				procesnyKonfigType = key
			}
		}
	})

	return procesnyKonfigType
}
