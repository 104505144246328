import React from 'react'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Field, change } from 'redux-form'
import dayjs from 'dayjs'

// atoms
import TimeRangeField from '../../../atoms/TimeRangeField'
import SelectCategoryOneField from './fields/SelectCategoryOneField'

// utils
import GenericFields from './GenericFields'
import { CheckboxField } from '../../../atoms'
import { FORMS } from '../../../utils/enums'

export const UKON_CATEGORY_ONE_KEY = 'ukonKategoria1'
export const UKON_TIME_RANGE_KEY = 'timeRange'
export const PRIMARNY_UKON_KEY = 'primarny'

class GenericFieldArray extends React.Component {
	static propTypes = {
		interakciaStartTime: PropTypes.any.isRequired,
		currentTime: PropTypes.any.isRequired,
		ukonOptions: PropTypes.array.isRequired,
		isMOP: PropTypes.bool.isRequired,
		formValues: PropTypes.shape(),
		ukony: PropTypes.shape()
	}

	constructor(props) {
		super(props)
		this.state = {}
	}

	onChangePrimarnyUkon = () => {
		const { formValues, dispatch } = this.props

		const primarnyUkonIndex = formValues.ukony.findIndex((ukon) => ukon[PRIMARNY_UKON_KEY] === true)

		dispatch(change(FORMS.GENERIC_UKON_UKONCIT_INTERAKCIU, `ukony[${primarnyUkonIndex}].${PRIMARNY_UKON_KEY}`, false))
	}

	render() {
		const { t, fields, interakciaStartTime, currentTime, ukonOptions, isMOP, formValues, ukony } = this.props

		return (
			<>
				{fields.map((member, index) => {
					const fieldValues = fields.get(index)
					const timeRangeFieldName = `${member}.${UKON_TIME_RANGE_KEY}`
					const primarnyUkonFieldName = `${member}.${PRIMARNY_UKON_KEY}`
					const ukonCategory1FieldName = `${member}.${UKON_CATEGORY_ONE_KEY}`

					const selectedUkonCategory1 = fieldValues?.[UKON_CATEGORY_ONE_KEY]?.value
					return (
						<>
							<tr key={`generic-field-row-${index}`}>
								<td>
									<div style={{ marginBottom: '16px' }}>
										<Field
											name={timeRangeFieldName}
											component={TimeRangeField}
											startRange={interakciaStartTime}
											endRange={currentTime}
											minuteIncrement={1}
											use24Hours={true}
											placeholder={t('translation:Common.Zvoľte čas')}
											showErrorOnUntouch
										/>
									</div>
									<div>
										<Field
											name={primarnyUkonFieldName}
											component={CheckboxField}
											label={t('containers:UkoncenieInterakcie.Označiť ako primárny úkon')}
											onChange={(value) => this.onChangePrimarnyUkon(value, primarnyUkonFieldName)}
										/>
									</div>
								</td>
								<td>
									<div className='row'>
										<div className='col-12'>
											<Field name={ukonCategory1FieldName} component={SelectCategoryOneField} ukonOptions={ukonOptions} />
										</div>
									</div>
									<div className='row'>
										<div className='col-12'>
											<GenericFields
												parentIndex={index}
												parentFieldName={member}
												selectedUkon={selectedUkonCategory1}
												formValues={formValues}
											/>
										</div>
									</div>
								</td>
								<td>
									<div className='remove-button' onClick={() => fields.remove(index)} />
								</td>
							</tr>
						</>
					)
				})}
				{ukony}
				<br />
				{isMOP === false && (
					<tr>
						<td colSpan='2'>
							<div className='text-center'>
								<div
									className='button with-icon'
									data-type='outline'
									onClick={() =>
										fields.push({
											[UKON_TIME_RANGE_KEY]: {
												startTime: interakciaStartTime.startOf('minute'),
												endTime: currentTime.endOf('minute')
											}
										})
									}
									data-icon='plus'
									data-color='blue'
								>
									{t('containers:UkoncenieInterakcie.Vložiť úkon manuálne')}
								</div>
							</div>
						</td>
					</tr>
				)}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie
})

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(GenericFieldArray)
