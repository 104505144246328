import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { reduxForm, propTypes } from 'redux-form'
import PropTypes from 'prop-types'
import { get, find, upperFirst, map, omit, isEqual, forEach, isEmpty } from 'lodash'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'

import validate from './validateForm'

// utils
import { FORMS } from '../../../utils/enums'
import { formatFormValue } from '../../../utils/form'
import { formatAddress, formatAddressForResponse } from '../../../utils/address'
import { formatDate } from '../../../utils/date'
import { areEquals, formatConfirm } from '../../../utils/suhlas'

import WrapperPodpisovanieDokumentov from '../../PodpisovanieDokumentov/WrapperPodpisovanieDokumentov'

// generic
import ContactPhoneField from '../../GenericUkon/fields/ContactPhoneField'
import ContactEmailField from '../../GenericUkon/fields/ContactEmailField'
import { EDIT_MODE, COLUMNS_COUNT } from '../../../containers/GenericUkon/attributesConfig'

class ObchodnyPartnerEditConfirm extends React.Component {
	static propTypes = {
		...propTypes,
		formTitle: PropTypes.string.isRequired,
		onSubmit: PropTypes.func.isRequired,
		onBackClick: PropTypes.func.isRequired,
		onCancelClick: PropTypes.func.isRequired,
		scenarioOption: PropTypes.shape({
			kanal: PropTypes.number.isRequired,
			scenarios: PropTypes.array.isRequired,
			notificationRequire: PropTypes.bool.isRequired,
			notificationTypes: PropTypes.array.isRequired
		}),
		isDisabledNotification: PropTypes.bool,
		isDisabledSignedPdf: PropTypes.bool
	}

	getKorespondencnaAdresa = (isAdresaKorespondencaChanged, adresaKorespondencna, globalnaZmenaAdresaKorespondencna) => {
		if (!isAdresaKorespondencaChanged && isEmpty(adresaKorespondencna)) {
			return null
		}
		if (isEmpty(adresaKorespondencna)) {
			return null
		}
		return { ...adresaKorespondencna, ...(globalnaZmenaAdresaKorespondencna && { globalnaZmena: globalnaZmenaAdresaKorespondencna }) }
	}

	requireSignatureAndNotification = () => {
		const { originalValues, formValues, addresses } = this.props

		if (
			areEquals(get(originalValues, 'suhlasKontaktnyTelefon'), get(formValues, 'suhlasKontaktnyTelefon')) &&
			areEquals(get(originalValues, 'suhlasKontaktnyEmail'), get(formValues, 'suhlasKontaktnyEmail')) &&
			areEquals(get(originalValues, 'aktualnostTelefon'), get(formValues, 'aktualnostTelefon')) &&
			areEquals(get(originalValues, 'aktualnostEmail'), get(formValues, 'aktualnostEmail')) &&
			areEquals(get(originalValues, 'aktualnostAdresa'), get(formValues, 'aktualnostAdresa')) &&
			areEquals(get(originalValues, 'aktualnostAdresaZakaznika'), get(formValues, 'aktualnostAdresaZakaznika')) &&
			get(formValues, 'adresaKorespondencna') !== null
		) {
			return true
		}

		const omittedProps = [
			'suhlasKontaktnyTelefon',
			'suhlasKontaktnyEmail',
			'aktualnostTelefon',
			'aktualnostEmail',
			'aktualnostAdresa',
			'aktualnostAdresaZakaznika',
			'podpisMiesto',
			'splnomocnenec',
			'ukonVstup',
			'dokumenty',
			'poznamka',
			'datumPrijatiaZiadosti',
			'uplatnitEfaktury',
			'INIT_TIMESTAMP'
		]

		// NOTE: adding based on CP-3207 task
		const isAdresaKorespondencaChanged =
			(isEmpty(get(originalValues, 'adresaKorespondencna', null)) && !get(formValues, 'adresaZakaznika.globalnaZmena')) ||
			get(formValues, 'adresaZakaznika.id') !== get(formValues, 'adresaKorespondencna.id') ||
			get(formValues, 'adresaKorespondencna.globalnaZmena')

		const previousFormValues = omit(originalValues, [...omittedProps, 'adresaKorespondencnaSection', 'adresaKorespondencnaSectionInherit'])
		const previous = {}
		forEach(Object.keys(previousFormValues), (key) => {
			previous[key] = get(originalValues, key, null)
		})
		previous.titulPred1 = get(originalValues, 'titulPred1.hodnota.id', null)
		previous.titulPred2 = get(originalValues, 'titulPred2.hodnota.id', null)
		previous.titulZa = get(originalValues, 'titulZa.hodnota.id', null)

		// CP-2979 - Ak zmením adresné dáta (cez ceruzku), tak zmeny sa nezapíšu do formValues, ale sú v addresses
		const currentFormValues = omit(formValues, [...omittedProps], 'adresaZakaznika', 'adresaKorespondencna')
		const adresaZakaznika = find(addresses, (address) => {
			return get(address, 'id') == get(formValues, 'adresaZakaznika.id')
		})
		const adresaKorespondencna = find(addresses, (address) => {
			return get(address, 'id') == get(formValues, 'adresaKorespondencna.id')
		})

		const globalnaZmenaAdresaZakaznika = get(formValues, 'adresaZakaznika.globalnaZmena')
		const globalnaZmenaAdresaKorespondencna = get(formValues, 'adresaKorespondencna.globalnaZmena')

		const current = {
			// adding globalnaZmena into current addresses
			adresaZakaznika:
				{
					...adresaZakaznika,
					...(globalnaZmenaAdresaZakaznika && { globalnaZmena: globalnaZmenaAdresaZakaznika })
				} ?? null,
			adresaKorespondencna: this.getKorespondencnaAdresa(isAdresaKorespondencaChanged, adresaKorespondencna, globalnaZmenaAdresaKorespondencna)
		}

		forEach(Object.keys(currentFormValues), (key) => {
			current[key] = get(formValues, key, null)
		})

		return !isEqual(previous, current) || get(formValues, 'uplatnitEfaktury')
	}

	render() {
		const {
			handleSubmit,
			formValues,
			originalValues,
			ciselniky,
			addresses,
			t,
			scenarioOption,
			isDisabledNotification,
			isDisabledSignedPdf,
			podpisovanieDokumentov,
			formTitle,
			onBackClick,
			onCancelClick
		} = this.props

		const ukonVstup = find(get(ciselniky, 'ukonVstup', []), (vstup) => {
			return get(vstup, 'id') == get(formValues, 'ukonVstup')
		})
		const titulPred1 = find(get(ciselniky, 'akademickyTitulHodnota', []), (item) => {
			return get(item, 'id') == get(formValues, 'titulPred1')
		})
		const titulPred2 = find(get(ciselniky, 'akademickyTitulHodnota', []), (item) => {
			return get(item, 'id') == get(formValues, 'titulPred2')
		})
		const titulZa = find(get(ciselniky, 'akademickyTitulHodnota', []), (item) => {
			return get(item, 'id') == get(formValues, 'titulZa')
		})

		const adresaZakaznika = find(addresses, (address) => {
			return get(address, 'id') == get(formValues, 'adresaZakaznika.id')
		})
		let adresaKorespondencna = find(addresses, (address) => {
			return get(address, 'id') == get(formValues, 'adresaKorespondencna.id')
		})

		if (adresaKorespondencna === undefined) {
			adresaKorespondencna = formatAddressForResponse('adresaKorespondencna', formValues, addresses)
		}

		let adresaKorespondencnaString = null
		let adresaKorespondencnaDoRuk = null

		if (get(formValues, 'adresaZakaznika.globalnaZmena')) {
			adresaKorespondencnaString = formatFormValue(formatAddress(adresaZakaznika), formatAddress(get(originalValues, 'adresaKorespondencna')))
			adresaKorespondencnaDoRuk = formatFormValue(get(adresaZakaznika, 'doRukMeno', ''), get(originalValues, 'adresaKorespondencna.doRukMeno', ''))
		} else {
			adresaKorespondencnaString = formatFormValue(formatAddress(adresaKorespondencna), formatAddress(get(originalValues, 'adresaKorespondencna')))
			adresaKorespondencnaDoRuk = formatFormValue(get(adresaKorespondencna, 'doRukMeno', ''), get(originalValues, 'adresaKorespondencna.doRukMeno', ''))
		}

		const disabledBtn = (
			<button className='button pull-right' disabled type='submit' data-color='blue' style={{ marginLeft: '20px' }}>
				{t('translation:Common.Dokončiť')}
			</button>
		)

		const requiredSignatureAndNotification = this.requireSignatureAndNotification()

		let submitBtn
		if (!requiredSignatureAndNotification) {
			submitBtn = (
				<button className='button pull-right' type='submit' data-color='blue' style={{ marginLeft: '20px' }}>
					{t('translation:Common.Dokončiť')}
				</button>
			)
		} else if (get(podpisovanieDokumentov, 'templatePdf.isLoading')) {
			submitBtn = (
				<Tooltip
					html={<span>{t('translation:Common.Prebieha načítavanie dokumentu pre podpisovanie')}</span>}
					position='top'
					trigger='mouseenter'
					theme='light'
				>
					{disabledBtn}
				</Tooltip>
			)
		} else if (isDisabledSignedPdf) {
			submitBtn = (
				<Tooltip html={<span>{t('translation:Common.Dokument musí byť podpísaný')}</span>} position='top' trigger='mouseenter' theme='light'>
					{disabledBtn}
				</Tooltip>
			)
		} else if (!isDisabledSignedPdf && isDisabledNotification) {
			submitBtn = (
				<Tooltip
					html={<span>{t('translation:Common.Na dokončenie úkonu je potrebné nastaviť notifikáciu')}</span>}
					position='top'
					trigger='mouseenter'
					theme='light'
				>
					{disabledBtn}
				</Tooltip>
			)
		} else if (!isDisabledNotification && !isDisabledSignedPdf) {
			submitBtn = (
				<button className='button pull-right' type='submit' data-color='blue' style={{ marginLeft: '20px' }}>
					{t('translation:Common.Dokončiť')}
				</button>
			)
		}

		const eFakturyMerged = [...get(formValues, 'eFaktury', [])]
		if (get(formValues, 'uplatnitEfaktury') && get(formValues, 'kontaktnyEmail')) {
			eFakturyMerged.push({ email: get(formValues, 'kontaktnyEmail') })
		}

		const formValuesEFakturaEmaily = map(eFakturyMerged, (eFaktura, index) => {
			return <p key={index}>{get(eFaktura, 'email')}</p>
		})

		const formValuesGlobalnaZmenaEFaktury = get(formValues, 'uplatnitEfaktury') ? t('translation:Common.Áno') : t('translation:Common.Nie')

		const denSplatnostiPreferovany = get(formValues, 'denSplatnostiPreferovany') ? `${get(formValues, 'denSplatnostiPreferovany')}.` : null

		// if notificatoin is required and not selected yet then disable submit
		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					<div className='pull-right'>{submitBtn}</div>
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<button onClick={onCancelClick} type='button' className='button pull-right' data-type='outline' data-color='red'>
						{t('translation:Common.Zrušiť')}
					</button>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<thead>
									<tr>
										<th />
										<th>{t('translation:Common.Aktuálne hodnoty')}</th>
										<th>{t('translation:Common.Nové hodnoty')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Vstup do úkonu')}</strong>
										</td>
										<td>{get(originalValues, 'ukonVstup.nazov')}</td>
										<td>{upperFirst(get(ukonVstup, 'nazov'))}</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Splnomocnenec')}</strong>
										</td>
										<td />
										<td>{formatFormValue(get(formValues, 'splnomocnenec'), null)}</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Miesto podpisu úkonu')}</strong>
										</td>
										<td>{get(originalValues, 'podpisMiesto')}</td>
										<td>{formatFormValue(get(formValues, 'podpisMiesto'), get(originalValues, 'podpisMiesto'))}</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Dátum prijatia žiadosti')}</strong>
										</td>
										<td />
										<td>{formatDate(get(formValues, 'datumPrijatiaZiadosti'), null, 'DD.MM.YYYY')}</td>
									</tr>
								</tbody>
							</table>
							<div className='inner-box'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<tbody>
										<tr>
											<td>
												<strong>{t('translation:Common.Meno')}</strong>
											</td>
											<td>{get(originalValues, 'meno')}</td>
											<td>{formatFormValue(get(formValues, 'meno'), get(originalValues, 'meno'))}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Common.Priezvisko')}</strong>
											</td>
											<td>{get(originalValues, 'priezvisko')}</td>
											<td>{formatFormValue(get(formValues, 'priezvisko'), get(originalValues, 'priezvisko'))}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Common.Titul pred menom')}</strong>
											</td>
											<td>
												<div className='row'>
													<div className='col-6'>{get(originalValues, 'titulPred1.hodnota.nazov')}</div>
													<div className='col-6'>{get(originalValues, 'titulPred2.hodnota.nazov')}</div>
												</div>
											</td>
											<td>
												<div className='row'>
													<div className='col-6'>
														{formatFormValue(
															get(formValues, 'titulPred1'),
															get(originalValues, 'titulPred1.hodnota.id'),
															get(titulPred1, 'nazov')
														)}
													</div>
													<div className='col-6'>
														{formatFormValue(
															get(formValues, 'titulPred2'),
															get(originalValues, 'titulPred2.hodnota.id'),
															get(titulPred2, 'nazov')
														)}
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Common.Titul za menom')}</strong>
											</td>
											<td>{get(originalValues, 'titulZa.hodnota.nazov')}</td>
											<td>
												{formatFormValue(get(formValues, 'titulZa'), get(originalValues, 'titulZa.hodnota.id'), get(titulZa, 'nazov'))}
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Common.Dátum narodenia')}</strong>
											</td>
											<td>{formatDate(get(originalValues, 'narodenieDatum'), null, 'DD.MM.YYYY')}</td>
											<td>
												{formatFormValue(
													formatDate(get(formValues, 'narodenieDatum'), null, 'DD.MM.YYYY'),
													formatDate(get(originalValues, 'narodenieDatum'), null, 'DD.MM.YYYY')
												)}
											</td>
										</tr>
										<ContactPhoneField
											field='kontaktnyTelefon'
											editMode={EDIT_MODE.CONFIRM}
											columnsCount={COLUMNS_COUNT.THREE}
											originalValue={get(originalValues, 'kontaktnyTelefon')}
											value={get(formValues, 'kontaktnyTelefon')}
											originalSuhlas={get(originalValues, 'suhlasKontaktnyTelefon')}
											aktualnostTelefon={get(formValues, 'aktualnostTelefon')}
											originalAktualnostTelefon={get(originalValues, 'aktualnostTelefon')}
											newSuhlas={get(formValues, 'suhlasKontaktnyTelefon')}
										/>
										<ContactEmailField
											field='kontaktnyEmail'
											editMode={EDIT_MODE.CONFIRM}
											columnsCount={COLUMNS_COUNT.THREE}
											originalValue={get(originalValues, 'kontaktnyEmail')}
											originalSuhlas={get(originalValues, 'suhlasKontaktnyEmail')}
											value={get(formValues, 'kontaktnyEmail')}
											aktualnostEmail={get(formValues, 'aktualnostEmail')}
											originalAktualnostEmail={get(originalValues, 'aktualnostEmail')}
											newSuhlas={get(formValues, 'suhlasKontaktnyEmail')}
										/>
										<tr>
											<td>
												<strong>{t('translation:Common.Uplatniť e-faktúru na všetkých ZÚ')}</strong>
											</td>
											<td>{t('translation:Common.Nie')}</td>
											<td>{formatFormValue(formValuesGlobalnaZmenaEFaktury, t('translation:Common.Nie'))}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Common.E-mailové adresy pre e-faktúry')}</strong>
											</td>
											<td />
											<td>
												<strong>{formValuesEFakturaEmaily}</strong>
											</td>
										</tr>
										<tr>
											<td>
												<div>
													<strong>{t('translation:Common.Adresa trvalého pobytu')}</strong>
												</div>
												{(get(originalValues, 'adresaZakaznika.doRukMeno') || get(adresaZakaznika, 'doRukMeno')) && (
													<>
														<br />
														<div>
															<strong>{t('translation:Common.Do rúk')}</strong>
														</div>
													</>
												)}
												{get(formValues, 'adresaZakaznika.globalnaZmena') && (
													<>
														<br />
														<div>
															<strong>{t('translation:Common.Globálne použitie adresy')}</strong>
														</div>
													</>
												)}
											</td>
											<td>
												<div>{formatAddress(get(originalValues, 'adresaZakaznika'))}</div>
												{(get(originalValues, 'adresaZakaznika.doRukMeno') || get(adresaZakaznika, 'doRukMeno')) && (
													<>
														<br />
														<div>{get(originalValues, 'adresaZakaznika.doRukMeno')}&nbsp;</div>
													</>
												)}
												{get(formValues, 'adresaZakaznika.globalnaZmena') && (
													<>
														<br />
														<div>&nbsp;</div>
													</>
												)}
											</td>
											<td>
												<div>
													{formatFormValue(formatAddress(adresaZakaznika), formatAddress(get(originalValues, 'adresaZakaznika')))}
												</div>
												<div>
													{formatConfirm(
														get(formValues, 'aktualnostAdresaZakaznika'),
														get(originalValues, 'aktualnostAdresaZakaznika')
													)}
												</div>
												{(get(adresaZakaznika, 'doRukMeno') || get(originalValues, 'adresaZakaznika.doRukMeno')) && (
													<>
														<br />
														<div>
															{formatFormValue(
																get(adresaZakaznika, 'doRukMeno'),
																get(originalValues, 'adresaZakaznika.doRukMeno')
															)}
															&nbsp;
														</div>
													</>
												)}
												{get(formValues, 'adresaZakaznika.globalnaZmena') && (
													<>
														<br />
														<div>
															<strong>{t('translation:Common.Áno')}</strong>
														</div>
													</>
												)}
											</td>
										</tr>
										<tr>
											<td>
												<div>
													<strong>{t('translation:Common.Korešpondenčná adresa')}</strong>
												</div>
												{(adresaKorespondencnaDoRuk || get(originalValues, 'adresaKorespondencna.doRukMeno')) && (
													<>
														<br />
														<div>
															<strong>{t('translation:Common.Do rúk')}</strong>
														</div>
													</>
												)}
												{get(formValues, 'adresaKorespondencna.globalnaZmena') && (
													<>
														<br />
														<div>
															<strong>{t('translation:Common.Globálne použitie adresy')}</strong>
														</div>
													</>
												)}
											</td>
											<td>
												<div style={{ position: 'relative', paddingRight: '20px' }}>
													{get(originalValues, 'adresaKorespondencnaSection')}
													{get(originalValues, 'adresaKorespondencnaSectionInherit') && (
														<Tooltip
															html={
																<>
																	<span>{t('translation:Common.Odvodená adresa z')}</span>
																	<br />
																	<span>
																		<strong>{get(originalValues, 'adresaKorespondencnaSectionInherit')}</strong>
																	</span>
																</>
															}
															position='bottom'
															trigger='mouseenter'
															theme='light'
														>
															<div className='info-icon' />
														</Tooltip>
													)}
												</div>
												{(adresaKorespondencnaDoRuk || get(originalValues, 'adresaKorespondencna.doRukMeno')) && (
													<>
														<br />
														<div>{get(originalValues, 'adresaKorespondencna.doRukMeno')}&nbsp;</div>
													</>
												)}
												{get(formValues, 'adresaKorespondencna.globalnaZmena') && (
													<>
														<br />
														<div>&nbsp;</div>
													</>
												)}
											</td>
											<td>
												<div>{adresaKorespondencnaString}</div>
												<div>{formatConfirm(get(formValues, 'aktualnostAdresa'), get(originalValues, 'aktualnostAdresa'))}</div>
												{(adresaKorespondencnaDoRuk || get(originalValues, 'adresaKorespondencna.doRukMeno')) && (
													<>
														<br />
														<div>{adresaKorespondencnaDoRuk}&nbsp;</div>
													</>
												)}
												{get(formValues, 'adresaKorespondencna.globalnaZmena') && (
													<>
														<br />
														<strong>{t('translation:Common.Áno')}</strong>
													</>
												)}
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Common.Deň splatnosti preferovaný')}</strong>
											</td>
											<td>{get(originalValues, 'denSplatnostiPreferovany')}</td>
											<td>
												{formatFormValue(
													get(formValues, 'denSplatnostiPreferovany'),
													get(originalValues, 'denSplatnostiPreferovany'),
													denSplatnostiPreferovany
												)}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Poznámka')}</strong>
										</td>
										<td />
										<td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
											<div className='input-wrapper'>
												<textarea
													disabled='disabled'
													rows='5'
													className='form-control static'
													style={{ backgroundColor: 'white' }}
													value={get(formValues, 'poznamka', '')}
												/>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							{requiredSignatureAndNotification && <WrapperPodpisovanieDokumentov scenarioOption={scenarioOption} />}
							{!requiredSignatureAndNotification && (
								<div className='inner-box'>
									<strong>{t('translation:Common.Validácia údajov')}</strong>
									<div className='message-contact-fields-validation'>
										<p>
											{t(
												'translation:Common.Validácia kontaktných údajov Úkon nebude generovať žiadne notifikácie klientovi Pre pokračovanie stlačte tlačidlo'
											)}
											<strong> {t('translation:Common.Dokončiť')}</strong>
										</p>
										{submitBtn}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.OBCHODNY_PARTNER_EDITACIA,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	validate
})(ObchodnyPartnerEditConfirm)

const mapStateToProps = (state) => {
	return {
		podpisovanieDokumentov: state.podpisovanieDokumentov
	}
}

export default compose(withTranslation('components'), connect(mapStateToProps))(form)
