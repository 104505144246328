import dayjs from 'dayjs'
import { get, isEmpty, some } from 'lodash'

import { isEmail } from '../../../utils/email'

import { BUSINESS_CHANNELS } from '../../../utils/enums'

const validate = (values, props) => {
	const { t } = props
	const errors = {}

	if (!values.ukonVstup) {
		errors.ukonVstup = t('translation:Common.validate.Vstup do úkonu je povinný')
	}

	if (values.podpisMiesto) {
		if (values.podpisMiesto.length > 100) {
			errors.podpisMiesto = t('translation:Common.validate.Miesto podpisu úkonu môže mať najviac 100 znakov')
		}
	}

	if (!values.oslovenie) {
		errors.oslovenie = props.t('translation:ZalozenieOP.validate.Oslovenie je povinné')
	}
	if (!values.meno) {
		errors.meno = props.t('translation:ZalozenieOP.validate.Meno je povinné')
	} else if (values.meno.length > 40) {
		errors.meno = props.t('translation:ZalozenieOP.validate.Meno môže mať najviac 40 znakov')
	}
	if (!values.priezvisko) {
		errors.priezvisko = props.t('translation:ZalozenieOP.validate.Priezvisko je povinné')
	} else if (values.priezvisko.length > 40) {
		errors.priezvisko = props.t('translation:ZalozenieOP.validate.Priezvisko môže mať najviac 40 znakov')
	}

	if (!values.narodenieDatum) {
		errors.narodenieDatum = props.t('translation:ZalozenieOP.validate.Dátum narodenia je povinný')
	} else if (values.narodenieDatum < dayjs().add(-120, 'years').add(-1, 'day') || values.narodenieDatum > dayjs().add(-18, 'years')) {
		errors.narodenieDatum = props.t('translation:ZalozenieOP.validate.Dátum narodenia nie je v povolenom rozsahu')
	}

	if (values.telefonneCislo) {
		// check if phone number has slovak international format
		const isSK = new RegExp(/^\+421/, 'g')
		if (isSK.test(values.telefonneCislo)) {
			// check slovak international format phone
			const regex = new RegExp(/^\+421 ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/, 'g')
			const result = regex.test(values.telefonneCislo)
			if (!result) {
				errors.telefonneCislo = props.t('translation:ZalozenieOP.validate.Nesprávny formát telefónneho čisla, použite formát v tvare +421 XXX XXX XXX')
			}
		} else {
			// check if string has allow char 0-9 + [space]
			const regexFormat = new RegExp(/^\+[1-9]{1}[0-9\s]{1,14}$/, 'g')
			const regexWhitespaces = new RegExp(/\s{2,}/, 'g')
			const result = regexFormat.test(values.telefonneCislo) && !regexWhitespaces.test(values.telefonneCislo)
			if (!result) {
				errors.telefonneCislo = props.t('translation:ZalozenieOP.validate.Nesprávny formát telefónneho čisla, použite formát v tvare +421 XXX XXX XXX')
			}
		}
	}

	if (values.kontaktnyEmail) {
		if (!isEmail(values.kontaktnyEmail)) {
			errors.kontaktnyEmail = props.t('translation:ZalozenieOP.validate.Emailová adresa je neplatná')
		} else if (values.kontaktnyEmail.length > 2048) {
			errors.kontaktnyEmail = props.t('translation:ZalozenieOP.validate.Email môže mať najviac 2048 znakov')
		}
	}

	if (!get(values, 'adresaZakaznika.id')) {
		errors.adresaZakaznika = { id: props.t('translation:Common.validate.Adresa je povinná') }
	}

	if (!get(values, 'adresaKorespondencna.id')) {
		errors.adresaKorespondencna = { id: props.t('translation:Common.validate.Adresa je povinná') }
	}

	if (get(props, 'auth.businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE && isEmpty(values.dokumenty)) {
		errors.dokumenty = t('translation:Common.validate.Dokumenty pre BO kanál sú povinné')
	}

	if (get(props, 'auth.businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE && !values.datumPrijatiaZiadosti) {
		errors.datumPrijatiaZiadosti = t('translation:Common.validate.Dátum prijatia žiadosti je povinný')
	}

	if (values.datumPrijatiaZiadosti) {
		if (
			get(props, 'auth.businessChannel.actual.id') == BUSINESS_CHANNELS.ZSE_CENTRUM ||
			get(props, 'auth.businessChannel.actual.id') == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA
		) {
			if (values.datumPrijatiaZiadosti < dayjs().startOf('day') || values.datumPrijatiaZiadosti > dayjs().endOf('day')) {
				errors.datumPrijatiaZiadosti = t('translation:Common.validate.Dátum prijatia žiadosti nie je platný - zvoľte prosím aktuálny deň')
			}
		} else if (get(props, 'auth.businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE) {
			if (values.datumPrijatiaZiadosti > dayjs().endOf('day')) {
				errors.datumPrijatiaZiadosti = t('translation:Common.validate.Dátum prijatia žiadosti nie je platný - nemôžete zvoliť dátum v budúcnosti')
			}
		}
	}

	if (!isEmpty(values.dokumenty)) {
		some(values.dokumenty, (dokument) => {
			if (!dokument.dataAsBase64) {
				errors.dokumenty = t('translation:Common.validate.Neplatný dokument', {
					nazovDokumentu: dokument.name
				})
				return true
			}
		})
	}

	if (values.poznamka && values.poznamka.length > 2000) {
		errors.poznamka = props.t('translation:Common.validate.Maximálna dĺžka poznámky je 2000 znakov')
	}

	return errors
}

export default validate
