import {
	OBCHODNY_PARTNER_LOAD_START,
	OBCHODNY_PARTNER_LOAD_FAIL,
	OBCHODNY_PARTNER_LOAD_DONE,
	OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_DONE,
	OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_FAIL,
	OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_START,
	OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_PART_DONE,
	OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_DONE,
	OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_FAIL,
	OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_START,
	OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_DONE,
	OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_FAIL,
	OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_START,
	OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_PART_DONE,
	OBCHODNY_PARTNER_ZMLUVNE_UCTY_FILTER_CHANGED,
	OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_PART_DONE,
	OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_DONE,
	OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_FAIL,
	OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_START,
	OBCHODNY_PARTNER_ZMLUVNY_UCET_LOAD_START,
	OBCHODNY_PARTNER_ZMLUVNY_UCET_LOAD_DONE,
	OBCHODNY_PARTNER_ZMLUVNY_UCET_LOAD_FAIL,
	OBCHODNY_PARTNER_SPRAVA_SUHLASOV_LOAD_START,
	OBCHODNY_PARTNER_SPRAVA_SUHLASOV_LOAD_DONE,
	OBCHODNY_PARTNER_SPRAVA_SUHLASOV_LOAD_FAIL,
	OBCHODNY_PARTNER_HISTORIA_SUHLASU_LOAD_START,
	OBCHODNY_PARTNER_HISTORIA_SUHLASU_LOAD_DONE,
	OBCHODNY_PARTNER_HISTORIA_SUHLASU_LOAD_FAIL,
	OBCHODNY_PARTNER_ROZPIS_ZALOH_LOAD_DONE,
	OBCHODNY_PARTNER_ROZPIS_ZALOH_LOAD_START,
	OBCHODNY_PARTNER_ROZPIS_ZALOH_LOAD_FAIL,
	OBCHODNY_PARTNER_ODBERNE_MIESTA_LOAD_START,
	OBCHODNY_PARTNER_ODBERNE_MIESTA_LOAD_DONE,
	OBCHODNY_PARTNER_ODBERNE_MIESTA_LOAD_FAIL,
	OBCHODNY_PARTNER_POHLADAVKY_LOAD_DONE,
	OBCHODNY_PARTNER_POHLADAVKY_LOAD_FAIL,
	OBCHODNY_PARTNER_POHLADAVKY_LOAD_START,
	OBCHODNY_PARTNER_POHLADAVKY_ZOSTATOK_LOAD_DONE,
	OBCHODNY_PARTNER_POHLADAVKY_ZOSTATOK_LOAD_FAIL,
	OBCHODNY_PARTNER_POHLADAVKY_ZOSTATOK_LOAD_START,
	OBCHODNY_PARTNER_POHLADAVKY_DETAIL_LOAD_START,
	OBCHODNY_PARTNER_POHLADAVKY_DETAIL_LOAD_DONE,
	OBCHODNY_PARTNER_POHLADAVKY_DETAIL_LOAD_FAIL,
	OBCHODNY_PARTNER_POHLADAVKY_HIGHLIGHT,
	OBCHODNY_PARTNER_VZTAHY_LOAD_START,
	OBCHODNY_PARTNER_VZTAHY_LOAD_DONE,
	OBCHODNY_PARTNER_VZTAHY_LOAD_FAIL,
	OBCHODNY_PARTNER_VZTAH_DETAIL_LOAD_START,
	OBCHODNY_PARTNER_VZTAH_DETAIL_LOAD_DONE,
	OBCHODNY_PARTNER_VZTAH_DETAIL_LOAD_FAIL,
	OBCHODNY_PARTNER_BANKOVE_UCTY_LOAD_START,
	OBCHODNY_PARTNER_BANKOVE_UCTY_LOAD_FAIL,
	OBCHODNY_PARTNER_BANKOVE_UCTY_LOAD_DONE,
	OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_DONE,
	OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_FAIL,
	OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_START,
	OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_PART_DONE,
	OBCHODNY_PARTNER_BANKOVE_UCTY_DETAIL_LOAD_DONE,
	OBCHODNY_PARTNER_BANKOVE_UCTY_DETAIL_LOAD_FAIL,
	OBCHODNY_PARTNER_BANKOVE_UCTY_DETAIL_LOAD_START,
	OBCHODNY_PARTNER_UPOMIENKY_LOAD_DONE,
	OBCHODNY_PARTNER_UPOMIENKY_LOAD_FAIL,
	OBCHODNY_PARTNER_UPOMIENKY_LOAD_START,
	OBCHODNY_PARTNER_PODRADENE_SUHLASY_LOAD_DONE,
	OBCHODNY_PARTNER_PODRADENE_SUHLASY_LOAD_START,
	OBCHODNY_PARTNER_PODRADENE_SUHLASY_LOAD_FAIL,
	OBCHODNY_PARTNER_HLAVNY_ZOZNAM_SUHLASOV_LOAD_DONE,
	OBCHODNY_PARTNER_HLAVNY_ZOZNAM_SUHLASOV_LOAD_FAIL,
	OBCHODNY_PARTNER_HLAVNY_ZOZNAM_SUHLASOV_LOAD_START,
	OBCHODNY_PARTNER_AKTUALNA_VERZIA_SUHLASU_LOAD_DONE,
	OBCHODNY_PARTNER_AKTUALNA_VERZIA_SUHLASU_LOAD_FAIL,
	OBCHODNY_PARTNER_AKTUALNA_VERZIA_SUHLASU_LOAD_START,
	OBCHODNY_PARTNER_MARKETINGOVE_ATRIBUTY_LOAD_DONE,
	OBCHODNY_PARTNER_MARKETINGOVE_ATRIBUTY_LOAD_START,
	OBCHODNY_PARTNER_MARKETINGOVE_ATRIBUTY_LOAD_FAIL,
	OBCHODNY_PARTNER_REGULACIE_LOAD_DONE,
	OBCHODNY_PARTNER_REGULACIE_LOAD_FAIL,
	OBCHODNY_PARTNER_REGULACIE_LOAD_START,
	OBCHODNY_PARTNER_DIGITALIZACIA_LOAD_DONE,
	OBCHODNY_PARTNER_DIGITALIZACIA_LOAD_FAIL,
	OBCHODNY_PARTNER_DIGITALIZACIA_LOAD_START
} from '../types/obchodnyPartner'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	detail: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	zmluvneUcty: {
		data: [],
		isLoading: false,
		isPrepared: false,
		lastLoad: new Date(0),
		isFailure: false,
		allData: [],
		allDataLoading: false,
		allDataPrepared: false
	},
	rozpisyZaloh: {
		data: [],
		isLoading: false,
		isPrepared: false,
		page: 1,
		lastLoad: new Date(0),
		isFailure: false,
		isLastPage: false
	},
	rozpisZalohDetail: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	suhlasy: {
		data: [],
		isLoading: false,
		isPrepared: false,
		lastLoad: new Date(0),
		isFailure: false
	},
	historiaSuhlasu: {
		data: [],
		isLoading: false,
		isPrepared: false,
		lastLoad: new Date(0),
		isFailure: false
	},
	podradeneSuhlasy: {
		data: [],
		isLoading: false,
		isPrepared: false,
		lastLoad: new Date(0),
		isFailure: false
	},
	zmluvnyUcetDetail: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	odberneMiesta: {
		page: 1,
		data: [],
		isLoading: false,
		isFailure: false,
		lastLoad: new Date(0),
		allData: null,
		allDataLoading: false,
		allDataPrepared: false
	},
	pohladavky: {
		data: [],
		isLoading: false,
		page: 1,
		lastLoad: new Date(0),
		isFailure: false,
		isLastPage: false
	},
	pohladavkaDetail: {
		data: [],
		filtered: [],
		idPohladavky: null,
		isLoading: false,
		isFailure: false
	},
	pohladavkyZostatok: {
		data: null,
		isFailure: false,
		isLoading: false
	},
	splnomocnenia: {
		data: [],
		page: 1,
		isLoading: false,
		isFailure: false,
		lastLoad: new Date(0),
		isLastPage: false
	},
	vztahDetail: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	bankoveUcty: {
		data: [],
		isLoading: false,
		page: 1,
		lastLoad: new Date(0),
		isFailure: false,
		isLastPage: false,
		allData: [],
		allDataLoading: false,
		allDataPrepared: false
	},
	bankoveUctyDetail: {
		data: null,
		isFailure: false,
		isLoading: false
	},
	upomienky: {
		data: [],
		page: 1,
		isLastPage: false,
		isLoading: false,
		isFailure: false,
		lastLoad: new Date(0)
	},
	hlavnyZoznamSuhlasov: {
		data: [],
		isFailure: false,
		isLoading: false
	},
	aktualnaVerziaSuhlasu: {
		data: null,
		isFailure: false,
		isLoading: false
	},
	marketingoveAtributy: {
		data: [],
		page: 1,
		isLoading: false,
		isFailure: false,
		lastLoad: new Date(0),
		hasNextPage: false
	},
	regulacie: {
		data: [],
		page: 1,
		isLoading: false,
		isFailure: false,
		lastLoad: new Date(0),
		hasNextPage: false
	},
	digitalizacia: {
		data: null,
		isFailure: false,
		isLoading: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case OBCHODNY_PARTNER_LOAD_START:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					data: null,
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_LOAD_DONE:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					data: action.payload.partner,
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_LOAD_FAIL:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					data: null,
					isFailure: true
				}
			}
		case OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_START:
			return {
				...state,
				zmluvneUcty: {
					...state.zmluvneUcty,
					data: [],
					isLoading: true,
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_DONE:
			return {
				...state,
				zmluvneUcty: {
					...state.zmluvneUcty,
					isLoading: false,
					isPrepared: true,
					lastLoad: new Date(),
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_FAIL:
			return {
				...state,
				zmluvneUcty: {
					...state.zmluvneUcty,
					isFailure: true,
					isLoading: false,
					data: [],
					lastLoad: new Date(0)
				}
			}
		case OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_PART_DONE:
			return {
				...state,
				zmluvneUcty: {
					...state.zmluvneUcty,
					isFailure: false,
					data: state.zmluvneUcty.data.concat(action.payload.zmluvneUcty)
				}
			}
		case OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_START:
			return {
				...state,
				zmluvneUcty: {
					...state.zmluvneUcty,
					allData: [],
					allDataPrepared: false,
					allDataLoading: true
				}
			}
		case OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_FAIL:
			return {
				...state,
				zmluvneUcty: {
					...state.zmluvneUcty,
					allData: [],
					allDataPrepared: false,
					allDataLoading: false
				}
			}
		case OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_PART_DONE:
			return {
				...state,
				zmluvneUcty: {
					...state.zmluvneUcty,
					allData: state.zmluvneUcty.allData.concat(action.payload.zmluvneUcty)
				}
			}
		case OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_DONE:
			return {
				...state,
				zmluvneUcty: {
					...state.zmluvneUcty,
					allDataPrepared: true,
					allDataLoading: false
				}
			}
		case OBCHODNY_PARTNER_SPRAVA_SUHLASOV_LOAD_START:
			return {
				...state,
				suhlasy: {
					...state.suhlasy,
					isLoading: true,
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_SPRAVA_SUHLASOV_LOAD_FAIL:
			return {
				...state,
				suhlasy: {
					...initState.suhlasy,
					isFailure: true
				}
			}
		case OBCHODNY_PARTNER_SPRAVA_SUHLASOV_LOAD_DONE:
			return {
				...state,
				suhlasy: {
					...state.suhlasy,
					data: action.payload.suhlasy,
					isLoading: false,
					isPrepared: true,
					lastLoad: new Date(),
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_PODRADENE_SUHLASY_LOAD_START:
			return {
				...state,
				podradeneSuhlasy: {
					...state.podradeneSuhlasy,
					isLoading: true,
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_PODRADENE_SUHLASY_LOAD_FAIL:
			return {
				...state,
				podradeneSuhlasy: {
					...initState.podradeneSuhlasy,
					isFailure: false,
					isLoading: true
				}
			}
		case OBCHODNY_PARTNER_PODRADENE_SUHLASY_LOAD_DONE:
			return {
				...state,
				podradeneSuhlasy: {
					...state.podradeneSuhlasy,
					data: action.payload.podradeneSuhlasy,
					isLoading: false,
					isPrepared: true,
					lastLoad: new Date(),
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_HISTORIA_SUHLASU_LOAD_START:
			return {
				...state,
				historiaSuhlasu: {
					...state.historiaSuhlasu,
					isLoading: true,
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_HISTORIA_SUHLASU_LOAD_FAIL:
			return {
				...state,
				historiaSuhlasu: {
					...initState.historiaSuhlasu,
					isFailure: true,
					isLoading: false
				}
			}
		case OBCHODNY_PARTNER_HISTORIA_SUHLASU_LOAD_DONE:
			return {
				...state,
				historiaSuhlasu: {
					...state.historiaSuhlasu,
					data: action.payload.historiaSuhlasu,
					isLoading: false,
					isPrepared: true,
					lastLoad: new Date(),
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_START:
			return {
				...state,
				rozpisyZaloh: {
					...state.rozpisyZaloh,
					page: action.payload.page,
					isLoading: true,
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_FAIL:
			return {
				...state,
				rozpisyZaloh: {
					...initState.rozpisyZaloh,
					isFailure: true
				}
			}
		case OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_DONE:
			return {
				...state,
				rozpisyZaloh: {
					...state.rozpisyZaloh,
					data: state.rozpisyZaloh.page === 1 ? action.payload.rozpisyZaloh : state.rozpisyZaloh.data.concat(action.payload.rozpisyZaloh),
					isLoading: false,
					isPrepared: true,
					lastLoad: new Date(),
					isFailure: false,
					isLastPage: action.payload.isLastPage
				}
			}
		case OBCHODNY_PARTNER_ROZPIS_ZALOH_LOAD_START:
			return {
				...state,
				rozpisZalohDetail: {
					...initState.rozpisZalohDetail,
					isLoading: true
				}
			}
		case OBCHODNY_PARTNER_ROZPIS_ZALOH_LOAD_FAIL:
			return {
				...state,
				rozpisZalohDetail: {
					...initState.rozpisZalohDetail,
					isFailure: true
				}
			}
		case OBCHODNY_PARTNER_ROZPIS_ZALOH_LOAD_DONE:
			return {
				...state,
				rozpisZalohDetail: {
					...initState.rozpisZalohDetail,
					data: action.payload.rozpisZaloh
				}
			}
		case OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_DONE:
			return {
				...state,
				odberneMiesta: {
					...state.odberneMiesta,
					allDataPrepared: true,
					allDataLoading: false,
					lastLoad: new Date()
				}
			}
		case OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_FAIL:
			return {
				...state,
				odberneMiesta: {
					...state.odberneMiesta,
					allData: null,
					allDataPrepared: false,
					allDataLoading: false
				}
			}
		case OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_START:
			return {
				...state,
				odberneMiesta: {
					...state.odberneMiesta,
					allData: null,
					allDataPrepared: false,
					allDataLoading: true
				}
			}
		case OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_PART_DONE:
			return {
				...state,
				odberneMiesta: {
					...state.odberneMiesta,
					allData: action.payload.odberneMiesta
				}
			}
		case OBCHODNY_PARTNER_ZMLUVNE_UCTY_FILTER_CHANGED:
			return {
				...state,
				zmluvneUcty: {
					...state.zmluvneUcty,
					selectedFilters: action.payload.selectedFilters
				}
			}
		case OBCHODNY_PARTNER_ZMLUVNY_UCET_LOAD_START:
			return {
				...state,
				zmluvnyUcetDetail: {
					...state.zmluvnyUcetDetail,
					data: null,
					isFailure: false,
					isLoading: true
				}
			}
		case OBCHODNY_PARTNER_ZMLUVNY_UCET_LOAD_DONE:
			return {
				...state,
				zmluvnyUcetDetail: {
					...state.zmluvnyUcetDetail,
					data: {
						odlisnyOp: action.payload.odlisnyOp,
						zmluvnyUcet: action.payload.zmluvnyUcet
					},
					isFailure: false,
					isLoading: false
				}
			}
		case OBCHODNY_PARTNER_ZMLUVNY_UCET_LOAD_FAIL:
			return {
				...state,
				zmluvnyUcetDetail: {
					...state.zmluvnyUcetDetail,
					data: null,
					isFailure: true,
					isLoading: false
				}
			}
		case OBCHODNY_PARTNER_ODBERNE_MIESTA_LOAD_START:
			return {
				...state,
				odberneMiesta: {
					...state.odberneMiesta,
					page: action.payload.page,
					data: null,
					isLoading: true,
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_ODBERNE_MIESTA_LOAD_DONE:
			return {
				...state,
				odberneMiesta: {
					...state.odberneMiesta,
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_ODBERNE_MIESTA_LOAD_FAIL:
			return {
				...state,
				odberneMiesta: {
					...state.odberneMiesta,
					data: null,
					isLoading: false,
					isFailure: true
				}
			}
		case OBCHODNY_PARTNER_POHLADAVKY_LOAD_DONE:
			return {
				...state,
				pohladavky: {
					...state.pohladavky,
					data: action.payload.pohladavky,
					isFailure: false,
					isLoading: false,
					lastLoad: new Date(),
					isLastPage: action.payload.isLastPage
				}
			}
		case OBCHODNY_PARTNER_POHLADAVKY_LOAD_FAIL:
			return {
				...state,
				pohladavky: {
					...initState.pohladavky,
					isFailure: true
				}
			}
		case OBCHODNY_PARTNER_POHLADAVKY_LOAD_START:
			return {
				...state,
				pohladavky: {
					...state.pohladavky,
					page: action.payload.page,
					isLoading: true
				}
			}
		case OBCHODNY_PARTNER_POHLADAVKY_ZOSTATOK_LOAD_DONE:
			return {
				...state,
				pohladavkyZostatok: {
					...state.pohladavkyZostatok,
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_POHLADAVKY_ZOSTATOK_LOAD_FAIL:
			return {
				...state,
				pohladavkyZostatok: {
					...state.pohladavkyZostatok,
					data: null,
					isFailure: true,
					isLoading: false
				}
			}
		case OBCHODNY_PARTNER_POHLADAVKY_ZOSTATOK_LOAD_START:
			return {
				...state,
				pohladavkyZostatok: {
					...state.pohladavkyZostatok,
					data: null,
					isFailure: false,
					isLoading: true
				}
			}
		case OBCHODNY_PARTNER_POHLADAVKY_DETAIL_LOAD_DONE:
			return {
				...state,
				pohladavkaDetail: {
					...state.pohladavkaDetail,
					data: action.payload.data,
					filtered: action.payload.filtered,
					idPohladavky: action.payload.idPohladavky,
					isLoading: false,
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_POHLADAVKY_DETAIL_LOAD_FAIL:
			return {
				...state,
				pohladavkaDetail: {
					...state.pohladavkaDetail,
					data: [],
					filtered: [],
					idPohladavky: null,
					isFailure: true,
					isLoading: false
				}
			}
		case OBCHODNY_PARTNER_POHLADAVKY_DETAIL_LOAD_START:
			return {
				...state,
				pohladavkaDetail: {
					...state.pohladavkaDetail,
					data: [],
					filtered: [],
					idPohladavky: null,
					isFailure: false,
					isLoading: true
				}
			}
		case OBCHODNY_PARTNER_VZTAHY_LOAD_START:
			return {
				...state,
				splnomocnenia: {
					...state.splnomocnenia,
					page: action.payload.page,
					isLoading: true
				}
			}
		case OBCHODNY_PARTNER_VZTAHY_LOAD_DONE:
			return {
				...state,
				splnomocnenia: {
					...state.splnomocnenia,
					data: action.payload.splnomocnenia,
					lastLoad: action.payload.lastLoad,
					isLastPage: action.payload.isLastPage,
					isLoading: false,
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_VZTAHY_LOAD_FAIL:
			return {
				...state,
				splnomocnenia: {
					...initState.splnomocnenia,
					isFailure: true
				}
			}
		case OBCHODNY_PARTNER_VZTAH_DETAIL_LOAD_START:
			return {
				...state,
				vztahDetail: {
					...state.vztahDetail,
					isLoading: true,
					data: null,
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_VZTAH_DETAIL_LOAD_DONE:
			return {
				...state,
				vztahDetail: {
					...state.vztahDetail,
					isLoading: false,
					data: action.payload.vztahDetail,
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_VZTAH_DETAIL_LOAD_FAIL:
			return {
				...state,
				vztahDetail: {
					...state.vztahDetail,
					isLoading: false,
					data: null,
					isFailure: true
				}
			}
		case OBCHODNY_PARTNER_BANKOVE_UCTY_LOAD_START:
			return {
				...state,
				bankoveUcty: {
					...state.bankoveUcty,
					page: action.payload.page,
					isLoading: true,
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_BANKOVE_UCTY_LOAD_FAIL:
			return {
				...state,
				bankoveUcty: {
					...state.bankoveUcty,
					data: [],
					page: 0,
					isLoading: false,
					isFailure: true
				}
			}
		case OBCHODNY_PARTNER_BANKOVE_UCTY_LOAD_DONE:
			return {
				...state,
				bankoveUcty: {
					...state.bankoveUcty,
					data: action.payload.bankoveUcty,
					isLastPage: action.payload.isLastPage,
					lastLoad: action.payload.lastLoad,
					isLoading: false
				}
			}
		case OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_DONE:
			return {
				...state,
				bankoveUcty: {
					...state.bankoveUcty,
					allDataPrepared: true,
					allDataLoading: false,
					lastLoad: new Date()
				}
			}
		case OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_FAIL:
			return {
				...state,
				bankoveUcty: {
					...state.bankoveUcty,
					allData: [],
					allDataPrepared: false,
					allDataLoading: false,
					lastLoad: new Date(0)
				}
			}
		case OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_START:
			return {
				...state,
				bankoveUcty: {
					...state.bankoveUcty,
					allData: [],
					allDataPrepared: false,
					allDataLoading: true
				}
			}
		case OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_PART_DONE:
			return {
				...state,
				bankoveUcty: {
					...state.bankoveUcty,
					allData: [...state.bankoveUcty.allData, ...action.payload.bankoveUctyPart]
				}
			}
		case OBCHODNY_PARTNER_POHLADAVKY_HIGHLIGHT:
			return {
				...state,
				pohladavky: {
					...state.pohladavky,
					data: action.payload.pohladavky
				},
				pohladavkaDetail: {
					...state.pohladavkaDetail,
					filtered: action.payload.pohladavkaDetail
				}
			}
		case OBCHODNY_PARTNER_BANKOVE_UCTY_DETAIL_LOAD_DONE:
			return {
				...state,
				bankoveUctyDetail: {
					...initState.bankoveUctyDetail,
					data: action.payload.bankoveUctyDetail
				}
			}
		case OBCHODNY_PARTNER_BANKOVE_UCTY_DETAIL_LOAD_FAIL:
			return {
				...state,
				bankoveUctyDetail: {
					...initState.bankoveUctyDetail,
					isFailure: true
				}
			}
		case OBCHODNY_PARTNER_BANKOVE_UCTY_DETAIL_LOAD_START:
			return {
				...state,
				bankoveUctyDetail: {
					...initState.bankoveUctyDetail,
					isLoading: true
				}
			}
		case OBCHODNY_PARTNER_UPOMIENKY_LOAD_DONE:
			return {
				...state,
				upomienky: {
					...state.upomienky,
					isLoading: false,
					isFailure: false,
					lastLoad: new Date(),
					data: action.payload.upomienky,
					isLastPage: action.payload.isLastPage
				}
			}
		case OBCHODNY_PARTNER_UPOMIENKY_LOAD_FAIL:
			return {
				...state,
				upomienky: {
					...state.upomienky,
					isFailure: true,
					isLoading: false
				}
			}
		case OBCHODNY_PARTNER_UPOMIENKY_LOAD_START:
			return {
				...state,
				upomienky: {
					...state.upomienky,
					page: action.payload.page,
					isFailure: false,
					isLoading: true
				}
			}
		case OBCHODNY_PARTNER_HLAVNY_ZOZNAM_SUHLASOV_LOAD_DONE:
			return {
				...state,
				hlavnyZoznamSuhlasov: {
					...initState.hlavnyZoznamSuhlasov,
					data: action.payload.zoznamHlavnychSuhlasov
				}
			}
		case OBCHODNY_PARTNER_HLAVNY_ZOZNAM_SUHLASOV_LOAD_FAIL:
			return {
				...state,
				hlavnyZoznamSuhlasov: {
					...initState.hlavnyZoznamSuhlasov,
					isFailure: true
				}
			}
		case OBCHODNY_PARTNER_HLAVNY_ZOZNAM_SUHLASOV_LOAD_START:
			return {
				...state,
				hlavnyZoznamSuhlasov: {
					...initState.hlavnyZoznamSuhlasov,
					isLoading: true
				}
			}
		case OBCHODNY_PARTNER_AKTUALNA_VERZIA_SUHLASU_LOAD_DONE:
			return {
				...state,
				aktualnaVerziaSuhlasu: {
					...initState.aktualnaVerziaSuhlasu,
					data: action.payload.aktualnaVerziaSuhlasu
				}
			}
		case OBCHODNY_PARTNER_AKTUALNA_VERZIA_SUHLASU_LOAD_FAIL:
			return {
				...state,
				aktualnaVerziaSuhlasu: {
					...initState.aktualnaVerziaSuhlasu,
					isFailure: true
				}
			}
		case OBCHODNY_PARTNER_AKTUALNA_VERZIA_SUHLASU_LOAD_START:
			return {
				...state,
				aktualnaVerziaSuhlasu: {
					...initState.aktualnaVerziaSuhlasu,
					isLoading: true
				}
			}
		case OBCHODNY_PARTNER_MARKETINGOVE_ATRIBUTY_LOAD_START:
			return {
				...state,
				marketingoveAtributy: {
					...state.marketingoveAtributy,
					page: action.payload.page,
					isLoading: true
				}
			}
		case OBCHODNY_PARTNER_MARKETINGOVE_ATRIBUTY_LOAD_DONE:
			return {
				...state,
				marketingoveAtributy: {
					...state.marketingoveAtributy,
					data:
						state.marketingoveAtributy.page === 1
							? action.payload.marketingoveAtributy
							: state.marketingoveAtributy.data.concat(action.payload.marketingoveAtributy),
					lastLoad: action.payload.lastLoad,
					page: action.payload.page,
					hasNextPage: action.payload.hasNextPage,
					isLoading: false,
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_MARKETINGOVE_ATRIBUTY_LOAD_FAIL:
			return {
				...state,
				marketingoveAtributy: {
					...initState.marketingoveAtributy,
					isFailure: true
				}
			}
		case OBCHODNY_PARTNER_REGULACIE_LOAD_START:
			return {
				...state,
				regulacie: {
					...state.regulacie,
					page: action.payload.page,
					isLoading: true
				}
			}
		case OBCHODNY_PARTNER_REGULACIE_LOAD_DONE:
			return {
				...state,
				regulacie: {
					...state.regulacie,
					data: state.regulacie.page === 1 ? action.payload.regulacie : state.regulacie.data.concat(action.payload.regulacie),
					lastLoad: action.payload.lastLoad,
					page: action.payload.page,
					hasNextPage: action.payload.hasNextPage,
					isLoading: false,
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_REGULACIE_LOAD_FAIL:
			return {
				...state,
				regulacie: {
					...initState.regulacie,
					isFailure: true
				}
			}
		case OBCHODNY_PARTNER_DIGITALIZACIA_LOAD_START:
			return {
				...state,
				digitalizacia: {
					...state.digitalizacia,
					isLoading: true
				}
			}
		case OBCHODNY_PARTNER_DIGITALIZACIA_LOAD_DONE:
			return {
				...state,
				digitalizacia: {
					...state.digitalizacia,
					data: action.payload.digitalizacia,
					isLoading: false,
					isFailure: false
				}
			}
		case OBCHODNY_PARTNER_DIGITALIZACIA_LOAD_FAIL:
			return {
				...state,
				digitalizacia: {
					...initState.digitalizacia,
					isFailure: true
				}
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
