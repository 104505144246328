import {
	INTERAKCIE_CLOSE_START,
	INTERAKCIE_CLOSE_DONE,
	INTERAKCIE_CLOSE_FAIL,
	INTERAKCIE_START_LOAD_START,
	INTERAKCIE_START_LOAD_FAIL,
	INTERAKCIE_START_LOAD_DONE,
	INTERAKCIE_LOAD_DONE,
	INTERAKCIE_LOAD_FAIL,
	INTERAKCIE_LOAD_START
} from '../types/interakcie'
import { RESET_STORAGE } from '../types/search'

export const initState = {
	index: {
		data: [],
		isLoading: false,
		isFailure: false,
		isLastPage: false,
		page: 1
	},
	detail: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	isLoading: false
}

export default (state = initState, action) => {
	switch (action.type) {
		case INTERAKCIE_START_LOAD_START:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case INTERAKCIE_START_LOAD_FAIL:
			return {
				...initState,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case INTERAKCIE_START_LOAD_DONE:
			return {
				...state,
				detail: {
					...state.detail,
					data: {
						id: action.payload.interakcia.id,
						opCislo: action.payload.interakcia.opCislo,
						vytvorenaOd: action.payload.interakcia.vytvorenaOd,
						ukoncenaOd: action.payload.interakcia.ukoncenaOd,
						smer: action.payload.interakcia.smer,
						kategoria: action.payload.interakcia.kategoria,
						poznamka: action.payload.interakcia.poznamka,
						riesitel: action.payload.interakcia.riesitel,
						riesitelIdentita: action.payload.interakcia.riesitelIdentita,
						kanal: action.payload.interakcia.kanal,
						ukony: action.payload.interakcia.ukony,
						acw: action.payload.interakcia.acw,
						pinStav: action.payload.interakcia.pinStav,
						pinPlatnostDo: action.payload.interakcia.pinPlatnostDo,
						externeIDs: action.payload.interakcia.externeIDs
					},
					isLoading: false,
					isFailure: false
				}
			}
		case INTERAKCIE_LOAD_DONE:
			return {
				...state,
				index: {
					...state.index,
					data: state.index.page === 1 ? action.payload.interakcie : state.index.data.concat(action.payload.interakcie),
					isLoading: false,
					isFailure: false,
					isLastPage: action.payload.isLastPage
				}
			}
		case INTERAKCIE_LOAD_FAIL:
			return {
				...initState,
				index: {
					...state.index,
					isLoading: false,
					isFailure: true
				}
			}
		case INTERAKCIE_LOAD_START:
			return {
				...state,
				index: {
					...state.index,
					page: action.payload.page,
					isLoading: true,
					isFailure: false
				}
			}
		case INTERAKCIE_CLOSE_START:
			return {
				...state,
				isLoading: true
			}
		case INTERAKCIE_CLOSE_DONE:
			return {
				...state,
				isLoading: false
			}
		case INTERAKCIE_CLOSE_FAIL:
			return {
				...state,
				isLoading: false
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
