import { EXTERNAL_INTEGRATIONS } from './enums'

export const getExterneIDs = (externalIntegrationsStore) => {
	if (!externalIntegrationsStore) return []

	const externeIDs = []

	const { liveAgentId, finesseId, call250Id } = externalIntegrationsStore

	if (liveAgentId) {
		externeIDs.push({
			source: EXTERNAL_INTEGRATIONS.LIVE_AGENT,
			externalId: liveAgentId
		})
	}

	if (finesseId) {
		externeIDs.push({
			source: EXTERNAL_INTEGRATIONS.FINESSE,
			externalId: finesseId
		})
	}

	if (call250Id) {
		externeIDs.push({
			source: EXTERNAL_INTEGRATIONS.CALL250,
			externalId: call250Id
		})
	}

	return externeIDs
}
