import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { reduxForm, propTypes, Field, change } from 'redux-form'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { get, isEmpty, isEqual } from 'lodash'

import WrapperPodpisovanieDokumentov from '../PodpisovanieDokumentov/WrapperPodpisovanieDokumentov'
import SubmitButton from './SubmitButton'
import PodradeneSuhlasyWarning from './PodradeneSuhlasyWarning'

import validate from './validateZmenaSuhlasuConfirmForm'

// actions
import * as PodradeneSuhlasyActions from '../../actions/ObchodniPartneri/PodradeneSuhlasyActions'

// utils
import { FORMS, SUHLAS_TYP_VYJADRENIA } from '../../utils/enums'
import { formatFormValue } from '../../utils/form'
import { formatDate } from '../../utils/date'
import { requireSignature } from '../../utils/suhlas'
import TextInputField from '../../atoms/TextInputField'

class ZmenaSuhlasuConfirm extends React.Component {
	static propTypes = {
		...propTypes,
		formTitle: PropTypes.string.isRequired,
		onSubmit: PropTypes.func.isRequired,
		onBackClick: PropTypes.func.isRequired,
		onCancelClick: PropTypes.func.isRequired,
		scenarioOption: PropTypes.shape({
			kanal: PropTypes.number.isRequired,
			scenarios: PropTypes.array.isRequired,
			notificationRequire: PropTypes.bool.isRequired,
			notificationTypes: PropTypes.array.isRequired
		}),
		signedPdf: PropTypes.shape({
			data: PropTypes.shape(),
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired
		}),
		podradeneSuhlasy: PropTypes.shape(),
		aktualnaVerziaSuhlasu: PropTypes.shape()
	}

	componentDidMount() {
		const { podradeneSuhlasyActions, formValues, computedMatch } = this.props
		const { params } = computedMatch

		podradeneSuhlasyActions.loadPodradeneSuhlasy(params.definiciaId, formValues.typVyjadrenieSuhlas)
	}

	componentDidUpdate(prevProps) {
		const { dispatch, signedPdf } = this.props

		if (!isEqual(get(signedPdf, 'data', null), get(prevProps, 'signedPdf.data', null))) {
			dispatch(change(FORMS.ZMENA_SUHLASU, 'biznisReferencia', null))
		}
	}

	render() {
		const { handleSubmit, formValues, t, onBackClick, onCancelClick, originalValues, formTitle, scenarioOption, signedPdf, podradeneSuhlasy } = this.props

		const poslednySuhlas = originalValues.poslednySuhlas
		const poslednaVerzia = originalValues.suhlasVerzia && originalValues.suhlasVerzia[originalValues.suhlasVerzia.length - 1]
		const typVyjadrenieSuhlasValue = get(formValues, 'typVyjadrenieSuhlas')
		const isBiznisReferenciaEmpty = isEmpty(get(formValues, 'biznisReferencia'))

		const requiredSignature = isBiznisReferenciaEmpty
			? requireSignature(poslednaVerzia) && typVyjadrenieSuhlasValue === SUHLAS_TYP_VYJADRENIA.SUHLAS
			: false
		const isDocumentSigned = signedPdf.data != null

		return (
			<form className='consent-page' onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					<div className='pull-right'>
						<SubmitButton requiredSignature={requiredSignature} isDocumentSigned={isDocumentSigned} />
					</div>
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<button onClick={onCancelClick} type='button' className='button pull-right' data-type='outline' data-color='red'>
						{t('translation:Common.Zrušiť')}
					</button>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<thead>
									<tr>
										<th />
										<th>{t('translation:Common.Aktuálne hodnoty')}</th>
										<th>{t('translation:Common.Nové hodnoty')}</th>
									</tr>
								</thead>
								<tbody>
									{poslednySuhlas?.zmluvnyUcet && formValues?.zmluvnyUcet && (
										<tr>
											<td>
												<strong>{t('translation:Suhlasy.Zmluvný účet')}</strong>
											</td>
											<td>{poslednySuhlas?.zmluvnyUcet}</td>
											<td>{formatFormValue(formValues?.zmluvnyUcet, poslednySuhlas?.zmluvnyUcet)}</td>
										</tr>
									)}
									{poslednySuhlas?.produktKod && formValues?.produktKod && (
										<tr>
											<td>
												<strong>{t('translation:Suhlasy.Súhlas pre produkt')}</strong>
											</td>
											<td>{poslednySuhlas.produktKod}</td>
											<td>{formatFormValue(formValues?.produktKod, poslednySuhlas?.produktKod)}</td>
										</tr>
									)}
									{(poslednySuhlas?.datumVyjadrenie || formValues?.datumVyjadrenie) && (
										<tr>
											<td>
												<strong>{t('translation:Suhlasy.Dátum a čas vyjadrenia')}</strong>
											</td>
											<td>{formatDate(poslednySuhlas?.datumVyjadrenie, null, 'DD.MM.YYYY, HH:mm')}</td>
											<td>
												{formatFormValue(
													formatDate(formValues?.datumVyjadrenie, null, 'DD.MM.YYYY, HH:mm'),
													formatDate(poslednySuhlas?.datumVyjadrenie, null, 'DD.MM.YYYY, HH:mm')
												)}
											</td>
										</tr>
									)}
									{(poslednySuhlas?.platnostOd || formValues?.platnostOd) && (
										<tr>
											<td>
												<strong>{t('translation:Suhlasy.Platnosť od')}</strong>
											</td>
											<td>{formatDate(poslednySuhlas?.platnostOd, null, 'DD.MM.YYYY, HH:mm')}</td>
											<td>
												{formatFormValue(
													formatDate(formValues?.platnostOd, null, 'DD.MM.YYYY, HH:mm'),
													formatDate(poslednySuhlas?.platnostOd, null, 'DD.MM.YYYY, HH:mm')
												)}
											</td>
										</tr>
									)}
									{(poslednySuhlas?.typVyjadrenieSuhlas || formValues?.typVyjadrenieSuhlas) && (
										<tr>
											<td>
												<strong>{t('translation:Suhlasy.Typ vyjadrenia')}</strong>
											</td>
											<td>{poslednySuhlas?.typVyjadrenieSuhlas}</td>
											<td>{formatFormValue(formValues?.typVyjadrenieSuhlas, poslednySuhlas?.typVyjadrenieSuhlas)}</td>
										</tr>
									)}
									{poslednySuhlas?.pocetVykonanyDopyt && formValues?.typVyjadrenieSuhlas === SUHLAS_TYP_VYJADRENIA.NESUHLAS && (
										<tr>
											<td>
												<strong>{t('translation:Suhlasy.Počet dopýtaní')}</strong>
											</td>
											<td>{poslednySuhlas?.pocetVykonanyDopyt}</td>
											<td>
												<strong>{Number(poslednySuhlas?.pocetVykonanyDopyt) + 1}</strong>
											</td>
										</tr>
									)}
									{(poslednySuhlas?.nesuhlasDovod?.dovod || formValues?.nesuhlasDovod?.dovod) && (
										<tr>
											<td>
												{formValues?.typVyjadrenieSuhlas === SUHLAS_TYP_VYJADRENIA.NESUHLAS && (
													<strong>{t('translation:Suhlasy.Dôvod nesúhlasu')}</strong>
												)}
												{(formValues?.typVyjadrenieSuhlas === SUHLAS_TYP_VYJADRENIA.NAMIETKA ||
													formValues?.typVyjadrenieSuhlas === SUHLAS_TYP_VYJADRENIA.NAMIETKA_NA_SCHVALENIE) && (
													<strong>{t('translation:Suhlasy.Dôvod námietky')}</strong>
												)}
											</td>
											<td>{poslednySuhlas?.nesuhlasDovod?.dovod}</td>
											<td>
												{formValues?.nesuhlasDovod?.dovod
													? formatFormValue(formValues?.nesuhlasDovod?.dovod, poslednySuhlas?.nesuhlasDovod?.dovod)
													: '-'}
											</td>
										</tr>
									)}
									{(formValues?.nesuhlasPoznamka || poslednySuhlas?.nesuhlasPoznamka) && (
										<tr>
											<td>
												<strong>{t('translation:Suhlasy.Poznámka')}</strong>
											</td>
											<td>{poslednySuhlas?.nesuhlasPoznamka}</td>
											<td>
												{formValues?.nesuhlasPoznamka
													? formatFormValue(formValues?.nesuhlasPoznamka, poslednySuhlas?.nesuhlasPoznamka)
													: '-'}
											</td>
										</tr>
									)}
									<tr>
										<td>
											<strong>{t('translation:Suhlasy.Indentifikátor nahrávky')}</strong>
										</td>
										<td>{poslednySuhlas?.biznisReferencia}</td>
										<td>
											<Field
												name={'biznisReferencia'}
												component={TextInputField}
												validate={this.validate}
												placeholder={t('translation:Suhlasy.Zapíšte indentifikátor nahrávky')}
												disabled={isDocumentSigned}
											/>
										</td>
									</tr>
								</tbody>
							</table>
							{isBiznisReferenciaEmpty && <WrapperPodpisovanieDokumentov scenarioOption={scenarioOption} suhlasVerzia={poslednaVerzia} />}
							{podradeneSuhlasy?.data?.length > 0 && (
								<PodradeneSuhlasyWarning originalSuhlas={originalValues} formValues={formValues} podradeneSuhlasy={podradeneSuhlasy.data} />
							)}
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.ZMENA_SUHLASU,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate
})(ZmenaSuhlasuConfirm)

const mapStateToProps = (state) => ({
	signedPdf: get(state, 'podpisovanieDokumentov.signedPdf'),
	podradeneSuhlasy: state.obchodnyPartner.podradeneSuhlasy,
	aktualnaVerziaSuhlasu: state.obchodnyPartner.aktualnaVerziaSuhlasu
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	podradeneSuhlasyActions: bindActionCreators(PodradeneSuhlasyActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(form)
