import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

class DefaultRoute extends Route {
	static propTypes = {
		withoutPartner: PropTypes.bool,
		needPartner: PropTypes.bool,
		component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
		layout: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
	}

	componentDidUpdate = () => {
		document.title = 'ZSE Care'
	}

	componentDidMount = () => {
		document.title = 'ZSE Care'
	}

	render = () => {
		const { component: Component, layout: Layout } = this.props

		if (this.props.layout) {
			return (
				<Layout {...this.props}>
					<Component {...this.props} />
				</Layout>
			)
		}
		return <Component {...this.props} />
	}
}

export default DefaultRoute
