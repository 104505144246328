import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { isEmpty, map, get, size, pick, keys, indexOf, toArray, isEqual, values, trim, debounce } from 'lodash'
import { withTranslation, Trans } from 'react-i18next'
import ReactQueryParams from 'react-query-params'
import cx from 'classnames'

// components
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'
import ElementEmptyContent from '../../components/ElementEmptyContent'
import MiestoSpotrebyWrapper from '../../components/ZmluvneVztahy/MiestoSpotrebyWrapper'
import PonukaDropdownMenu from '../../components/ZmluvneVztahy/PonukaDropdownMenu'

// actions
import * as MiestaSpotrebyActions from '../../actions/MiestaSpotrebyActions'
import * as OdberneMiestaActions from '../../actions/OdberneMiestaActions'
import * as ZmluvneUctyActions from '../../actions/ZmluvneUctyActions'
import * as ZmluvneVztahyActions from '../../actions/ZmluvneVztahyActions'
import * as ZmluvneVztahyConfigActions from '../../actions/ZmluvneVztahyConfigActions'
import * as FiltersActions from '../../actions/SelectedFiltersActions'

// utils
import Permissions, { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import { FILTER_SELECTORS, PONUKA_MODE, OBCHODNY_PARTNER_TYP } from '../../utils/enums'
import { isValidIdentifikator, isValidCisloZmluvy } from '../../utils/zmluvneVztahy'
import extractProperties from '../../utils/extractProperties'

// atoms
import Switch from '../../atoms/Switch'
import Select from '../../atoms/BasicSelect'

const BEZ_MIESTA_SPOTREBY_CISLO = '0'

const queryParams = {
	omIdentifikator: 'omIdentifikator',
	cisloZmluvy: 'cisloZmluvy',
	zuCislo: 'zuCislo',
	aktivnaKomoditnaZmluva: 'aktivnaKomoditnaZmluva',
	neregulovanaKomoditnaZmluva: 'neregulovanaKomoditnaZmluva'
}

class ZmluvneVztahyPage extends ReactQueryParams {
	static propTypes = {
		interakcia: PropTypes.shape({
			id: PropTypes.number.isRequired,
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		miestaSpotrebyActions: PropTypes.shape(),
		miestaSpotreby: PropTypes.shape(),
		odberneMiestaActions: PropTypes.shape(),
		odberneMiesta: PropTypes.shape(),
		zmluvneUctyActions: PropTypes.shape(),
		zmluvneUcty: PropTypes.shape(),
		zmluvneVztahyBezMiestaSpotreby: PropTypes.shape(),
		t: PropTypes.func
	}

	defaultQueryParams = {
		[queryParams.omIdentifikator]: null,
		[queryParams.cisloZmluvy]: null,
		[queryParams.zuCislo]: null,
		[queryParams.aktivnaKomoditnaZmluva]: null,
		[queryParams.neregulovanaKomoditnaZmluva]: null
	}

	constructor(props) {
		super(props)

		this.state = {
			miestaSpotrebySize: 0,
			identifikatorError: null,
			cisloZmluvyError: null,
			isFilterActive: false
		}
	}

	_mounted = false

	componentDidMount = async () => {
		this._mounted = true

		const { selectedFilters, odberneMiestaActions, zmluvneUctyActions, filtersActions, zmluvneVztahyActions, zmluvneVztahyConfigActions } = this.props

		odberneMiestaActions.loadAllOdberneMiesta()
		zmluvneUctyActions.loadAllZmluvneUcty()

		const filteredParams = pick(
			this.queryParams,
			keys(this.queryParams).filter((key) => {
				return indexOf(toArray(queryParams), key) !== -1 && this.queryParams[key] !== ''
			})
		)

		const newFilter = {
			...selectedFilters,
			...filteredParams
		}

		zmluvneVztahyConfigActions.clearZmluvneVztahyConfig()
		zmluvneVztahyActions.loadAllZmluvneVztahy(BEZ_MIESTA_SPOTREBY_CISLO, {
			neregulovanaKomoditnaZmluva: newFilter[queryParams.neregulovanaKomoditna] ?? undefined
		})

		this.fetchData(newFilter)

		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.ZMLUVNE_VZTAHY_PAGE, newFilter)
	}

	componentWillUnmount = () => {
		this._mounted = false

		const { filtersActions } = this.props

		const newFilter = {
			...this.defaultQueryParams
		}

		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.ZMLUVNE_VZTAHY_PAGE, newFilter)
	}

	componentDidUpdate = () => {
		const { selectedFilters } = this.props

		// prevent to inject not allowed query param
		const query = extractProperties(this.queryParams, values(queryParams))
		if (!isEqual(query, selectedFilters)) {
			this.setQueryParams({
				...selectedFilters
			})
		}
	}

	openCollapsibleItems = (miestaSpotreby) => {
		const { zmluvneVztahyConfigActions, zmluvneVztahyActions, selectedFilters, zmluvneVztahyBezMiestaSpotreby } = this.props

		const isFilterActive = this.isFilterActive()
		this.setState({
			isFilterActive
		})

		zmluvneVztahyConfigActions.clearZmluvneVztahyConfig()
		// Rozbalí sekciu bez MS ak nie je nastavený filter
		const zmluvneVztahyBezMiestaSpotrebySize = size(get(zmluvneVztahyBezMiestaSpotreby, 'all', []))
		if (zmluvneVztahyBezMiestaSpotrebySize > 0) {
			zmluvneVztahyConfigActions.updateMiestaSpotrebyConfig(BEZ_MIESTA_SPOTREBY_CISLO, !isFilterActive)
		}

		miestaSpotreby?.forEach((miestoSpotreby) => {
			const msCislo = get(miestoSpotreby, 'cislo')

			// Rozbalí sekciu ak je nastavený filter
			zmluvneVztahyConfigActions.updateMiestaSpotrebyConfig(msCislo, isFilterActive)

			// Rozbalí aj sekciu neaktívnych ZÚ a zmlúv po vyhľadaní EIC/POD
			if (selectedFilters[queryParams.omIdentifikator] || selectedFilters[queryParams.cisloZmluvy]) {
				zmluvneVztahyConfigActions.updateNeaktivneZmluvneUctyConfig(msCislo, true)
				zmluvneVztahyConfigActions.updateNeaktivneZmluvneVztahyConfig(msCislo, true)
			}

			// Optimalizácia, aby zbytočne nevolal endpoint ak je sekcia zatvorená
			if (!isFilterActive) {
				return
			}

			zmluvneVztahyActions.loadAllZmluvneVztahy(msCislo, {
				neregulovanaKomoditnaZmluva: selectedFilters[queryParams.neregulovanaKomoditnaZmluva] ?? undefined
			})
		})

		// Ak je len jedno miesto spotreby a nie sú nastavené filtre, tak sa otvorí zmluvný vzťah.
		// Zvyčajne to platí pre prvé načítanie stránky alebo po zrušení filtrov.
		if (miestaSpotreby?.length === 1 && !isFilterActive) {
			const msCislo = get(miestaSpotreby[0], 'cislo')
			zmluvneVztahyConfigActions.updateMiestaSpotrebyConfig(msCislo, true)
			zmluvneVztahyActions.loadAllZmluvneVztahy(msCislo, {
				neregulovanaKomoditnaZmluva: selectedFilters[queryParams.neregulovanaKomoditnaZmluva] ?? undefined
			})
		}
	}

	fetchData = async (newFilter) => {
		const { miestaSpotrebyActions } = this.props

		let miestaSpotreby = await miestaSpotrebyActions.loadAllMiestaSpotreby(
			{ sort: 'adresa.obec:ASC,adresa.ulica:ASC' },
			{ ...newFilter, cisloZmluvy: undefined, zvCisloIsu: newFilter[queryParams.cisloZmluvy] }
		)

		// Pri nastavení filtra pošle najprv query parameter „zvCisloISU“.
		// Ak nedostal výsledky, tak zavolá endpoint s druhým query parametrom „zvCislo“
		if (newFilter[queryParams.cisloZmluvy] && miestaSpotreby?.length === 0) {
			miestaSpotreby = await miestaSpotrebyActions.loadAllMiestaSpotreby(
				{ sort: 'adresa.obec:ASC,adresa.ulica:ASC' },
				{ ...newFilter, cisloZmluvy: undefined, zvCislo: newFilter[queryParams.cisloZmluvy] }
			)
		}

		this.openCollapsibleItems(miestaSpotreby)
	}

	handleExpandAll = (shouldExpand) => {
		const { zmluvneVztahyConfigActions, zmluvneVztahyActions, miestaSpotreby, zmluvneVztahy, selectedFilters } = this.props

		zmluvneVztahyConfigActions.updateMiestaSpotrebyConfig(BEZ_MIESTA_SPOTREBY_CISLO, shouldExpand)
		get(miestaSpotreby, 'data', []).forEach((miestoSpotreby) => {
			const msCislo = get(miestoSpotreby, 'cislo')
			zmluvneVztahyConfigActions.updateMiestaSpotrebyConfig(msCislo, shouldExpand)

			if (shouldExpand === false) {
				return
			}

			zmluvneVztahyActions.loadAllZmluvneVztahy(msCislo, {
				neregulovanaKomoditnaZmluva: selectedFilters[queryParams.neregulovanaKomoditnaZmluva] ?? undefined
			})
		})
	}

	fetchDataDebounced = debounce(this.fetchData, 600)

	filterChanged = (filter, fieldName) => {
		const { selectedFilters, filtersActions, t } = this.props

		const newFilter = {
			...selectedFilters,
			[fieldName]: null
		}

		let disableFetch = false
		switch (fieldName) {
			case queryParams.omIdentifikator:
				if (filter) {
					newFilter[fieldName] = filter
				}
				if (filter && !isValidIdentifikator(filter)) {
					this.setState({
						identifikatorError: { msg: t('translation:ZmluvneUcty.Skontrolujte formát identifikátora') }
					})
					disableFetch = true
				} else {
					this.setState({
						identifikatorError: null
					})
				}
				break
			case queryParams.cisloZmluvy:
				if (filter) {
					newFilter[fieldName] = filter
				}
				if (filter && !isValidCisloZmluvy(filter)) {
					this.setState({
						cisloZmluvyError: { msg: t('translation:ZmluvneUcty.Dĺžka čísla zmluvy musí byť 9 alebo 10 znakov') }
					})
					disableFetch = true
				} else {
					this.setState({
						cisloZmluvyError: null
					})
				}
				break
			default:
				if (filter) {
					newFilter[fieldName] = filter.value
				}
				break
		}

		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.ZMLUVNE_VZTAHY_PAGE, newFilter)
		if (disableFetch) return
		this.fetchDataDebounced(newFilter)
	}

	onChangeSearchInput = (value, type) => {
		const trimmed = trim(value)
		this.filterChanged(trimmed, type)
	}

	removeFilters = () => {
		const { selectedFilters, zmluvneVztahyConfigActions, filtersActions } = this.props

		const newFilter = {
			...this.defaultQueryParams
		}

		if (!isEqual(selectedFilters, newFilter)) {
			this.fetchData(newFilter)
			zmluvneVztahyConfigActions.clearZmluvneVztahyConfig()
		}

		this.setState({
			identifikatorError: null,
			cisloZmluvyError: null
		})
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.ZMLUVNE_VZTAHY_PAGE, newFilter)
	}

	removeFilterValue = (filterName, filterValue) => {
		const { selectedFilters } = this.props

		let filteredValue
		if (typeof selectedFilters[filterName] === 'string') {
			filteredValue = selectedFilters[filterName].split(',').filter((item) => item != filterValue)
		} else {
			filteredValue = null
		}

		const newValue = !isEmpty(filteredValue) ? filteredValue.join(',') : null

		this.filterChanged(newValue, filterName)
	}

	isFilterActive = () => {
		const { selectedFilters } = this.props

		if (
			get(selectedFilters, queryParams.omIdentifikator) ||
			get(selectedFilters, queryParams.cisloZmluvy) ||
			get(selectedFilters, queryParams.zuCislo) ||
			get(selectedFilters, queryParams.aktivnaKomoditnaZmluva) === true ||
			typeof get(selectedFilters, queryParams.neregulovanaKomoditnaZmluva) === 'boolean'
		) {
			return true
		}

		return false
	}

	getZmluvnyUcetFilter = () => {
		const { t, selectedFilters, zmluvneUcty } = this.props

		const isActiveZmluvnyUcetFilter = !isEmpty(selectedFilters[queryParams.zuCislo])

		let zmluvnyUcetFilterPlaceholder = t('translation:ZmluvneUcty.Všetky')
		if (isActiveZmluvnyUcetFilter) {
			zmluvnyUcetFilterPlaceholder = selectedFilters[queryParams.zuCislo]
		}

		const allOptions = {
			value: null,
			label: t('translation:ZmluvneUcty.Všetky')
		}

		let zmluvnyUcetOptions = zmluvneUcty?.data?.map((zmluvnyUcet) => {
			return {
				value: zmluvnyUcet.cislo,
				label: zmluvnyUcet.cislo
			}
		})

		const zmluvnyUcetFilterOptions = [
			allOptions,
			...zmluvnyUcetOptions.filter((item) => {
				// filter out selected items
				if (selectedFilters[queryParams.zuCislo] && selectedFilters[queryParams.zuCislo].split(',').includes(item?.value)) {
					return false
				}

				return true
			})
		]

		return { zmluvnyUcetFilterOptions, zmluvnyUcetFilterPlaceholder, isActiveZmluvnyUcetFilter }
	}

	getStavZmluvnyFilter = () => {
		const { t, selectedFilters } = this.props

		const isActiveStavZmluvnyFilter = selectedFilters[queryParams.aktivnaKomoditnaZmluva] === true

		let stavZmluvnyFilterPlaceholder = t('translation:ZmluvneUcty.Všetky')
		if (selectedFilters[queryParams.aktivnaKomoditnaZmluva] === true) {
			stavZmluvnyFilterPlaceholder = t('translation:ZmluvneUcty.Len aktívne')
		}

		const stavZmluvnyFilterOptions = [
			{
				value: null,
				label: t('translation:ZmluvneUcty.Všetky')
			},
			{
				value: true,
				label: t('translation:ZmluvneUcty.Len aktívne')
			}
		]

		return { stavZmluvnyFilterOptions, stavZmluvnyFilterPlaceholder, isActiveStavZmluvnyFilter }
	}

	getStavRegulacieFilter = () => {
		const { t, selectedFilters } = this.props

		const isActiveStavRegulacieFilter = typeof selectedFilters[queryParams.neregulovanaKomoditnaZmluva] === 'boolean'

		let stavRegulacieFilterPlaceholder = t('translation:ZmluvneUcty.Všetky')
		if (selectedFilters[queryParams.neregulovanaKomoditnaZmluva] === true) {
			stavRegulacieFilterPlaceholder = t('translation:ZmluvneUcty.Len neregulované')
		} else if (selectedFilters[queryParams.neregulovanaKomoditnaZmluva] === false) {
			stavRegulacieFilterPlaceholder = t('translation:ZmluvneUcty.Len regulované')
		}

		const stavRegulacieFilterOptions = [
			{
				value: null,
				label: t('translation:ZmluvneUcty.Všetky')
			},
			{
				value: false,
				label: t('translation:ZmluvneUcty.Len regulované')
			},
			{
				value: true,
				label: t('translation:ZmluvneUcty.Len neregulované')
			}
		]

		return { stavRegulacieFilterOptions, stavRegulacieFilterPlaceholder, isActiveStavRegulacieFilter }
	}

	render = () => {
		const { selectedFilters, miestaSpotreby, odberneMiesta, zmluvneUcty, zmluvneVztahyBezMiestaSpotreby, t } = this.props

		const isLoading = get(miestaSpotreby, 'isLoading') || get(odberneMiesta, 'isLoading') || get(zmluvneUcty, 'isLoading') || false
		const isFailure = get(miestaSpotreby, 'isFailure') || get(odberneMiesta, 'isFailure') || get(zmluvneUcty, 'isFailure') || false

		const odberneMiestaByCislo = get(odberneMiesta, 'byCislo', {})
		const zmluvneUctyByCislo = get(zmluvneUcty, 'byCislo', {})

		if (isLoading) {
			return this.commonContentContainer(<ElementLoading />)
		}

		if (isFailure) {
			return this.commonContentContainer(<ElementFailure text={t('translation:ZmluvneUcty.Nepodarilo sa načítať miesta spotreby')} />)
		}

		const zmluvneVztahyBezMiestaSpotrebySize = size(get(zmluvneVztahyBezMiestaSpotreby, 'all', []))
		if (isEmpty(get(miestaSpotreby, 'data', [])) && zmluvneVztahyBezMiestaSpotrebySize == 0) {
			return this.commonContentContainer(<ElementEmptyContent text={t('translation:ZmluvneUcty.Obchodný partner nemá žiadne miesta spotreby')} />)
		}

		const bezMiestaSpotreby = zmluvneVztahyBezMiestaSpotrebySize > 0 && (
			<MiestoSpotrebyWrapper
				key={`miesto-spotreby-0`}
				zmluvneUcty={zmluvneUctyByCislo}
				searchIdentifikator={selectedFilters[queryParams.omIdentifikator]}
				searchCisloZmluvy={selectedFilters[queryParams.cisloZmluvy]}
				neregulovanaKomoditnaZmluva={selectedFilters[queryParams.neregulovanaKomoditnaZmluva]}
				t={t}
			/>
		)

		const sMiestomSpotreby = map(get(miestaSpotreby, 'data', []), (miestoSpotreby, index) => {
			return (
				<MiestoSpotrebyWrapper
					key={`miesto-spotreby-${index + 1}`}
					miestoSpotreby={miestoSpotreby}
					odberneMiesta={odberneMiestaByCislo}
					zmluvneUcty={zmluvneUctyByCislo}
					searchIdentifikator={selectedFilters[queryParams.omIdentifikator]}
					searchCisloZmluvy={selectedFilters[queryParams.cisloZmluvy]}
					neregulovanaKomoditnaZmluva={selectedFilters[queryParams.neregulovanaKomoditnaZmluva]}
					t={t}
				/>
			)
		})

		const miestaSpotrebyListItems = [bezMiestaSpotreby, ...sMiestomSpotreby]

		return this.commonContentContainer(<>{miestaSpotrebyListItems}</>)
	}

	commonContentContainer = (content) => {
		const { t, selectedFilters, obchodnyPartner, zmluvneVztahyConfig } = this.props
		const { identifikatorError, cisloZmluvyError } = this.state

		const isMop = get(obchodnyPartner, 'typ') === OBCHODNY_PARTNER_TYP.MOP

		const isAllExpanded = Object.values(zmluvneVztahyConfig).every((miestoSpotreby) => {
			return miestoSpotreby?.isOpenMiestoSpotreby === true
		})

		const { zmluvnyUcetFilterOptions, zmluvnyUcetFilterPlaceholder, isActiveZmluvnyUcetFilter } = this.getZmluvnyUcetFilter()
		const { stavRegulacieFilterOptions, stavRegulacieFilterPlaceholder, isActiveStavRegulacieFilter } = this.getStavRegulacieFilter()
		const { stavZmluvnyFilterOptions, stavZmluvnyFilterPlaceholder, isActiveStavZmluvnyFilter } = this.getStavZmluvnyFilter()

		return (
			<>
				<div className='content-header zmluvne-vztahy'>
					<div className='row'>
						<div className='col-2'>
							<div
								className={cx('input-wrapper no-icon clearfix', { 'has-error': identifikatorError })}
								style={{ margin: '10px 0', paddingBottom: '0px' }}
							>
								<label htmlFor='identifikator-search'>{t('translation:ZmluvneUcty.EIC / POD')}</label>
								<input
									type='text'
									className='input-field'
									data-type='search'
									value={selectedFilters[queryParams.omIdentifikator] || ''}
									onChange={(e) => {
										this.onChangeSearchInput(e.target.value, queryParams.omIdentifikator)
									}}
									placeholder={t('translation:ZmluvneUcty.EIC / POD')}
								/>
								{identifikatorError && (
									<div htmlFor='identifikator-search' className='text-danger'>
										{identifikatorError.msg}
									</div>
								)}
							</div>
						</div>
						<div className='col-2'>
							<Select
								className={cx({ 'active-filters': isActiveZmluvnyUcetFilter, purple: isActiveZmluvnyUcetFilter })}
								label={t('translation:ZmluvneUcty.Zmluvný účet')}
								options={zmluvnyUcetFilterOptions}
								onChange={(zuCislo) => this.filterChanged(zuCislo, queryParams.zuCislo)}
								placeholder={zmluvnyUcetFilterPlaceholder}
							/>
						</div>
						<div className='col-2'>
							<div
								className={cx('input-wrapper no-icon clearfix', { 'has-error': cisloZmluvyError })}
								style={{ margin: '10px 0', paddingBottom: '0px' }}
							>
								<label htmlFor='cislo-zmluvy-search'>{t('translation:ZmluvneUcty.Číslo zmluvy')}</label>
								<input
									type='text'
									className='input-field'
									data-type='search'
									value={selectedFilters[queryParams.cisloZmluvy] || ''}
									onChange={(e) => {
										this.onChangeSearchInput(e.target.value, queryParams.cisloZmluvy)
									}}
									placeholder={t('translation:ZmluvneUcty.Číslo zmluvy')}
								/>
								{cisloZmluvyError && (
									<div htmlFor='identifikator-search' className='text-danger'>
										{cisloZmluvyError.msg}
									</div>
								)}
							</div>
						</div>
						<div className='col-2'>
							<Select
								className={cx({ 'active-filters': isActiveStavZmluvnyFilter, orange: isActiveStavZmluvnyFilter })}
								label={t('translation:ZmluvneUcty.Stav zmluvy')}
								classNamePrefix='react-select'
								options={stavZmluvnyFilterOptions}
								onChange={(stav) => this.filterChanged(stav, queryParams.aktivnaKomoditnaZmluva)}
								placeholder={stavZmluvnyFilterPlaceholder}
							/>
						</div>
						{isMop && (
							<div className='col-2'>
								<Select
									className={cx({ 'active-filters': isActiveStavRegulacieFilter, blue: isActiveStavRegulacieFilter })}
									label={t('translation:ZmluvneUcty.Stav regulácie')}
									classNamePrefix='react-select'
									options={stavRegulacieFilterOptions}
									onChange={(stav) => this.filterChanged(stav, queryParams.neregulovanaKomoditnaZmluva)}
									placeholder={stavRegulacieFilterPlaceholder}
									labelTooltip={
										<div style={{ textAlign: 'left' }}>
											<div style={{ marginBottom: '10px' }}>
												{t('translation:ZmluvneUcty.Len regulované = na MS sú len regulované komoditné spotrebné zmluvy')}
											</div>
											<div>
												{t('translation:ZmluvneUcty.Len neregulované = na MS je aspoň jedna neregulovaná komoditná spotrebná zmluva')}
											</div>
										</div>
									}
								/>
							</div>
						)}
						<div className='col-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', marginLeft: 'auto' }}>
							<Permissions
								allowed={[PERMISSIONS.UKON_NOVA_ZMLUVA_KOMODITA]}
								render={(hasPerm) => {
									if (hasPerm) {
										return <PonukaDropdownMenu ponukaMode={PONUKA_MODE.OBCHODNY_PARTNER} />
									}
									return null
								}}
							/>
						</div>
						<div className='col-12'>
							<div className='filter-items clearfix' style={{ paddingRight: '150px' }}>
								{get(selectedFilters, queryParams.omIdentifikator) && (
									<div
										className='filter-item'
										data-color={'green'}
										onClick={() => this.removeFilterValue(queryParams.omIdentifikator, selectedFilters[queryParams.omIdentifikator])}
										style={{ cursor: 'pointer' }}
									>
										<span className='filter-text'>{selectedFilters[queryParams.omIdentifikator]}</span>
										<span className='remove' />
									</div>
								)}
								{get(selectedFilters, queryParams.zuCislo) && (
									<div
										className='filter-item'
										data-color={'purple'}
										onClick={() => this.removeFilterValue(queryParams.zuCislo, selectedFilters[queryParams.zuCislo])}
										style={{ cursor: 'pointer' }}
									>
										<span className='filter-text'>{selectedFilters[queryParams.zuCislo]}</span>
										<span className='remove' />
									</div>
								)}
								{get(selectedFilters, queryParams.cisloZmluvy) && (
									<div
										className='filter-item'
										data-color={'blue'}
										onClick={() => this.removeFilterValue(queryParams.cisloZmluvy, selectedFilters[queryParams.cisloZmluvy])}
										style={{ cursor: 'pointer' }}
									>
										<span className='filter-text'>{selectedFilters[queryParams.cisloZmluvy]}</span>
										<span className='remove' />
									</div>
								)}
								{get(selectedFilters, queryParams.aktivnaKomoditnaZmluva) === true && (
									<div
										className='filter-item'
										data-color={'orange'}
										onClick={() =>
											this.removeFilterValue(queryParams.aktivnaKomoditnaZmluva, selectedFilters[queryParams.aktivnaKomoditnaZmluva])
										}
										style={{ cursor: 'pointer' }}
									>
										<span className='filter-text'>{stavZmluvnyFilterPlaceholder}</span>
										<span className='remove' />
									</div>
								)}
								{typeof get(selectedFilters, queryParams.neregulovanaKomoditnaZmluva) === 'boolean' && (
									<div
										className='filter-item'
										data-color={'blue'}
										onClick={() =>
											this.removeFilterValue(
												queryParams.neregulovanaKomoditnaZmluva,
												selectedFilters[queryParams.neregulovanaKomoditnaZmluva]
											)
										}
										style={{ cursor: 'pointer' }}
									>
										<span className='filter-text'>{stavRegulacieFilterPlaceholder}</span>
										<span className='remove' />
									</div>
								)}
								{this.isFilterActive() && (
									<div
										className='filter-item'
										style={{ margin: 0, position: 'absolute', top: '5px', right: '15px', cursor: 'pointer' }}
										data-color='red'
										onClick={() => this.removeFilters()}
									>
										<span className='filter-text'>{t('translation:Common.filter.Zrušiť filtre')}</span>
										<span className='remove' />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<div id='miesta-spotreby-scroll-container' className='content-wrapper'>
					<div className='inline-filter' style={{ marginBottom: '20px', justifyContent: 'end' }}>
						<strong>{t('translation:ZmluvneUcty.Rozbaliť všetky')}</strong>
						<Switch isChecked={isAllExpanded} onChange={(isCheckedNewValue) => this.handleExpandAll(isCheckedNewValue)} />
					</div>
					{content}
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	obchodnyPartner: get(state, 'obchodnyPartner.detail.data'),
	miestaSpotreby: get(state, 'miestaSpotreby.collection'),
	zmluvneVztahyConfig: get(state, 'zmluvneVztahyConfig.miestaSpotreby'),
	odberneMiesta: get(state, 'odberneMiesta.collection'),
	zmluvneUcty: get(state, 'zmluvneUcty.collection'),
	zmluvneVztahy: get(state, 'zmluvneVztahy'),
	zmluvneVztahyBezMiestaSpotreby: get(state, 'zmluvneVztahy.miestaSpotreby.0.data'),
	selectedFilters: get(state, `selectedFilters.${FILTER_SELECTORS.ZMLUVNE_VZTAHY_PAGE}`, {})
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	miestaSpotrebyActions: bindActionCreators(MiestaSpotrebyActions, dispatch),
	odberneMiestaActions: bindActionCreators(OdberneMiestaActions, dispatch),
	zmluvneUctyActions: bindActionCreators(ZmluvneUctyActions, dispatch),
	zmluvneVztahyActions: bindActionCreators(ZmluvneVztahyActions, dispatch),
	zmluvneVztahyConfigActions: bindActionCreators(ZmluvneVztahyConfigActions, dispatch),
	filtersActions: bindActionCreators(FiltersActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_OP_ZMLUVY])
)(ZmluvneVztahyPage)
