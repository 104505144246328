import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { get } from 'lodash'

// atoms
import { RadioGroupField } from '../../../atoms'

// utils
import { getHlavnyIsticOptions, customParseHlavnyIsticHodnota } from '../../../utils/zmluvneVztahy'
import { RADIO_GROUP_FIELD_TYPE, PREVADZKOVATEL_SIETE } from '../../../utils/enums'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/attributesConfig'

class HlavnyIsticHodnotaField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		povinny: PropTypes.bool.isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape()
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	formatHlavnyIsticHodnota = (num) => {
		const ALLOW_NUMBER_OF_DECIMALS = 0

		if (num != null && num != undefined) {
			const integralPart = num.toString().split('.')[0]
			let decimalPart = num.toString().split('.')[1]
			decimalPart = decimalPart && decimalPart.slice(0, ALLOW_NUMBER_OF_DECIMALS)

			const finalNumber = decimalPart ? `${integralPart},${decimalPart}` : integralPart
			return `${finalNumber} A`
		}

		return 'A'
	}

	validateHlavnyIsticHodnota = (fieldValue) => {
		const { povinny, t } = this.props

		if (!fieldValue && povinny) {
			return t('translation:Common.validate.Hodnota hlavného ističa je povinná')
		}
		if (fieldValue && fieldValue <= 0) {
			return t('translation:Common.validate.Hodnota hlavného ističa musí byť kladné číslo')
		}
		if (fieldValue && fieldValue > 9999) {
			return t('translation:Common.validate.Hodnota hlavného ističa musí byť menšia ako 9999')
		}
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.THREE, value, t } = this.props

		const valueBefore = null
		let valueAfter = null

		const hlavnyIsticOptions = getHlavnyIsticOptions()

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP) {
			valueAfter = (
				<Field
					name={field}
					component={RadioGroupField}
					options={hlavnyIsticOptions}
					compare={(option, val) => {
						return get(option, 'value') == val
					}}
					editableOption
					editableOptionType={RADIO_GROUP_FIELD_TYPE.DECIMAL}
					placeholder={this.formatHlavnyIsticHodnota()}
					formatValue={(val) => (val > 0 ? this.formatHlavnyIsticHodnota(val) : '')}
					customParse={customParseHlavnyIsticHodnota()}
					validate={this.validateHlavnyIsticHodnota}
				/>
			)
		} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
			valueAfter = value
		}

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('translation:Common.Hodnota hlavného ističa')}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
							<td>{valueAfter}</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

export default compose(withTranslation('components'))(HlavnyIsticHodnotaField)
