import {
	SAVE_LIVE_AGENT_SESSION,
	CLEAR_LIVE_AGENT_SESSION,
	SAVE_FINESSE_SESSION,
	CLEAR_FINESSE_SESSION,
	SAVE_CALL250_SESSION,
	CLEAR_CALL250_SESSION
} from '../types/externalIntegrations'
import { EXTERNAL_INTEGRATIONS } from '../utils/enums'

const EXTERNAL_ID_QUERY = 'externalId'
const SOURCE_QUERY = 'source'

const clearQueryParams = () => {
	const url = new URL(window.location.href)
	url.searchParams.delete(EXTERNAL_ID_QUERY)
	url.searchParams.delete(SOURCE_QUERY)
	window.history.pushState({}, '', url.toString())
}

export const createLiveAgentSession = (liveAgentId) => {
	return {
		type: SAVE_LIVE_AGENT_SESSION,
		payload: {
			liveAgentId
		}
	}
}

export const clearLiveAgentSession = () => (dispatch) => {
	clearQueryParams()
	dispatch({ type: CLEAR_LIVE_AGENT_SESSION })
}

export const createFinesseSession = (finesseId) => {
	return {
		type: SAVE_FINESSE_SESSION,
		payload: {
			finesseId
		}
	}
}

export const clearFinesseSession = () => (dispatch) => {
	clearQueryParams()
	dispatch({ type: CLEAR_FINESSE_SESSION })
}

export const createCall250Session = (call250Id) => {
	return {
		type: SAVE_CALL250_SESSION,
		payload: {
			call250Id
		}
	}
}

export const clearCall250Session = () => (dispatch) => {
	clearQueryParams()
	dispatch({ type: CLEAR_CALL250_SESSION })
}

export const clearAllExternalSessions = () => (dispatch) => {
	dispatch(clearLiveAgentSession())
	dispatch(clearFinesseSession())
	dispatch(clearCall250Session())
}

export const loadExternalSession = () => (dispatch) => {
	const urlParams = new URLSearchParams(window.location.search)
	const externalId = urlParams.get(EXTERNAL_ID_QUERY)
	const source = urlParams.get(SOURCE_QUERY)

	if (source === EXTERNAL_INTEGRATIONS.LIVE_AGENT && typeof externalId === 'string') {
		const trimmedExternalId = externalId.substring(0, 50)
		dispatch(createLiveAgentSession(trimmedExternalId))
	}

	if (source === EXTERNAL_INTEGRATIONS.FINESSE && typeof externalId === 'string') {
		const trimmedExternalId = externalId.substring(0, 50)
		dispatch(createFinesseSession(trimmedExternalId))
	}

	if (source === EXTERNAL_INTEGRATIONS.CALL250 && typeof externalId === 'string') {
		const trimmedExternalId = externalId.substring(0, 50)
		dispatch(createCall250Session(trimmedExternalId))
	}
}
