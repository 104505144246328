import { get, isEmpty, some, forEach, set, size } from 'lodash'
import dayjs from 'dayjs'

// utils
import { isEmail } from '../../../utils/email'
import { BUSINESS_CHANNELS } from '../../../utils/enums'
import { isPhoneNumberValid } from '../../../utils/phone'

const validate = (values, props) => {
	const { t, auth, originalValues } = props

	const errors = {}

	if (!get(values, 'ukonVstup')) {
		errors.vstup = t('translation:Common.validate.Vstup do úkonu je povinný')
	}

	if (get(values, 'splnomocnenec')) {
		if (size(get(values, 'splnomocnenec')) > 120) {
			errors.splnomocnenec = t('translation:Common.validate.Splnomocnenec môže mať najviac 120 znakov')
		}
	}

	if (get(values, 'podpisMiesto')) {
		if (values.podpisMiesto.length > 100) {
			errors.podpisMiesto = t('translation:Common.validate.Miesto podpisu úkonu môže mať najviac 100 znakov')
		}
	}

	if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE && !get(values, 'datumPrijatiaZiadosti')) {
		errors.datumPrijatiaZiadosti = t('translation:Common.validate.Dátum prijatia žiadosti je povinný')
	}

	if (get(values, 'datumPrijatiaZiadosti')) {
		if (
			get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZSE_CENTRUM ||
			get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA
		) {
			if (get(values, 'datumPrijatiaZiadosti') < dayjs().startOf('day') || get(values, 'datumPrijatiaZiadosti') > dayjs().endOf('day')) {
				errors.datumPrijatiaZiadosti = t('translation:Common.validate.Dátum prijatia žiadosti nie je platný - zvoľte prosím aktuálny deň')
			}
		} else if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE) {
			if (get(values, 'datumPrijatiaZiadosti') > dayjs().endOf('day')) {
				errors.datumPrijatiaZiadosti = t('translation:Common.validate.Dátum prijatia žiadosti nie je platný - nemôžete zvoliť dátum v budúcnosti')
			}
		}
	}

	if (!get(values, 'meno')) {
		errors.meno = t('translation:Common.validate.Meno je povinné')
	} else if (size(get(values, 'meno')) > 40) {
		errors.meno = t('translation:Common.validate.Meno môže mať najviac 40 znakov')
	}

	if (!get(values, 'priezvisko')) {
		errors.priezvisko = t('translation:Common.validate.Priezvisko je povinné')
	} else if (size(get(values, 'priezvisko')) > 40) {
		errors.priezvisko = t('translation:Common.validate.Priezvisko môže mať najviac 40 znakov')
	}

	if (get(originalValues, 'narodenieDatum') && !get(values, 'narodenieDatum')) {
		errors.narodenieDatum = t('translation:Common.validate.Dátum narodenia je povinný')
	}
	if (get(values, 'narodenieDatum') < dayjs().add(-120, 'years').add(-1, 'day') || get(values, 'narodenieDatum') > dayjs().add(-18, 'years')) {
		errors.narodenieDatum = t('translation:Common.validate.Dátum narodenia nie je v povolenom rozsahu')
	}

	if (get(values, 'kontaktnyTelefon')) {
		if (!isPhoneNumberValid(get(values, 'kontaktnyTelefon'))) {
			errors.kontaktnyTelefon = t('translation:Common.validate.Nesprávny formát telefónneho čisla, použite formát v tvare +421 XXX XXX XXX')
		}
	}

	if (get(values, 'kontaktnyEmail')) {
		if (!isEmail(get(values, 'kontaktnyEmail'))) {
			errors.kontaktnyEmail = t('translation:Common.validate.Emailová adresa je neplatná')
		} else if (size(get(values, 'kontaktnyEmail')) > 2048) {
			errors.kontaktnyEmail = t('translation:Common.validate.Email môže mať najviac 2048 znakov')
		}
	}

	if (!isEmpty(get(values, 'eFaktury', []))) {
		forEach(get(values, 'eFaktury', []), (eFaktura, index) => {
			let counter = 0
			if (get(eFaktura, 'email') === get(values, 'kontaktnyEmail')) {
				counter++
			}
			forEach(get(values, 'eFaktury', []), (eFaktura2) => {
				if (get(eFaktura2, 'email') === get(eFaktura, 'email')) {
					counter++
				}
			})
			// check if email is unique in array
			if (counter > 1) {
				set(errors, `eFaktury[${index}].email`, t('translation:Common.validate.Zadaný email už existuje'))
			}

			if (!get(eFaktura, 'email')) {
				set(errors, `eFaktury[${index}].email`, t('translation:Common.validate.Pole je prázdne'))
			} else if (!isEmail(get(eFaktura, 'email'))) {
				set(errors, `eFaktury[${index}].email`, t('translation:Common.validate.Email pre e-faktúru nie je platný'))
			} else if (size(get(eFaktura, 'email')) > 2048) {
				set(errors, `eFaktury[${index}].email`, t('translation:Common.validate.Email môže mať najviac 2048 znakov'))
			}
		})
	}

	if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE && isEmpty(get(values, 'dokumenty', []))) {
		errors.dokumenty = t('translation:Common.validate.Dokumenty pre BO kanál sú povinné')
	}
	if (!isEmpty(get(values, 'dokumenty', []))) {
		some(get(values, 'dokumenty', []), (document) => {
			if (!get(document, 'dataAsBase64')) {
				errors.dokumenty = t('translation:Common.validate.Neplatný dokument', {
					nazovDokumentu: get(document, 'name')
				})
				return true
			}
		})
	}

	if (get(values, 'poznamka') && size(get(values, 'poznamka')) > 2000) {
		errors.poznamka = props.t('translation:Common.validate.Maximálna dĺžka poznámky je 2000 znakov')
	}

	return errors
}

export default validate
