import dayjs from 'dayjs'
import { get, set, isEmpty, some, forEach } from 'lodash'
import * as IBAN from 'iban'

import { isEmail } from '../../utils/email'

import { ZMLUVNY_UCET_TYP, BUSINESS_CHANNELS, SPOSOB_PLATBY_DOSLA_PLATBA, SPOSOB_PLATBY_ODOSLANA_PLATBA } from '../../utils/enums'

const validate = (values, props) => {
	const { zmluvnyUcet, t } = props
	const errors = {}

	const isNCB = get(zmluvnyUcet, 'data.zmluvnyUcet.typ.id') == ZMLUVNY_UCET_TYP.NCB

	if (!values.ukonVstup) {
		errors.ukonVstup = t('translation:Common.validate.Vstup do úkonu je povinný')
	}

	if (values.splnomocnenec) {
		if (values.splnomocnenec.length > 120) {
			errors.splnomocnenec = t('translation:Common.validate.Splnomocnenec môže mať najviac 120 znakov')
		}
	}

	if (values.podpisMiesto) {
		if (values.podpisMiesto.length > 100) {
			errors.podpisMiesto = t('translation:Common.validate.Miesto podpisu úkonu môže mať najviac 100 znakov')
		}
	}

	if (isNCB && !values.fakturacnaPerioda) {
		errors.fakturacnaPerioda = t('translation:Common.validate.Frekvencia platby je povinná')
	}

	if (!isEmpty(values.eFakturaEmaily)) {
		forEach(values.eFakturaEmaily, (eFaktura, index) => {
			let counter = 0
			forEach(values.eFakturaEmaily, (eFaktura2) => {
				if (eFaktura2.email === eFaktura.email) {
					counter++
				}
			})
			// check if email is unique in array
			if (counter > 1) {
				set(errors, `eFakturaEmaily[${index}].email`, t('translation:ZmluvneUcty.validate.Zadaný email už existuje'))
			}
			if (!eFaktura.email) {
				set(errors, `eFakturaEmaily[${index}].email`, t('translation:ZmluvneUcty.validate.Pole je prázdne'))
			} else if (!isEmail(eFaktura.email)) {
				set(errors, `eFakturaEmaily[${index}].email`, t('translation:ZmluvneUcty.validate.Email pre e-faktúru nie je platný'))
			} else if (eFaktura.email.length > 2048) {
				set(errors, `eFakturaEmaily[${index}].email`, t('translation:ZmluvneUcty.validate.Email môže mať najviac 2048 znakov'))
			}
		})
	}

	if (get(values, 'doslePlatbySposob.id') == SPOSOB_PLATBY_DOSLA_PLATBA.INKASNY_PRIKAZ && !IBAN.isValid(get(values, 'doslePlatbyBankoveSpojenie.id'))) {
		errors.doslePlatbyBankoveSpojenie = t('translation:ZmluvneUcty.validate.Neplatný formát IBAN čísla účtu')
	}

	if (get(values, 'doslePlatbySposob.id') == SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA && isEmpty(values.adresaDoslePlatby)) {
		errors.adresaDoslePlatby = {
			id: t('translation:ZmluvneUcty.validate.Pre poštové poukážky je adresa povinná')
		}
	}

	if (
		!isNCB &&
		get(values, 'odoslanePlatbySposob.id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.PREVODNY_PRIKAZ &&
		!IBAN.isValid(get(values, 'odoslanePlatbyBankoveSpojenie.id'))
	) {
		errors.odoslanePlatbyBankoveSpojenie = t('translation:ZmluvneUcty.validate.Neplatný formát IBAN čísla účtu')
	}

	if (get(values, 'odoslanePlatbySposob.id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA && isEmpty(values.adresaOdoslanePlatby)) {
		errors.adresaOdoslanePlatby = {
			id: t('translation:ZmluvneUcty.validate.Pre poštové poukážky je adresa povinná')
		}
	}

	if (get(props, 'auth.businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE && isEmpty(values.dokumenty)) {
		errors.dokumenty = t('translation:Common.validate.Dokumenty pre BO kanál sú povinné')
	}

	if (get(props, 'auth.businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE && !values.datumPrijatiaZiadosti) {
		errors.datumPrijatiaZiadosti = t('translation:Common.validate.Dátum prijatia žiadosti je povinný')
	}

	if (values.datumPrijatiaZiadosti) {
		if (
			get(props, 'auth.businessChannel.actual.id') == BUSINESS_CHANNELS.ZSE_CENTRUM ||
			get(props, 'auth.businessChannel.actual.id') == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA
		) {
			if (values.datumPrijatiaZiadosti < dayjs().startOf('day') || values.datumPrijatiaZiadosti > dayjs().endOf('day')) {
				errors.datumPrijatiaZiadosti = t('translation:Common.validate.Dátum prijatia žiadosti nie je platný - zvoľte prosím aktuálny deň')
			}
		} else if (get(props, 'auth.businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE) {
			if (values.datumPrijatiaZiadosti > dayjs().endOf('day')) {
				errors.datumPrijatiaZiadosti = t('translation:Common.validate.Dátum prijatia žiadosti nie je platný - nemôžete zvoliť dátum v budúcnosti')
			}
		}
	}

	if (!isEmpty(values.dokumenty)) {
		some(values.dokumenty, (dokument) => {
			if (!dokument.dataAsBase64) {
				errors.dokumenty = t('translation:Common.validate.Neplatný dokument', {
					nazovDokumentu: dokument.name
				})
				return true
			}
		})
	}

	if (values.poznamka && values.poznamka.length > 2000) {
		errors.poznamka = props.t('translation:Common.validate.Maximálna dĺžka poznámky je 2000 znakov')
	}

	return errors
}

export default validate
