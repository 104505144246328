import React from 'react'
import { connect } from 'react-redux'
import { touch } from 'redux-form'
import PropTypes from 'prop-types'
import { get, isEmpty, countBy, size, forEach, head } from 'lodash'
import { compose, bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import * as IBAN from 'iban'

import { COLUMNS_COUNT } from '../../../../containers/GenericUkon/attributesConfig'

// components
import PaymentMethodField from './PaymentMethodField'
import EfakturaEmailsField from './EfakturaEmailsField'
import FakturacnaAdresaField from './FakturacnaAdresaField'
import PripomienkaPlatbyField from './PripomienkaPlatbyField'

// utils
import { formatAddress } from '../../../../utils/address'
import { DEFINICIA_TYP, SPOSOB_PLATBY_DOSLA_PLATBA, SPOSOB_PLATBY_ODOSLANA_PLATBA, SUHLAS_HODNOTA, ZMLUVNY_UCET_TYP } from '../../../../utils/enums'
import { getSposobPlatbyFromCiselnik } from '../../../../utils/platby'
import { isEmail } from '../../../../utils/email'

// actions
import ObchodnyPartnerActions from '../../../../actions/ObchodniPartneri'
import { createSuhlasDTO } from '../../../../utils/suhlas'

class ZmluvnyUcetField extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		change: PropTypes.func.isRequired,
		field: PropTypes.string,
		form: PropTypes.string,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		originalValue: PropTypes.shape(),
		value: PropTypes.shape(),
		originalValues: PropTypes.shape(),
		formValues: PropTypes.shape(),
		addresses: PropTypes.array,
		nesuhlasyDefinicie: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				nazov: PropTypes.string,
				nesulasy: PropTypes.arrayOf(
					PropTypes.shape({
						id: PropTypes.string,
						dovod: PropTypes.string
					})
				)
			})
		),
		t: PropTypes.func,
		obchodnyPartner: PropTypes.shape(),
		obchodnyPartnerActions: PropTypes.shape(),
		ciselniky: PropTypes.shape()
	}

	constructor(props) {
		super(props)

		this.state = {
			adresaFakturacieSection: null,
			adresaFakturacieSectionInherit: null
		}
	}

	componentDidMount() {
		this._mounted = true
		const { dispatch, field, form, value, originalValue, t, change, ciselniky, addresses, obchodnyPartnerActions } = this.props

		const { doslePlatbySposob, odoslanePlatbySposob, eFakturaEmaily } = value

		if (this._mounted) {
			obchodnyPartnerActions.loadAllBankoveUcty(0)
		}

		if (!get(value, 'adresaFakturacie') && !isEmpty(addresses)) {
			dispatch(change(`${field}.adresaFakturacie`, head(addresses)))
		}

		// NOTE: Poziadavka z BRD - pri nastavenom inkase pre prijate platby je mozne pre odoslane platby nastavit iba bankovy prevod
		// NOTE: CP-1632
		if (
			get(doslePlatbySposob, 'id') == SPOSOB_PLATBY_DOSLA_PLATBA.INKASNY_PRIKAZ &&
			get(odoslanePlatbySposob, 'id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA
		) {
			dispatch(
				change(
					`${field}.odoslanePlatbySposob`,
					getSposobPlatbyFromCiselnik('odoslanaPlatbaSposob', SPOSOB_PLATBY_ODOSLANA_PLATBA.PREVODNY_PRIKAZ, ciselniky)
				)
			)
		}

		if (
			get(doslePlatbySposob, 'id') == SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA &&
			get(odoslanePlatbySposob, 'id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.PREVODNY_PRIKAZ
		) {
			dispatch(
				change(
					`${field}.odoslanePlatbySposob`,
					getSposobPlatbyFromCiselnik('odoslanaPlatbaSposob', SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA, ciselniky)
				)
			)
		}

		if (get(doslePlatbySposob, 'id') != SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA) {
			dispatch(touch(form, `${field}.doslePlatbyBankoveSpojenie`))
		} else {
			dispatch(touch(form, `${field}.adresaFakturacie`))
		}

		if (get(odoslanePlatbySposob, 'id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.PREVODNY_PRIKAZ) {
			dispatch(touch(form, `${field}.odoslanePlatbyBankoveSpojenie`))
		} else {
			dispatch(touch(form, `${field}.adresaFakturacie`))
		}

		const address = get(originalValue, 'adresaFakturacie')
		if (address) {
			this.setState({
				adresaFakturacieSection: formatAddress(address),
				adresaFakturacieSectionInherit: t('translation:ZmluvneUcty.Adresa fakturačná')
			})
		}

		if (size(eFakturaEmaily) > 0) {
			dispatch(touch(form, `${field}.eFakturaEmaily[0].email`))
		}
	}

	componentDidUpdate(prevProps) {
		const { field, dispatch, value, originalValue, ciselniky, change, form } = this.props

		const { doslePlatbySposob, odoslanePlatbySposob, doslePlatbyBankoveSpojenie, odoslanePlatbyBankoveSpojenie, eFakturaEmaily } = value

		if (get(doslePlatbySposob, 'id') != get(prevProps, 'value.doslePlatbySposob.id')) {
			if (get(doslePlatbySposob, 'id') != SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA) {
				dispatch(touch(form, `${field}.doslePlatbyBankoveSpojenie`))
			} else {
				dispatch(touch(form, `${field}.adresaFakturacie`))
			}

			// NOTE: Poziadavka z BRD - pri nastavenom inkase pre prijate platby je mozne pre odoslane platby nastavit iba bankovy prevod
			if (
				get(doslePlatbySposob, 'id') == SPOSOB_PLATBY_DOSLA_PLATBA.INKASNY_PRIKAZ &&
				get(odoslanePlatbySposob, 'id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA
			) {
				dispatch(
					change(
						`${field}.odoslanePlatbySposob`,
						getSposobPlatbyFromCiselnik('odoslanaPlatbaSposob', SPOSOB_PLATBY_ODOSLANA_PLATBA.PREVODNY_PRIKAZ, ciselniky)
					)
				)
			}
			// NOTE: Poziadavka z BRD - pri nastavenych postovych poukazkach pre prijate platby je mozne pre odoslane platby nastavit iba postove poukazky
			if (
				get(doslePlatbySposob, 'id') == SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA &&
				get(odoslanePlatbySposob, 'id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.PREVODNY_PRIKAZ
			) {
				dispatch(
					change(
						`${field}.odoslanePlatbySposob`,
						getSposobPlatbyFromCiselnik('odoslanaPlatbaSposob', SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA, ciselniky)
					)
				)
			}

			// NOTE: Pri zmene sposobu platby je vzdy na editaciu k dispozicii iba jedno pole (IBAN alebo adresa) a druhe je vyresetovane na povodnu hodnotu
			if (get(doslePlatbySposob, 'id') == SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA) {
				dispatch(change(`${field}.doslePlatbyBankoveSpojenie`, get(originalValue, 'doslePlatbyBankoveSpojenie', null)))
			} else {
				get(originalValue, 'adresaFakturacie') && dispatch(change(`${field}.adresaFakturacie`, get(originalValue, 'adresaFakturacie')))
			}
		}

		// NOTE: CP-1017 - V pripade zvoleneho inkasneho prikazu pre prijate platby sa cislo uctu IBAN pouzije automaticky aj pre odoslane platby
		if (
			get(doslePlatbySposob, 'id') == SPOSOB_PLATBY_DOSLA_PLATBA.INKASNY_PRIKAZ &&
			get(doslePlatbyBankoveSpojenie, 'id') != get(odoslanePlatbyBankoveSpojenie, 'id')
		) {
			dispatch(change(`${field}.odoslanePlatbyBankoveSpojenie`, doslePlatbyBankoveSpojenie))
		}

		if (get(odoslanePlatbySposob, 'id') != get(prevProps, 'value.odoslanePlatbySposob.id')) {
			if (get(odoslanePlatbySposob, 'id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.PREVODNY_PRIKAZ) {
				dispatch(touch(form, `${field}.odoslanePlatbyBankoveSpojenie`))
			} else {
				dispatch(touch(form, `${field}.adresaFakturacie`))
			}

			// NOTE: Pri zmene sposobu platby je vzdy na editaciu k dispozicii iba jedno pole (IBAN alebo adresa) a druhe je vyresetovane na povodnu hodnotu
			if (get(odoslanePlatbySposob, 'id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA) {
				dispatch(change(`${field}.odoslanePlatbyBankoveSpojenie`, get(originalValue, 'odoslanePlatbyBankoveSpojenie', null)))
			} else {
				get(originalValue, 'adresaFakturacie') && dispatch(change(`${field}.adresaFakturacie`, get(originalValue, 'adresaFakturacie', null)))
			}
		}

		if (size(eFakturaEmaily) !== size(get(prevProps, 'value.eFakturaEmaily'))) {
			forEach(eFakturaEmaily, (email, index) => {
				dispatch(touch(form, `${field}.eFakturaEmaily[${index}].email`))
			})
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	handleChangeProperty = (value, propertyName) => {
		const { dispatch, change, field } = this.props

		dispatch(change(`${field}.${propertyName}`, value))
	}

	compareValues = (option, value) => {
		if (value.hasOwnProperty('id')) {
			return get(option, 'value') === get(value, 'id')
		}

		return get(option, 'value') == value
	}

	validateEfaktura = (email) => {
		const { value, t } = this.props

		if (isEmpty(email)) {
			return t('translation:ZmluvneUcty.validate.Pole je prázdne')
		}

		if (!isEmail(email)) {
			return t('translation:ZmluvneUcty.validate.Email pre e-faktúru nie je platný')
		}

		if (size(email) > 2048) {
			return t('translation:ZmluvneUcty.validate.Email môže mať najviac 2048 znakov')
		}

		const emails = get(value, 'eFakturaEmaily', [])

		if (size(emails) > 2) {
			const occurrences = countBy(emails, (item) => get(item, 'email') === email)
			if (get(occurrences, 'true', 0) > 1) {
				return t('translation:ZmluvneUcty.validate.Zadaný email už existuje')
			}
		}
	}

	validatePrijatePlatby = (iban, newValues) => {
		const { value, t } = this.props

		if (
			(get(newValues, 'data.zmluvnyUcet.doslePlatbySposob.id') === SPOSOB_PLATBY_DOSLA_PLATBA.INKASNY_PRIKAZ && !IBAN.isValid(get(iban, 'id'))) ||
			(get(value, 'doslePlatbySposob.id') === SPOSOB_PLATBY_DOSLA_PLATBA.INKASNY_PRIKAZ && !IBAN.isValid(get(iban, 'id')))
		) {
			return t('translation:ZmluvneUcty.validate.Neplatný formát IBAN čísla účtu')
		}
	}

	validateOdoslanePlatby = (iban) => {
		const { value, t } = this.props

		const isNCB = get(value, 'typ.id') == ZMLUVNY_UCET_TYP.NCB

		if (!isNCB && get(value, 'odoslanePlatbySposob.id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.PREVODNY_PRIKAZ && !IBAN.isValid(get(iban, 'id'))) {
			return t('translation:ZmluvneUcty.validate.Neplatný formát IBAN čísla účtu')
		}
	}

	validateFakturacnaAdresa = (address) => {
		const { t, value } = this.props

		if (!address) {
			return t('translation:ZmluvneUcty.validate.Fakturačná adresa je povinná')
		}

		if (get(value, 'doslePlatbySposob.id') == SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA && !address) {
			return t('translation:ZmluvneUcty.validate.Pre poštové poukážky je adresa povinná')
		}

		if (get(value, 'odoslanePlatbySposob.id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA && !address) {
			return t('translation:ZmluvneUcty.validate.Pre poštové poukážky je adresa povinná')
		}
	}

	render() {
		const {
			field,
			editMode,
			columnsCount = COLUMNS_COUNT.THREE,
			originalValue,
			addresses,
			value,
			obchodnyPartnerActions,
			t,
			nesuhlasyDefinicie
		} = this.props

		const { adresaFakturacieSection, adresaFakturacieSectionInherit } = this.state

		return (
			<>
				<div className='inner-box'>
					<table className='content-table padded bordered' cellSpacing='0'>
						<tbody>
							{get(value, 'cislo') && (
								<tr>
									<td>
										<strong>{t('translation:Common.Číslo zmluvného účtu')}</strong>
									</td>
									{columnsCount == COLUMNS_COUNT.THREE && <td />}
									<td>{get(value, 'cislo')}</td>
								</tr>
							)}
							<EfakturaEmailsField
								field={`${field}.eFakturaEmaily`}
								editMode={editMode}
								columnsCount={columnsCount}
								originalValues={originalValue}
								formValues={value}
								onChangeSuhlas={(suhlas) => this.handleChangeProperty(suhlas, 'suhlasEfaktura')}
								onDeactivate={() => {
									this.handleChangeProperty(createSuhlasDTO(SUHLAS_HODNOTA.NAMIETKA, null), 'suhlasEfaktura')
									this.handleChangeProperty([], 'eFakturaEmaily')
								}}
								wrapIntoContainer={false}
								nesuhlasyDefinicie={nesuhlasyDefinicie}
								validate={this.validateEfaktura}
							/>
						</tbody>
					</table>
				</div>

				<PaymentMethodField
					field={`${field}.doslePlatbySposob`}
					paymentMethod='doslePlatbySposob'
					ibanPropSelector='id'
					editMode={editMode}
					columnsCount={columnsCount}
					originalValues={originalValue}
					formValues={value}
					onChangeSuhlas={(suhlas) => this.handleChangeProperty(suhlas, 'suhlasEplatba')}
					onAddIBAN={(IBAN) => obchodnyPartnerActions.saveManuallyAddedAccount({ IBAN, id: IBAN })}
					validate={this.validatePrijatePlatby}
					nesuhlasyDefinicie={nesuhlasyDefinicie}
				/>

				<PaymentMethodField
					field={`${field}.odoslanePlatbySposob`}
					paymentMethod='odoslanePlatbySposob'
					ibanPropSelector='id'
					editMode={editMode}
					columnsCount={columnsCount}
					originalValues={originalValue}
					formValues={value}
					onChangeSuhlas={(suhlas) => this.handleChangeProperty(suhlas, 'suhlasEpreplatky')}
					onAddIBAN={(IBAN) => obchodnyPartnerActions.saveManuallyAddedAccount({ IBAN, id: IBAN })}
					validate={this.validateOdoslanePlatby}
					nesuhlasyDefinicie={nesuhlasyDefinicie}
				/>

				<div className='inner-box'>
					<table className='content-table padded bordered' cellSpacing='0'>
						<tbody>
							<FakturacnaAdresaField
								field={`${field}.adresaFakturacie`}
								editMode={editMode}
								columnsCount={columnsCount}
								originalValues={{
									...(originalValue || [head(addresses)]),
									adresaFakturacieSection,
									adresaFakturacieSectionInherit
								}}
								formValues={value}
								validate={this.validateFakturacnaAdresa}
							/>
						</tbody>
					</table>
				</div>

				<PripomienkaPlatbyField
					field={`${field}.neposielatPripomienkuPlatby`}
					editMode={editMode}
					columnsCount={columnsCount}
					value={get(value, 'neposielatPripomienkuPlatby')}
					originalValue={get(originalValue, 'neposielatPripomienkuPlatby')}
				/>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	obchodnyPartner: get(state, 'obchodnyPartner.detail.data'),
	ciselniky: get(state, 'ciselniky.data'),
	addresses: get(state, 'formAddresses.data')
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(ZmluvnyUcetField)
