import React from 'react'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'

// atoms
import TimeRangeField from '../../../atoms/TimeRangeField'

// utils
import GenericFields from './GenericFields'
import { getAccessToken } from '../../../utils/auth'

export const UKON_TIME_RANGE_KEY = 'trvanie'

class GenericUkonForm extends React.Component {
	static propTypes = {
		formValues: PropTypes.shape()
	}

	constructor(props) {
		super(props)
	}

	render() {
		const { t, fields, formValues } = this.props

		return (
			<>
				<table className='content-table bordered padded top-aligned'>
					<thead>
						<tr>
							<th style={{ width: '280px' }}>{t('translation:AnonymnyVseobecnyUkon.Čas úkonu')}</th>
							<th>{t('translation:AnonymnyVseobecnyUkon.Typ anonymného všeobecného úkonu')}</th>
							<th style={{ width: '40px' }} />
						</tr>
					</thead>
					<tbody>
						<>
							{fields.map((member, index) => {
								const timeRangeFieldName = `${member}.${UKON_TIME_RANGE_KEY}`

								return (
									<>
										<tr key={`generic-field-row-${index}`}>
											<td>
												<Field
													name={timeRangeFieldName}
													component={TimeRangeField}
													startRange={dayjs().set('hours', 0).startOf('minute')}
													endRange={dayjs().set('hours', 23).endOf('minute')}
													minuteIncrement={1}
													use24Hours={true}
													placeholder={t('translation:Common.Zvoľte čas')}
													showErrorOnUntouch
												/>
												<div style={{ textAlign: 'center' }}>
													<Link
														to={`/api/v0/pomocnik/anonymnyVseobecnyUkon?accessToken=${getAccessToken()}`}
														className='button with-icon '
														data-color='blue'
														data-type='outline'
														data-icon='question'
														target='_blank'
														rel='noopener noreferrer'
													>
														{t('translation:Common.Pomocník')}
													</Link>
												</div>
											</td>
											<td>
												<div className='row'>
													<div className='col-12'>
														<GenericFields parentIndex={index} parentFieldName={member} formValues={formValues} />
													</div>
												</div>
											</td>
											<td>{fields?.length > 1 && <div className='remove-button' onClick={() => fields.remove(index)} />}</td>
										</tr>
									</>
								)
							})}
						</>
					</tbody>
				</table>
				<div className='text-center'>
					<div
						className='button with-icon'
						data-type='outline'
						onClick={() =>
							fields.push({
								trvanie: {
									startTime: dayjs().startOf('minute').toISOString(),
									endTime: dayjs().startOf('minute').toISOString()
								}
							})
						}
						data-icon='plus'
						data-color='blue'
					>
						{t('translation:AnonymnyVseobecnyUkon.Pridať úkon')}
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie
})

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(GenericUkonForm)
