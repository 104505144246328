import React from 'react'
import { withTranslation } from 'react-i18next'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Fields } from 'redux-form'

// atoms
import AddressField from '../../../../atoms/AddressField/AddressField'

// utils
import { EDIT_MODE, COLUMNS_COUNT } from '../../../../containers/GenericUkon/attributesConfig'
import { formatAddress, formatAddressForResponse } from '../../../../utils/address'
import { formatFormValue } from '../../../../utils/form'

class FakturacnaAdresaField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.string,
		originalValues: PropTypes.shape(),
		formValues: PropTypes.shape(),
		addresses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
		t: PropTypes.shape(),
		validate: PropTypes.func
	}

	getDoRukMeno = (t, value) => {
		return (
			<div>
				<strong>{t('translation:Common.Do rúk')}: </strong>
				<span>{value}&nbsp;</span>
			</div>
		)
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.THREE, originalValues, formValues, addresses, validate, t } = this.props

		let valueBefore = null
		let valueAfter = null
		let showDoRuk = false
		if (editMode == EDIT_MODE.EDIT) {
			const adresaFakturacie = formatAddressForResponse('adresaFakturacie', formValues, addresses)
			showDoRuk = get(adresaFakturacie, 'doRukMeno') || get(formValues, 'adresaFakturacie.doRukMeno')

			valueBefore = (
				<span style={{ position: 'relative', paddingRight: '20px' }}>
					{get(originalValues, 'adresaFakturacieSection')}
					{get(originalValues, 'adresaFakturacieSectionInherit') && (
						<Tooltip
							html={
								<>
									<span>{t('translation:Common.Odvodená adresa z')}</span>
									<br />
									<span>
										<strong>{get(originalValues, 'adresaFakturacieSectionInherit')}</strong>
									</span>
								</>
							}
							position='bottom'
							trigger='mouseenter'
							theme='light'
						>
							<div className='info-icon' />
						</Tooltip>
					)}
				</span>
			)

			valueAfter = (
				<>
					{showDoRuk && this.getDoRukMeno(t, get(adresaFakturacie, 'doRukMeno'))}
					<Fields
						names={[`${field}.id`]}
						selector={field}
						create
						component={AddressField}
						validate={{
							[`${field}.id`]: (value) => {
								return validate(value)
							}
						}}
					/>
				</>
			)
		} else if (editMode == EDIT_MODE.CONFIRM) {
			const adresaFakturacie = formatAddressForResponse('adresaFakturacie', formValues, addresses)
			showDoRuk = get(adresaFakturacie, 'doRukMeno') || get(originalValues, 'adresaFakturacie.doRukMeno')

			valueBefore = (
				<div>
					<div style={{ position: 'relative', paddingRight: '20px' }}>
						{get(originalValues, 'adresaFakturacieSection')}
						{get(originalValues, 'adresaFakturacieSectionInherit') && (
							<Tooltip
								html={
									<>
										<span>{t('translation:Common.Odvodená adresa z')}</span>
										<br />
										<span>
											<strong>{get(originalValues, 'adresaFakturacieSectionInherit')}</strong>
										</span>
									</>
								}
								position='bottom'
								trigger='mouseenter'
								theme='light'
							>
								<div className='info-icon' />
							</Tooltip>
						)}
					</div>
				</div>
			)

			valueAfter = (
				<>
					{showDoRuk && this.getDoRukMeno(t, formatFormValue(get(adresaFakturacie, 'doRukMeno'), get(originalValues, 'adresaFakturacie.doRukMeno')))}
					<div>{formatFormValue(formatAddress(adresaFakturacie), get(originalValues, 'adresaFakturacieSection'))}</div>
				</>
			)
		} else if (editMode == EDIT_MODE.DETAIL) {
			showDoRuk = get(formValues, 'adresaFakturacie.doRukMeno') || get(originalValues, 'adresaFakturacie.doRukMeno')

			valueBefore = <div>{formatAddress(get(originalValues, 'adresaFakturacie'))}</div>

			valueAfter = (
				<>
					{showDoRuk &&
						this.getDoRukMeno(t, formatFormValue(get(formValues, 'adresaFakturacie.doRukMeno'), get(originalValues, 'adresaFakturacie.doRukMeno')))}
					<div>{formatFormValue(formatAddress(get(formValues, 'adresaFakturacie')), get(originalValues, 'adresaFakturacie'))}</div>
				</>
			)
		}

		return (
			<tr>
				<td>
					<div>
						<strong>{t('translation:ZmluvneUcty.Fakturačná adresa (zmluvného účtu)')}</strong>
					</div>
				</td>
				{columnsCount == COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
				<td>{valueAfter}</td>
			</tr>
		)
	}
}
const mapStateToProps = (state) => ({
	addresses: get(state, 'formAddresses.data')
})

export default compose(withTranslation('components'), connect(mapStateToProps, null))(FakturacnaAdresaField)
