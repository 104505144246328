import React from 'react'
import { compose, bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { initialize, reset, destroy, reduxForm, FieldArray, getFormValues } from 'redux-form'
import { get, map } from 'lodash'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { EDIT_MODE } from '../GenericUkon/attributesConfig'

// layouts
import Header from '../Layout/Header'

// components
import DefaultModal from '../../components/Modals/DefaultModal'
import ElementLoading from '../../components/ElementLoading'
import GenericUkonForm from '../../components/GenericUkon/AnonymnyVseobecnyUkon/GenericUkonForm'

// actions
import * as DataActions from '../../actions/DataActions'
import * as ExternalIntegrationsActions from '../../actions/ExternalIntegrationsActions'

// utils
import { postReq } from '../../utils/request'
import { FORMS, UKONY_CISELNIK } from '../../utils/enums'
import { INDEX } from '../../utils/routes'
import { history } from '../../utils/history'
import { createFormInitValues } from '../../utils/form'
import { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import { getExterneIDs } from '../../utils/externalIntegrations'

import validate from './validateForm'

export const GENERIC_FIELDS_KEY = 'anonymneVseobecneUkony'

const FORM_STATE = {
	PENDING: 'pending',
	LOADING: 'loading',
	SUCCESS: 'success',
	ERROR: 'error'
}

class AnonymnyVseobecnyUkonPage extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		auth: PropTypes.shape().isRequired,
		dataActions: PropTypes.shape().isRequired,
		invalid: PropTypes.bool,
		formValues: PropTypes.shape(),
		t: PropTypes.func.isRequired,
		externalIntegrations: PropTypes.shape()
	}

	_mounted = false

	constructor(props) {
		super(props)

		this.state = {
			formState: FORM_STATE.PENDING,
			modalText: null
		}
	}

	componentDidMount() {
		this._mounted = true

		const { dispatch, dataActions, location } = this.props

		dataActions.registerLeavePageModal()

		const ukon = get(location, 'anonymnyUkon.ukon')

		dispatch(
			initialize(
				FORMS.GENERIC_UKON_VSEOBECNY_ANONYMNY,
				createFormInitValues({
					[GENERIC_FIELDS_KEY]: [
						{
							trvanie: {
								startTime: dayjs().startOf('minute').toISOString(),
								endTime: dayjs().startOf('minute').toISOString()
							},
							vstup: get(ukon, 'vstup', null)
						}
					]
				}),
				true
			)
		)
		dispatch(reset(FORMS.GENERIC_UKON_VSEOBECNY_ANONYMNY))
	}

	componentWillUnmount() {
		const { dispatch, dataActions } = this.props

		dispatch(destroy(FORMS.GENERIC_UKON_VSEOBECNY_ANONYMNY))

		dataActions.unregisterLeavePageModal()

		this._mounted = false
	}

	formatDataForUkon = (values) => {
		const { auth, externalIntegrations } = this.props

		const ukony = map(get(values, GENERIC_FIELDS_KEY, []), (ukon) => {
			return {
				typ: { id: UKONY_CISELNIK.ANONYMNY_VSEOBECNY_UKON },
				riesitel: get(auth, 'user.id'),
				kanal: get(auth, 'businessChannel.actual'),
				trvanie: dayjs(get(ukon, 'trvanie.endTime')).diff(dayjs(get(ukon, 'trvanie.startTime')), 'millisecond'),
				vstup: get(ukon, 'vstup', null),
				poznamka: get(ukon, 'poznamka', null),
				data: get(ukon, 'data', null)
			}
		})

		const externeIDsForRequest = getExterneIDs(externalIntegrations)

		return { ukony, externeIDs: externeIDsForRequest }
	}

	fallbackCopyTextToClipboard = (text) => {
		const textArea = document.createElement('textarea')
		textArea.value = text
		textArea.style.position = 'fixed' // avoid scrolling to bottom
		document.body.appendChild(textArea)
		textArea.focus()
		textArea.select()

		document.execCommand('copy')
		document.body.removeChild(textArea)
	}

	copyTextToClipboard = (text) => {
		if (!navigator.clipboard) {
			this.fallbackCopyTextToClipboard(text)
			return
		}
		navigator.clipboard.writeText(text)
	}

	submitUkon = async () => {
		const { dataActions, t, formValues, externalIntegrationsActions } = this.props

		if (this._mounted) {
			this.setState({
				formState: FORM_STATE.LOADING
			})
		}

		try {
			const body = this.formatDataForUkon(formValues)

			const interakcia = await postReq('/api/v0/interakcie/anonym', null, body)

			externalIntegrationsActions.clearAllExternalSessions()

			const ukony = get(interakcia, 'response.obsah.ukony', '-')

			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				let modalText = t('translation:Common.Úkon bol úspešne odoslaný pod číslom', { cislo: get(ukony, '[0].id', '-') })
				if (ukony.length > 1) {
					let cisla = ''
					ukony.forEach((ukon) => {
						cisla = `${cisla} ${ukon.id}`
					})
					modalText = t('translation:Common.Úkony boli úspešne odoslané pod číslami', { cisla })
				}
				this.setState({
					modalText,
					formState: FORM_STATE.SUCCESS
				})
				this.copyTextToClipboard(get(interakcia, 'response.obsah.ukony[0].id'))
			}
		} catch (e) {
			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					modalText: t('translation:Common.Počas odosielania úkonu nastala chyba'),
					formState: FORM_STATE.ERROR
				})
			}
		}
	}

	confirmModal = () => {
		const { formState } = this.state

		if (this._mounted) {
			if (formState === FORM_STATE.SUCCESS) {
				history.replace(INDEX)
			} else {
				this.setState({
					formState: FORM_STATE.PENDING,
					modalText: null
				})
			}
		}
	}

	commonContentContainer = (content) => {
		return (
			<div style={{ backgroundColor: '#F4F6F9', minHeight: '100%' }}>
				<Header />
				{content}
			</div>
		)
	}

	render() {
		const { ciselniky, invalid, t, formValues } = this.props
		const { formState, modalText } = this.state

		if (formState === FORM_STATE.LOADING || get(ciselniky, 'isLoading')) {
			return this.commonContentContainer(<ElementLoading />)
		}

		const editMode = EDIT_MODE.EDIT

		return this.commonContentContainer(
			<>
				{modalText && (
					<DefaultModal
						modalTitle={formState === FORM_STATE.SUCCESS ? t('translation:Common.Odoslané') : t('translation:Common.Chyba')}
						modalContent={modalText}
						leftButton={{
							onClick: this.confirmModal,
							text: t('translation:Common.Zavrieť'),
							color: formState === FORM_STATE.SUCCESS ? 'green' : 'red'
						}}
						visible
					/>
				)}

				{formState === FORM_STATE.PENDING && (
					<>
						<div className='content-header clearfix'>
							<div className='pull-right'>
								<button
									className={cx('button', 'pull-right', { disabled: invalid })}
									disabled={invalid}
									type='submit'
									onClick={this.submitUkon}
									data-color='blue'
									style={{ marginLeft: '20px' }}
								>
									{t('translation:Common.Dokončiť')}
								</button>
							</div>
							<button onClick={() => history.push(INDEX)} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
								{t('translation:Common.Späť')}
							</button>
							<div className='header-title pull-left'>{t('translation:AnonymnyVseobecnyUkon.Anonymný všeobecný úkon')}</div>
							<Link to={INDEX} className='button pull-right' data-type='outline' data-color='red'>
								{t('translation:Common.Zrušiť')}
							</Link>
						</div>
						<div className='content-wrapper'>
							<div className='container' style={{ marginTop: '20px', marginBottom: '20px' }}>
								<div className='box'>
									<div className='box-content'>
										<FieldArray name={GENERIC_FIELDS_KEY} props={{ formValues, editMode }} component={GenericUkonForm} />
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</>
		)
	}
}

const form = reduxForm({
	form: FORMS.GENERIC_UKON_VSEOBECNY_ANONYMNY,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate
})(AnonymnyVseobecnyUkonPage)

const mapStateToProps = (state) => ({
	auth: get(state, 'auth'),
	externalIntegrations: get(state, 'externalIntegrations'),
	formValues: getFormValues(FORMS.GENERIC_UKON_VSEOBECNY_ANONYMNY)(state)
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	dataActions: bindActionCreators(DataActions, dispatch),
	externalIntegrationsActions: bindActionCreators(ExternalIntegrationsActions, dispatch)
})

export default compose(withTranslation('containers'), connect(mapStateToProps, mapDispatchToProps), withPermissions([PERMISSIONS.UKON_ANONYM]))(form)
