import { SELECTED_FILTERS_CHANGED } from '../types/selectedFilters'
import { RESET_STORAGE } from '../types/search'
import { FILTER_SELECTORS, VZTAHY_FILTER_TYP } from '../utils/enums'

// WARNING: filter init values it should be always be a string if are using also in query param as navigation !!!

export const initState = {
	[FILTER_SELECTORS.ZMLUVNE_UCTY_PAGE]: {
		zmluvnyUcetCislo: null,
		stavZmluvy: null,
		komoditnyTyp: null,
		adresaIsuId: null
	},
	[FILTER_SELECTORS.MIESTA_SPOTREBY_PAGE]: {
		zmluvnyUcetCislo: null,
		komoditaTyp: null
	},
	[FILTER_SELECTORS.FAKTURY_PAGE]: {
		zmluvnyUcetCislo: null,
		datumVystaveniaOd: null,
		datumVystaveniaDo: null,
		variabilnySymbol: null,
		fakturaCislo: null,
		typ: null,
		fakturaTyp: null
	},
	[FILTER_SELECTORS.ROZPISY_ZALOH_PAGE]: {
		zmluvnyUcetCislo: null,
		komoditaTyp: null,
		stav: '1',
		adresaIdIsu: null
	},
	[FILTER_SELECTORS.SIDEBAR_HISTORY]: {
		fulltext: null,
		kanalId: null,
		vytvorenyOd: null,
		vytvorenyDo: null,
		riesitel: null,
		typUkonu: null,
		dolezite: 'true'
	},
	[FILTER_SELECTORS.STAV_UCTU_PAGE]: {
		typ: null,
		triedenie: 'dokladDatum:desc',
		zmluvnyUcetCislo: null,
		vyrovnanieStatus: null,
		datumOd: null,
		datumDo: null,
		variabilnySymbol: null
	},
	[FILTER_SELECTORS.STAV_UCTU_DETAIL_PAGE]: {
		typ: null,
		triedenie: 'dokladDatum:desc',
		zmluvnyUcetCislo: null,
		vyrovnanieStatus: null,
		datumOd: null,
		datumDo: null,
		variabilnySymbol: null
	},
	[FILTER_SELECTORS.UPOMIENKY_PAGE]: {
		backUrl: '',
		pohladavkaCislo: null,
		zuCislo: null,
		vystavenieDatumOd: null,
		vystavenieDatumDo: null,
		upominanieMetoda: null,
		upominanieStupen: null,
		triedenie: 'vystavenieDatum:desc'
	},
	[FILTER_SELECTORS.PLANY_SPLATOK_CREATE_PAGE]: {
		zuCislo: null,
		fakturaCislo: null
	},
	[FILTER_SELECTORS.PREPLATKY_PAGE]: {
		zmluvnyUcetCislo: null,
		variabilnySymbol: null,
		typ: null,
		triedenie: 'dokladDatum:desc'
	},
	[FILTER_SELECTORS.BLOKOVANIE_UPOMINANIA_PAGE]: {
		zmluvnyUcetCislo: null,
		variabilnySymbol: null,
		vyrovnanieStatus: null,
		triedenie: 'dokladDatum:desc'
	},
	[FILTER_SELECTORS.ZMLUVNE_VZTAHY_PAGE]: {
		omIdentifikator: null,
		cisloZmluvy: null,
		zuCislo: null,
		aktivnaKomoditnaZmluva: null,
		neregulovanaKomoditnaZmluva: null
	},
	[FILTER_SELECTORS.OP_VZTAHY_PAGE]: {
		typ: null,
		platnostOd: null,
		platnostDo: null,
		stav: 'aktivne'
	},
	[FILTER_SELECTORS.OP_DETAIL_PAGE]: {
		marketingSort: null,
		marketingDolezite: true,
		regulaciaStav: 'aktivne',
		regulaciaTyp: null
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case SELECTED_FILTERS_CHANGED:
			return {
				...state,
				[action.payload.selector]: action.payload.selectedFilters
			}
		case RESET_STORAGE:
			return {
				...initState
			}
		default:
			return state
	}
}
