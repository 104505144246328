export const OBCHODNY_PARTNER_LOAD_START = 'OBCHODNY_PARTNER_LOAD_START'
export const OBCHODNY_PARTNER_LOAD_DONE = 'OBCHODNY_PARTNER_LOAD_DONE'
export const OBCHODNY_PARTNER_LOAD_FAIL = 'OBCHODNY_PARTNER_LOAD_FAIL'

export const OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_DONE = 'OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_DONE'
export const OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_FAIL = 'OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_FAIL'
export const OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_START = 'OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_START'
export const OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_PART_DONE = 'OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_PART_DONE'

export const OBCHODNY_PARTNER_SPRAVA_SUHLASOV_LOAD_START = 'OBCHODNY_PARTNER_SPRAVA_SUHLASOV_LOAD_START'
export const OBCHODNY_PARTNER_SPRAVA_SUHLASOV_LOAD_DONE = 'OBCHODNY_PARTNER_SPRAVA_SUHLASOV_LOAD_DONE'
export const OBCHODNY_PARTNER_SPRAVA_SUHLASOV_LOAD_FAIL = 'OBCHODNY_PARTNER_SPRAVA_SUHLASOV_LOAD_FAIL'

export const OBCHODNY_PARTNER_HLAVNY_ZOZNAM_SUHLASOV_LOAD_START = 'OBCHODNY_PARTNER_HLAVNY_ZOZNAM_SUHLASOV_LOAD_START'
export const OBCHODNY_PARTNER_HLAVNY_ZOZNAM_SUHLASOV_LOAD_DONE = 'OBCHODNY_PARTNER_HLAVNY_ZOZNAM_SUHLASOV_LOAD_DONE'
export const OBCHODNY_PARTNER_HLAVNY_ZOZNAM_SUHLASOV_LOAD_FAIL = 'OBCHODNY_PARTNER_HLAVNY_ZOZNAM_SUHLASOV_LOAD_FAIL'

export const OBCHODNY_PARTNER_PODRADENE_SUHLASY_LOAD_START = 'OBCHODNY_PARTNER_PODRADENE_SUHLASY_LOAD_START'
export const OBCHODNY_PARTNER_PODRADENE_SUHLASY_LOAD_DONE = 'OBCHODNY_PARTNER_PODRADENE_SUHLASY_LOAD_DONE'
export const OBCHODNY_PARTNER_PODRADENE_SUHLASY_LOAD_FAIL = 'OBCHODNY_PARTNER_PODRADENE_SUHLASY_LOAD_FAIL'

export const OBCHODNY_PARTNER_AKTUALNA_VERZIA_SUHLASU_LOAD_START = 'OBCHODNY_PARTNER_AKTUALNA_VERZIA_SUHLASU_LOAD_START'
export const OBCHODNY_PARTNER_AKTUALNA_VERZIA_SUHLASU_LOAD_DONE = 'OBCHODNY_PARTNER_AKTUALNA_VERZIA_SUHLASU_LOAD_DONE'
export const OBCHODNY_PARTNER_AKTUALNA_VERZIA_SUHLASU_LOAD_FAIL = 'OBCHODNY_PARTNER_AKTUALNA_VERZIA_SUHLASU_LOAD_FAIL'

export const OBCHODNY_PARTNER_HISTORIA_SUHLASU_LOAD_START = 'OBCHODNY_PARTNER_HISTORIA_SUHLASU_LOAD_START'
export const OBCHODNY_PARTNER_HISTORIA_SUHLASU_LOAD_DONE = 'OBCHODNY_PARTNER_HISTORIA_SUHLASU_LOAD_DONE'
export const OBCHODNY_PARTNER_HISTORIA_SUHLASU_LOAD_FAIL = 'OBCHODNY_PARTNER_HISTORIA_SUHLASU_LOAD_FAIL'

export const OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_DONE = 'OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_DONE'
export const OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_FAIL = 'OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_FAIL'
export const OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_START = 'OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_START'
export const OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_PART_DONE = 'OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_PART_DONE'

export const OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_START = 'OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_START'
export const OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_DONE = 'OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_DONE'
export const OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_FAIL = 'OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_FAIL'
export const OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_PART_DONE = 'OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_PART_DONE'
export const OBCHODNY_PARTNER_ROZPISY_ZALOH_FILTER_CHANGED = 'OBCHODNY_PARTNER_ROZPISY_ZALOH_FILTER_CHANGED'

export const OBCHODNY_PARTNER_ROZPIS_ZALOH_LOAD_START = 'OBCHODNY_PARTNER_ROZPIS_ZALOH_LOAD_START'
export const OBCHODNY_PARTNER_ROZPIS_ZALOH_LOAD_DONE = 'OBCHODNY_PARTNER_ROZPIS_ZALOH_LOAD_DONE'
export const OBCHODNY_PARTNER_ROZPIS_ZALOH_LOAD_FAIL = 'OBCHODNY_PARTNER_ROZPIS_ZALOH_LOAD_FAIL'

export const OBCHODNY_PARTNER_ZMLUVNE_UCTY_FILTER_CHANGED = 'OBCHODNY_PARTNER_ZMLUVNE_UCTY_FILTER_CHANGED'

export const OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_START = 'OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_START'
export const OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_DONE = 'OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_DONE'
export const OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_FAIL = 'OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_FAIL'
export const OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_PART_DONE = 'OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_PART_DONE'

export const OBCHODNY_PARTNER_ZMLUVNY_UCET_LOAD_DONE = 'OBCHODNY_PARTNER_ZMLUVNY_UCET_LOAD_DONE'
export const OBCHODNY_PARTNER_ZMLUVNY_UCET_LOAD_START = 'OBCHODNY_PARTNER_ZMLUVNY_UCET_LOAD_START'
export const OBCHODNY_PARTNER_ZMLUVNY_UCET_LOAD_FAIL = 'OBCHODNY_PARTNER_ZMLUVNY_UCET_LOAD_FAIL'

export const OBCHODNY_PARTNER_ODBERNE_MIESTA_LOAD_START = 'OBCHODNY_PARTNER_ODBERNE_MIESTA_LOAD_START'
export const OBCHODNY_PARTNER_ODBERNE_MIESTA_LOAD_DONE = 'OBCHODNY_PARTNER_ODBERNE_MIESTA_LOAD_DONE'
export const OBCHODNY_PARTNER_ODBERNE_MIESTA_LOAD_FAIL = 'OBCHODNY_PARTNER_ODBERNE_MIESTA_LOAD_FAIL'

export const OBCHODNY_PARTNER_POHLADAVKY_LOAD_START = 'OBCHODNY_PARTNER_POHLADAVKY_LOAD_START'
export const OBCHODNY_PARTNER_POHLADAVKY_LOAD_DONE = 'OBCHODNY_PARTNER_POHLADAVKY_LOAD_DONE'
export const OBCHODNY_PARTNER_POHLADAVKY_LOAD_FAIL = 'OBCHODNY_PARTNER_POHLADAVKY_LOAD_FAIL'

export const OBCHODNY_PARTNER_POHLADAVKY_ZOSTATOK_LOAD_START = 'OBCHODNY_PARTNER_POHLADAVKY_ZOSTATOK_LOAD_START'
export const OBCHODNY_PARTNER_POHLADAVKY_ZOSTATOK_LOAD_DONE = 'OBCHODNY_PARTNER_POHLADAVKY_ZOSTATOK_LOAD_DONE'
export const OBCHODNY_PARTNER_POHLADAVKY_ZOSTATOK_LOAD_FAIL = 'OBCHODNY_PARTNER_POHLADAVKY_ZOSTATOK_LOAD_FAIL'

export const OBCHODNY_PARTNER_POHLADAVKY_DETAIL_LOAD_START = 'OBCHODNY_PARTNER_POHLADAVKY_DETAIL_LOAD_START'
export const OBCHODNY_PARTNER_POHLADAVKY_DETAIL_LOAD_DONE = 'OBCHODNY_PARTNER_POHLADAVKY_DETAIL_LOAD_DONE'
export const OBCHODNY_PARTNER_POHLADAVKY_DETAIL_LOAD_FAIL = 'OBCHODNY_PARTNER_POHLADAVKY_DETAIL_LOAD_FAIL'

export const OBCHODNY_PARTNER_VZTAHY_LOAD_START = 'OBCHODNY_PARTNER_VZTAHY_LOAD_START'
export const OBCHODNY_PARTNER_VZTAHY_LOAD_DONE = 'OBCHODNY_PARTNER_VZTAHY_LOAD_DONE'
export const OBCHODNY_PARTNER_VZTAHY_LOAD_FAIL = 'OBCHODNY_PARTNER_VZTAHY_LOAD_FAIL'
export const OBCHODNY_PARTNER_VZTAH_DETAIL_LOAD_START = 'OBCHODNY_PARTNER_VZTAH_DETAIL_LOAD_START'
export const OBCHODNY_PARTNER_VZTAH_DETAIL_LOAD_DONE = 'OBCHODNY_PARTNER_VZTAH_DETAIL_LOAD_DONE'
export const OBCHODNY_PARTNER_VZTAH_DETAIL_LOAD_FAIL = 'OBCHODNY_PARTNER_VZTAH_DETAIL_LOAD_FAIL'
export const OBCHODNY_PARTNER_BANKOVE_UCTY_LOAD_START = 'OBCHODNY_PARTNER_BANKOVE_UCTY_LOAD_START'
export const OBCHODNY_PARTNER_BANKOVE_UCTY_LOAD_DONE = 'OBCHODNY_PARTNER_BANKOVE_UCTY_LOAD_DONE'
export const OBCHODNY_PARTNER_BANKOVE_UCTY_LOAD_FAIL = 'OBCHODNY_PARTNER_BANKOVE_UCTY_LOAD_FAIL'
export const OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_START = 'OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_START'
export const OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_DONE = 'OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_DONE'
export const OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_FAIL = 'OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_FAIL'
export const OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_PART_DONE = 'OBCHODNY_PARTNER_BANKOVE_UCTY_ALL_LOAD_PART_DONE'

export const OBCHODNY_PARTNER_POHLADAVKY_HIGHLIGHT = 'OBCHODNY_PARTNER_POHLADAVKY_HIGHLIGHT'

export const OBCHODNY_PARTNER_BANKOVE_UCTY_DETAIL_LOAD_START = 'OBCHODNY_PARTNER_BANKOVE_UCTY_DETAIL_LOAD_START'
export const OBCHODNY_PARTNER_BANKOVE_UCTY_DETAIL_LOAD_DONE = 'OBCHODNY_PARTNER_BANKOVE_UCTY_DETAIL_LOAD_DONE'
export const OBCHODNY_PARTNER_BANKOVE_UCTY_DETAIL_LOAD_FAIL = 'OBCHODNY_PARTNER_BANKOVE_UCTY_DETAIL_LOAD_FAIL'

export const OBCHODNY_PARTNER_UPOMIENKY_LOAD_START = 'OBCHODNY_PARTNER_UPOMIENKY_LOAD_START'
export const OBCHODNY_PARTNER_UPOMIENKY_LOAD_DONE = 'OBCHODNY_PARTNER_UPOMIENKY_LOAD_DONE'
export const OBCHODNY_PARTNER_UPOMIENKY_LOAD_FAIL = 'OBCHODNY_PARTNER_UPOMIENKY_LOAD_FAIL'

export const OBCHODNY_PARTNER_MARKETINGOVE_ATRIBUTY_LOAD_START = 'OBCHODNY_PARTNER_MARKETINGOVE_ATRIBUTY_LOAD_START'
export const OBCHODNY_PARTNER_MARKETINGOVE_ATRIBUTY_LOAD_DONE = 'OBCHODNY_PARTNER_MARKETINGOVE_ATRIBUTY_LOAD_DONE'
export const OBCHODNY_PARTNER_MARKETINGOVE_ATRIBUTY_LOAD_FAIL = 'OBCHODNY_PARTNER_MARKETINGOVE_ATRIBUTY_LOAD_FAIL'

export const OBCHODNY_PARTNER_REGULACIE_LOAD_START = 'OBCHODNY_PARTNER_REGULACIE_LOAD_START'
export const OBCHODNY_PARTNER_REGULACIE_LOAD_DONE = 'OBCHODNY_PARTNER_REGULACIE_LOAD_DONE'
export const OBCHODNY_PARTNER_REGULACIE_LOAD_FAIL = 'OBCHODNY_PARTNER_REGULACIE_LOAD_FAIL'

export const OBCHODNY_PARTNER_DIGITALIZACIA_LOAD_START = 'OBCHODNY_PARTNER_DIGITALIZACIA_LOAD_START'
export const OBCHODNY_PARTNER_DIGITALIZACIA_LOAD_DONE = 'OBCHODNY_PARTNER_DIGITALIZACIA_LOAD_DONE'
export const OBCHODNY_PARTNER_DIGITALIZACIA_LOAD_FAIL = 'OBCHODNY_PARTNER_DIGITALIZACIA_LOAD_FAIL'
