import React from 'react'
import PropTypes from 'prop-types'
import { get, upperFirst, filter } from 'lodash'

import { formatDate } from '../../utils/date'

function InteractionActionTableRow(props) {
	const { ukon } = props

	const timeRange = filter([formatDate(get(ukon, 'zacatyOd'), null, 'HH:mm'), formatDate(get(ukon, 'spustenyOd'), null, 'HH:mm')], (item) => !!item).join(
		' - '
	)

	return (
		<tr>
			<td>
				<strong>{timeRange}</strong>
			</td>
			<td>{upperFirst(get(ukon, 'popis', '-'))}</td>
			{/* <td>
			<div className="remove-button" ></div>
		</td> */}
		</tr>
	)
}

InteractionActionTableRow.propTypes = {
	ukon: PropTypes.number.isRequired
}

export default InteractionActionTableRow
