import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Link, Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { login, isLoggedIn } from '../utils/auth'
import { getLogo } from '../utils/environment'

class LoginPage extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		settings: PropTypes.shape().isRequired
	}

	componentDidMount() {
		// if user is not logged in clear localStorage and sessionStorage
		if (!isLoggedIn()) {
			const initUrl = localStorage.getItem('initUrl')
			localStorage.clear()
			initUrl && localStorage.setItem('initUrl', initUrl)

			const externalIntegrations = sessionStorage.getItem('persist:externalIntegrations')
			sessionStorage.clear()
			externalIntegrations && sessionStorage.setItem('persist:externalIntegrations', externalIntegrations)
		}
	}

	render() {
		if (isLoggedIn()) {
			return <Redirect to='/' />
		}

		const { t, settings } = this.props

		return (
			<>
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Link
						to='/api/v0/pomocnik/login'
						className='button with-icon pull-right'
						data-color='blue'
						data-type='outline'
						data-icon='question'
						target='_blank'
						rel='noopener noreferrer'
						style={{ marginRight: '20px', marginTop: '20px' }}
					>
						{t('loginPage:Pomocník')}
					</Link>
				</div>
				<div className='login-content' style={{ marginTop: '-60px' }}>
					<div className='logo-wrapper'>
						<img src={getLogo(get(settings, 'env'))} alt='ZSE, Súčasť vášho domova' />
					</div>
					<button id='sign-in' className='button rounded' data-color='blue' onClick={login}>
						Prihlásiť cez ZSE login
					</button>
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	settings: get(state, 'settings.settings')
})

export default compose(withTranslation('loginPage'), connect(mapStateToProps, null))(LoginPage)
