import {
	OBCHODNY_PARTNER_SPRAVA_SUHLASOV_LOAD_START,
	OBCHODNY_PARTNER_SPRAVA_SUHLASOV_LOAD_DONE,
	OBCHODNY_PARTNER_SPRAVA_SUHLASOV_LOAD_FAIL,
	OBCHODNY_PARTNER_HLAVNY_ZOZNAM_SUHLASOV_LOAD_START,
	OBCHODNY_PARTNER_HLAVNY_ZOZNAM_SUHLASOV_LOAD_DONE,
	OBCHODNY_PARTNER_HLAVNY_ZOZNAM_SUHLASOV_LOAD_FAIL
} from '../../types/obchodnyPartner'

import { getReq } from '../../utils/request'

export function spravaSuhlasovLoadStart() {
	return {
		type: OBCHODNY_PARTNER_SPRAVA_SUHLASOV_LOAD_START
	}
}

export function spravaSuhlasovLoadDone(suhlasy) {
	return {
		type: OBCHODNY_PARTNER_SPRAVA_SUHLASOV_LOAD_DONE,
		payload: {
			suhlasy
		}
	}
}

export function spravaSuhlasovLoadFail() {
	return {
		type: OBCHODNY_PARTNER_SPRAVA_SUHLASOV_LOAD_FAIL
	}
}

export function hlavnyZoznamSuhlasovLoadStart() {
	return {
		type: OBCHODNY_PARTNER_HLAVNY_ZOZNAM_SUHLASOV_LOAD_START
	}
}

export function hlavnyZoznamSuhlasovLoadDone(zoznamHlavnychSuhlasov) {
	return {
		type: OBCHODNY_PARTNER_HLAVNY_ZOZNAM_SUHLASOV_LOAD_DONE,
		payload: {
			zoznamHlavnychSuhlasov
		}
	}
}

export function hlavnyZoznamSuhlasovLoadFail() {
	return {
		type: OBCHODNY_PARTNER_HLAVNY_ZOZNAM_SUHLASOV_LOAD_FAIL
	}
}

export function loadSpravaSuhlasov(kanalId) {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = store.interakcie.detail

		dispatch(spravaSuhlasovLoadStart())
		try {
			const query = {
				kanalId
			}

			const res = await getReq(`/api/ces/suhlas/${interakcia.data.opCislo}`, query)
			const suhlasy = res.response

			dispatch(spravaSuhlasovLoadDone(suhlasy))
			return suhlasy
		} catch (e) {
			dispatch(spravaSuhlasovLoadFail())
		}
	}
}

export function loadHlavnyZoznamSuhlasov(kanalId, kategoriaId) {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = store.interakcie.detail

		dispatch(hlavnyZoznamSuhlasovLoadStart())
		try {
			const query = {
				kanalId,
				kategoriaId
			}

			const res = await getReq(`/api/ces/suhlas/${interakcia.data.opCislo}/hlavnyZoznam`, query)
			const zoznamHlavnychSuhlasov = res.response

			dispatch(hlavnyZoznamSuhlasovLoadDone(zoznamHlavnychSuhlasov))
			return zoznamHlavnychSuhlasov
		} catch (e) {
			dispatch(hlavnyZoznamSuhlasovLoadFail())
		}
	}
}
